footer {
  .container-md {
    padding: 0 10px !important;

    @media (min-width: 1220px) {
      max-width: 1160px;
    }

    @media (max-width: 1219px) {
      max-width: 1000px !important;
    }

    @media (max-width: 992px) and (min-width: 768px) {
      max-width: 752px !important;
    }

    @media (max-width: 768px) {
      max-width: 100% !important;
      width: 100%;
      padding: 0 !important;
    }
  }

  background: $primary;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 100;

  a {
    color: #fff;
    opacity: 1;
    transition: opacity 0.3s ease;
    text-decoration: underline;
    
    &:hover, &:focus {
      color: #fff;
      opacity: 0.8;
      text-decoration: none;
    }

    &.apple, &.android {
      transition: all .3s ease-in-out;

      &:hover {
        transform: scale(1.1);
        opacity: 1 !important;
      }
    }
  }

  .navi {
    display: none;

    @media (min-width: 992px) and (min-width: 768px) {
      display: block;
    }

    .navi-content {
      ul {
        padding: 20px 0 0 20px;
        margin: 0;

        li {
          padding: 5px 0;
          margin: 0;
        }
      }
    }

    .footer-navi {
      padding: 0 6px;
      margin: 0;
      list-style: none;

      > li {
        position: relative;
        padding: 21px 0 18px;

        &:nth-child(1) {
          width: 170px;
          margin-right: 86px;

          @media (max-width: 1219px) {
            width: 170px;
            margin-right: 30px;
          }
        }

        &:nth-child(2) {
          width: 214px;
          margin-right: 78px;

          @media (max-width: 1219px) {
            width: 217px;
            margin-right: 30px;
          }
        }

        &:nth-child(3) {
          width: 148px;
          margin-right: 68px;

          @media (max-width: 1219px) {
            width: 138px;
            margin-right: 30px;
          }
        }

        &:nth-child(4) {
          width: 170px;
          margin-right: 56px;

          @media (max-width: 1219px) {
            width: 166px;
            margin-right: 30px;
          }
        }

        &:last-child {
          width: 138px;

          @media (max-width: 1219px) {
            width: 138px;
            margin-right: 30px;
          }
        }
      }

      li {
        .footer-navi-div {
          display: flex;
          align-items: flex-start;
          position: relative;

          .footer-navi-link {
            font-family: $font-bold;
            letter-spacing: 1px;
            line-height: 19px;
            text-transform: uppercase;
            cursor: pointer;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }
          }

          .arrow {
            display: block;
            position: relative;
            margin-top: 6px;
            margin-left: 12px;
          }
        }

        &.sublevels {
          .footer-navi-div {
            .arrow {
              &:before {
                content: "";
                display: block;
                background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8.333px' height='5px' viewBox='-1.792 5.063 8.333 5' enable-background='new -1.792 5.063 8.333 5' xml:space='preserve'%3E%3Cpolygon id='Triangle-2-Copy-3' fill='%23FFFFFF' points='2.375,10.063 6.541,5.063 -1.791,5.063 '/%3E%3C/svg%3E%0A") center center/8px auto no-repeat;
                width: 8px;
                height: 5px;
              }
            }
          }
        }

        &.open {
          .footer-navi-div {
            .arrow {
              &:before {
                content: "";
                display: block;
                background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8.333px' height='5px' viewBox='-1.792 5.063 8.333 5' enable-background='new -1.792 5.063 8.333 5' xml:space='preserve'%3E%3Cpolygon id='Triangle-2-Copy-3' fill='%23FFFFFF' points='2.375,10.063 6.541,5.063 -1.791,5.063 '/%3E%3C/svg%3E%0A") center center/8px auto no-repeat;
                width: 8px;
                height: 4px;
              }
            }
          }
        }
      }

      ul.level2 {
        display: none;
        padding: 18px 0;
        margin: 0;
        list-style: none;
        max-width: 164px;

        li {
          padding: 0 0 0 14px;
          margin: 0;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 10px;
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background-color: currentColor;
          }

          > a {
            line-height: 1.86;
          }
        }
      }
    }
  }

  .main {
    padding: 20px 0 0;

    @include media-breakpoint-up(lg) {
      padding: 20px 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 42px 0 49px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    @media (max-width: 992px) and (min-width: 768px) {
      padding: 0;
      padding-bottom: 20px;
    }

    @media (max-width: 768px) {
      flex-direction: row;
    }

    .address {
      line-height: 22px;
      position: relative;

      @include media-breakpoint-down(xs) {
        flex-direction: column !important;
        padding-bottom: 22px;
        border-bottom: none;
      }

      @include media-breakpoint-up(md) {
        left: auto;
        width: auto;
      }

      @media (max-width: 992px) and (min-width: 768px) {
        width: 100%;
        flex-direction: column !important;
        padding: 18px 0;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(255,255,255,0.5);

        br {
          display: none;
        }
      }

      @media (max-width: 768px) {
        flex-direction: row !important;
        width: 100%;
        border-bottom: 1px solid rgba(255,255,255,0.5);
        padding: 0 20px 20px 20px;

        br {
          display: block;
        }
      }

      a {
        margin-bottom: 1px;

        span {
          border-bottom: thin solid $white;
          height: 21px;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .col-6 {
        padding: 0 30px 0 0;
      }

      .addr {
        margin-right: 30px;

        @media (max-width: 992px) and (min-width: 768px) {
          width: 100%;
          flex: 1 1 100%;
          text-align: center;
        }

        @media (max-width: 768px) {
          flex: 1;
        }

        address {
          line-height: 23px;
        }
      }

      .support {
        a {
          display: flex;
          align-items: center;
          text-decoration: none;

          span.icon {
            text-decoration: none;
            border-bottom: none;
            em {
              width: 14px;
              height: 14px;
              display: block;
              margin-top: 5px;
              margin-right: 5px;

              @media (max-width: 992px) and (min-width: 768px) {
                margin-left: 8px;
                margin-top: 6px;
                margin-right: 4px;
              }

              &.icon-phone {
                background: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='9px' height='14px' viewBox='0 0 9 14' enable-background='new 0 0 9 14' xml:space='preserve'%3e%3cg%3e%3cpath fill='%23FFFFFF' d='M7.042,0.583H2.375c-0.805,0-1.458,0.654-1.458,1.459v9.916c0,0.806,0.653,1.459,1.458,1.459h4.667 c0.806,0,1.458-0.653,1.458-1.459V2.042C8.5,1.237,7.848,0.583,7.042,0.583z M4.708,12.833c-0.483,0-0.875-0.392-0.875-0.875 s0.392-0.875,0.875-0.875s0.875,0.392,0.875,0.875S5.191,12.833,4.708,12.833z M7.333,10.5h-5.25V2.333h5.25V10.5z'/%3e%3cpath fill='none' d='M-2,0h14v14H-2V0z'/%3e%3c/g%3e%3c/svg%3e") no-repeat center center / contain;
                width: 14px;
                height: 14px;
              }

              &.icon-at {
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M6.6.515A5.835 5.835 0 0 0 .768 6.35 5.835 5.835 0 0 0 6.6 12.182h2.917v-1.167H6.6c-2.532 0-4.667-2.135-4.667-4.666 0-2.532 2.135-4.667 4.667-4.667s4.667 2.135 4.667 4.667v.834c0 .46-.415.916-.875.916-.461 0-.875-.455-.875-.916v-.834a2.918 2.918 0 0 0-5.834 0 2.918 2.918 0 0 0 4.982 2.059 2.16 2.16 0 0 0 1.727.857c1.149 0 2.041-.933 2.041-2.082v-.834A5.835 5.835 0 0 0 6.6.515zm0 7.584c-.968 0-1.75-.782-1.75-1.75 0-.969.782-1.75 1.75-1.75s1.75.781 1.75 1.75c0 .968-.782 1.75-1.75 1.75z'/%3E%3Cpath d='M-.4-.651h14v14h-14z'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center center / contain;
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        @include media-breakpoint-down(xs) {
          margin-top: 20px;
        }

        @media (max-width: 992px) and (min-width: 768px) {
          width: 100%;
          flex: 1 1 100%;
          text-align: center;

          br {
            display: none;
          }

          display: inline-block;

          a {
            display: inline-flex;

            span {
              display: inline-flex;
            }
          }
        }

        @media (max-width: 768px) {
          flex: 1;
          margin-top: 0;
        }
      }
    }

    .social-container {
      margin-left: -56px;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }

      @media (max-width: 992px) and (min-width: 768px) {
        margin-left: 10px;
      }

      @media (max-width: 768px) {
        width: 100%;
        padding: 0 10px;
      }
    }

    .apps {
      margin-right: 1px;
      padding: 26px 0px;

      @media (max-width: 1219px) {
        margin-right: 0px;
      }

      a {
        text-decoration: none;
      }

      .apple {
        margin-right: 16px;
      }

      @media (max-width: 768px) {
        width: 100%;
        padding: 20px !important;
      }
    }

    .social {
      padding: 20px 0px 0;

      @include media-breakpoint-up(md) {
        padding: 20px 10px 0;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          a {
            display: block;
            min-width: 1px;

            @include media-breakpoint-down(sm) {
              margin: 0 4px;

              &:first-child {
                margin-left: 0;
              }
            }

            @include media-breakpoint-down(md) {
              min-width: 40px;
            }

            @include media-breakpoint-down(xl) {
              min-width: 50px;
              margin: 0 6px;
            }

            @media (max-width: 1219px) {
              margin: 0 3px;
            }
          }
        }
      }

      a {
        text-align: center;
        position: relative;

        .icon {
          width: 30px;
          height: 30px;
          margin: 0 auto 7px;

          @include media-breakpoint-up(md) {
            width: 35px;
            height: 35px;
          }

          i {
            font-size: 14px;
            color: $primary;

            @include media-breakpoint-up(md) {
              font-size: 21px;
            }

            &.fa-instagram {
              font-size: 17px;

              @include media-breakpoint-up(md) {
                font-size: 24px;
              }
            }
          }
        }

        span.text {
          display: block;
          text-align: center;
          font-size: 9px;

          @include media-breakpoint-up(md) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .play-responsibly {
    font-size: 12px;
    margin-top: -3px;
    margin-bottom: 3px;

    .text {
      display: block;
      width: auto !important;
      height: auto;
    }

    .eighteen {
      margin-left: 2.5px;
      margin-right: 1px;
      margin-top: 3px;
      width: 30px;
      height: 30px;
      display: block;

      @media (max-width: 1219px) {
        margin-left: 3.5px;
        margin-right: 0px;
      }

      &:before {
        content: "";
        display: block;
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg _ngcontent-jil-c4='' height='30' viewBox='0 0 30 30' width='30' xmlns='http://www.w3.org/2000/svg'%3e%3cg _ngcontent-jil-c4='' fill='none' fill-rule='evenodd' transform='translate(1 1)'%3e%3ccircle _ngcontent-jil-c4='' class='age_18_border' cx='14' cy='14' r='14' stroke='%23fff'%3e%3c/circle%3e%3cpath _ngcontent-jil-c4='' class='age_18' d='M3.948 18.472v-1.825h2.188v-5.022l-1.683 1.32-1.14-1.45L5.917 9.54H8.22v7.107h1.683v1.825H3.948zm7.198-6.718c0-1.812 1.63-2.42 3.08-2.42 1.437 0 3.03.582 3.03 2.33 0 1.06-.609 1.786-1.463 2.2.945.388 1.58 1.152 1.58 2.291 0 1.813-1.567 2.512-3.172 2.512-1.593 0-3.185-.7-3.185-2.486 0-1.152.673-1.85 1.644-2.252-.919-.401-1.514-1.1-1.514-2.175zm1.89.155c0 .855.543 1.049 1.152 1.256.634-.207 1.178-.414 1.178-1.269 0-.841-.531-1.022-1.152-1.022-.648 0-1.178.194-1.178 1.035zM12.893 16c0 .906.596 1.113 1.295 1.113.712 0 1.294-.22 1.294-1.113 0-.932-.634-1.178-1.307-1.411-.673.22-1.282.492-1.282 1.411zm11.793-.168H22.59v2.407h-1.968v-2.407h-2.097v-1.748h2.097v-2.408h1.968v2.408h2.097v1.748z' fill='%23fff' fill-rule='nonzero'%3e%3c/path%3e%3c/g%3e%3c/svg%3e") no-repeat center center / contain;
        width: 30px;
        height: 30px;
      }
    }

    @media (max-width: 768px) {
      display: none !important;
    }
  }

  .play-responsibly-mobile {
    display: none;

    .play-responsibly-mobile-icon {
      font-size: 12px;
      margin-top: -3px;
      margin-bottom: 3px;

      .text {
        display: block;
        width: auto !important;
        height: auto;
      }

      .eighteen {
        margin-left: 2.5px;
        margin-right: 1px;
        margin-top: 3px;
        width: 30px;
        height: 30px;
        display: block;

        @media (max-width: 1219px) {
          margin-left: 3.5px;
          margin-right: 0px;
        }

        &:before {
          content: "";
          display: block;
          background: url("data:image/svg+xml;charset=UTF-8,%3csvg _ngcontent-jil-c4='' height='30' viewBox='0 0 30 30' width='30' xmlns='http://www.w3.org/2000/svg'%3e%3cg _ngcontent-jil-c4='' fill='none' fill-rule='evenodd' transform='translate(1 1)'%3e%3ccircle _ngcontent-jil-c4='' class='age_18_border' cx='14' cy='14' r='14' stroke='%23fff'%3e%3c/circle%3e%3cpath _ngcontent-jil-c4='' class='age_18' d='M3.948 18.472v-1.825h2.188v-5.022l-1.683 1.32-1.14-1.45L5.917 9.54H8.22v7.107h1.683v1.825H3.948zm7.198-6.718c0-1.812 1.63-2.42 3.08-2.42 1.437 0 3.03.582 3.03 2.33 0 1.06-.609 1.786-1.463 2.2.945.388 1.58 1.152 1.58 2.291 0 1.813-1.567 2.512-3.172 2.512-1.593 0-3.185-.7-3.185-2.486 0-1.152.673-1.85 1.644-2.252-.919-.401-1.514-1.1-1.514-2.175zm1.89.155c0 .855.543 1.049 1.152 1.256.634-.207 1.178-.414 1.178-1.269 0-.841-.531-1.022-1.152-1.022-.648 0-1.178.194-1.178 1.035zM12.893 16c0 .906.596 1.113 1.295 1.113.712 0 1.294-.22 1.294-1.113 0-.932-.634-1.178-1.307-1.411-.673.22-1.282.492-1.282 1.411zm11.793-.168H22.59v2.407h-1.968v-2.407h-2.097v-1.748h2.097v-2.408h1.968v2.408h2.097v1.748z' fill='%23fff' fill-rule='nonzero'%3e%3c/path%3e%3c/g%3e%3c/svg%3e") no-repeat center center / contain;
          width: 30px;
          height: 30px;
        }
      }
    }

    @media (max-width: 768px) {
      display: block;
      width: 100%;
      padding: 0 20px;
    }
  }

  .copyright {
    font-size: 9px;
    border-top: thin solid rgba(255, 255, 255, 0.5);
    padding: 12px 16px;
    left: -15px;
    position: relative;
    width: calc(100% + 30px);

    span {
      width: 1px;
      height: 12px;
      display: inline-block;
      margin: 0 4px;
      position: relative;
      top: 3px;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: 12px;
      left: auto;
      width: auto;
      padding: 12px 0 16px;
    }

    .cr {
      margin-right: 2px;
    }

    div[class^="col-"] {
      margin-top: 0;
    }

    .copyright-links {
      @media (max-width: 992px) and (min-width: 768px) {
        max-width: 38%;
      }

      margin-bottom: 0;
      list-style: none;

      li {
        display: inline-block;
      }

      @media (max-width: 768px) {
        max-width: 80%;
        padding: 0;
      }

      span {
        content:'|';
        width: 1px;
        height: 12px;
        background: rgba(255, 255, 255, 0.7);
        display: inline-block;
        margin: 0 4px;
        position: relative;
        top: 3px;
      }
    }

    @media (max-width: 768px) {
      left: 0;
      padding: 15px 20px;
      width: 100%;
    }
  }

  &.logged-in {
    padding-bottom: 30px;
  }
}