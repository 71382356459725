.partnerska-mreza {
  section#partnerska-results {
    background-color: $white;
    margin-bottom: 21px;
    padding: 0;
    display: none;
    
    &.active {
      display: block;
    }
    
    &.novoletniloto {
      display: block;
      padding: 0 16px;
    }

    .results-date {
      font-size: 27px;
    }

    .results-sklad {
      font-size: 14px;
      color: $text-primary;
    }

    .results-report {
      font-size: 14px;

      i {
        width: 12px;
        height: 16px;
        margin-right: 10px;
      }
    }

    .numbers-title {
      font-size: 18px;

      &.small {
        font-size: 14px;
      }
    }

    .numbers-title-add {
      font-size: 14px;
    }

    .winnings-title {
      font-size: 20px;
      font-family: $font-normal;

      span {
        color: $text-primary;
      }
    }

    .result {
      margin-bottom: 20px;

      .numbers {
        border: 1px solid #e5e9ed;
        border-radius: 18px;
        padding: 5px 0 5px 5px;

        @include media-breakpoint-up(md) {
          border: none;
          border-radius: 0px;
          padding: 0;
        }

        .number {
          @include media-breakpoint-up(md) {
            width: 38px;
            height: 38px;
            line-height: 38px;
            font-size: 22px;
          }
        }
      }

      .icon {
        border: 2px solid $gray;
        border-radius: 50%;
        text-align: center;
        position: relative;
        width: 100%;
        max-width: 18px;
        height: 18px;
        line-height: 18px;

        i {
          font-size: 11px;
          color: $green;
        }
      }

      .winning {
        .icon {
          border: 2px solid rgba(0, 149, 57, 0.3);
          transform: rotate(45deg);

          &:before {
            content: " ";
            display: inline-block;
            transform: rotate(-90deg);
            height: 5px;
            width: 9px;
            border-bottom: 2px solid #009539;
            border-left: 2px solid #009539;
            position: absolute;
            top: 4px;
            left: 2px;
          }
        }

        .text {
          color: $green;
        }
      }

      .nowinning {
        color: $text-primary;
  
        .icon {
          border: 2px solid rgba(155, 155, 155, 0.5);
          transform: rotate(0);
  
          i {
            color: transparent;
          }
  
          &:before,
          &:after {
            position: absolute;
            top: 3px;
            left: 6px;
            content: " ";
            height: 8px;
            width: 2px;
            background-color: #000;
          }
  
          &:before {
            transform: rotate(45deg);
          }
  
          &:after {
            transform: rotate(-45deg);
          }
        }
      }

      .sklad {
        font-size: 20px;

        @include media-breakpoint-up(md) {
          font-size: 27px;
        }
      }

      .result-more-details {
        .more-details-header {
          padding: 13px 0;
        }

        .details-row-data {
          border-top: 1px solid #e1e1e1;
          padding: 13px 0px;

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &.game-lotoplus {
        .numbers {
          .number {
            &.bg-prim {
              @extend .bg-green;
            }

            &.bg-second {
              @extend .bg-loto;
            }
          }
        }
      }

      &.game-ejp {
        border: none;

        .numbers {
          .number {
            &.bg-prim {
              @extend .bg-ejp;
              color: $ejp-text;
            }

            &.bg-second {
              @extend .bg-ejp-text;
              color: $ejp;
            }
          }
        }
      }

      &.game-tikitaka {
        border: none;

        .numbers {
          .number {
            &.bg-prim {
              background: #ffcb16;
              color: #000;
            }
          }

          &.last-hour {
            .number {
              width: 26px;
              height: 26px;
              line-height: 27px;
              font-size: 14px;
              margin-right: 4px;
            }
          }
        }

        .last-hour-element {
          margin-top: 20px;

          @include media-breakpoint-up(md) {
            margin-top: 35px;
          }
        }

        .tikitaka-buttons {
          width: 100%;
          padding-left: 15px;

          @include media-breakpoint-up(sm) {
            width: 340px;
          }
        }
      }

      &.game-threexthree {
        border: none;

        .numbers {
          .number {
            &.bg-prim {
              @extend .bg-threexthree-results;
              color: #fff;
            }

            width: 28px;
            height: 28px;
            line-height: 28px;
            font-size: 15px;
          }

          .letter {
            color: $text-primary;
            font-size: 15px;
            font-family: $font-bold;
          }
        }
      }

      &.game-viking {
        border: none;

        .numbers {
          .number {
            &.bg-prim {
              @extend .bg-vikinglotto;
              color: #fff;
            }

            &.bg-second {
              @extend .bg-vikinglotto3;
              color: $viking;
            }
          }
        }
      }
    }

    .result-show-more {
      cursor: pointer;
      font-size: 14px;

      .toggle-icon {
        &:before {
          content: "";
          display: inline-block;
          position: relative;
          top: -3px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid $text-secondary;
        }
      }

      .toggleText {
        &:before {
          content: "Skrij";
          display: inline-block;
          margin-right: 5px;
        }
      }

      &.collapsed {
        .toggle-icon {
          &:before {
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }

        .toggleText {
          &:before {
            content: "Prikaži";
          }
        }
      }
    }

    .more-details-header {
      font-size: 13px;
      font-family: $font-bold;
      color: $text-primary;
      border-bottom: 1px solid #e1e1e1;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
      }

      div {
        padding: 5px 0px 10px;

        @include media-breakpoint-up(md) {
          padding: 5px 8px 10px;
        }
      }
    }

    .more-details-data {
      .details-row-data {
        background: #f2f2f2;
        border-bottom: 1px solid #e1e1e1;
        color: $text-primary;
        font-size: 16px;
        font-family: $font-bold;

        &:nth-child(even) {
          background: #fff;
        }

        div {
          padding: 14px 8px;
        }
      }
    }

    table {
      padding: 0 25px;
    }
  }

  .game-container {
    padding: 0 25px;
  }
}

#partnerska-all-results {
  border-bottom: 1px solid #e1e1e1;
  padding: 0 0 20px;
  margin-bottom: 40px;

  .daily-archive-dynamic {
    .row.mb-md-5 {
      margin-bottom: 0 !important;
    }
  }
}

body.partner-tikitaka {
  .select2-results li:before {
    display: none;
  }
}
