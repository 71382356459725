body.srecomobil {
  background: #fff;
  .container {
    max-width: 1000px !important;
    padding: 0px;

    @include media-breakpoint-up(md) {
      padding: 0 25px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 65px;
    }
  }

  #srecomobil-header-wrapper {
    #srecomobil-navi {
      margin-top: 20px;
      padding: 0 15px;

      .logo {
        width: 150px;
        transition: width .15s ease-out;
        
        @include media-breakpoint-up(sm) {
          width: 260px;
        }
      }

      .menu-trigger {
        cursor: pointer;
        width: 19px;
        height: 19px;
        display: block;
        position: absolute;
        z-index: 10000;
        top: 40px;
        right: 20px;
        transform: translate(-50%, -50%);

        .open-menu {
          width: 19px;
          height: 19px;
          position: absolute;
          transition: opacity 200ms cubic-bezier(.455, .03, .515, .955);
          opacity: 1;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='19px' height='19px' viewBox='0 0 23 24' enable-background='new 0 0 23 24' xml:space='preserve'%3e%3cg opacity='0.7'%3e%3cellipse fill='%2326974A' cx='2.738' cy='3.273' rx='2.738' ry='2.727'/%3e%3cellipse fill='%2326974A' cx='2.738' cy='12' rx='2.738' ry='2.727'/%3e%3cellipse fill='%2326974A' cx='2.738' cy='20.727' rx='2.738' ry='2.727'/%3e%3cellipse fill='%2326974A' cx='11.5' cy='3.273' rx='2.738' ry='2.727'/%3e%3cellipse fill='%2326974A' cx='11.5' cy='12' rx='2.738' ry='2.727'/%3e%3cellipse fill='%2326974A' cx='11.5' cy='20.727' rx='2.738' ry='2.727'/%3e%3cellipse fill='%2326974A' cx='20.262' cy='3.273' rx='2.738' ry='2.727'/%3e%3cellipse fill='%2326974A' cx='20.262' cy='12' rx='2.738' ry='2.727'/%3e%3cellipse fill='%2326974A' cx='20.262' cy='20.727' rx='2.738' ry='2.727'/%3e%3c/g%3e%3c/svg%3e");

          &.expanded {
            opacity: 0;
          }
        }

        .close-menu {
          width: 21px;
          height: 21px;
          position: absolute;
          top: 10px;
          opacity: 0;
          transition: opacity 200ms cubic-bezier(.455, .03, .515, .955);
          transform: translate(0, -50%);

          &:before,
          &:after {
            width: 25px;
            height: 4px;
            position: absolute;
            top: 8px;
            left: -4px;
            background-color: #26974A;
            border-radius: 2px;
            border-radius: 2px;
            content: "";
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }

          &.expanded {
            opacity: 1;
          }
        }

        @media (max-width: 575px) {
          top: 58px;
        }

        @media (max-width: 425px) {
          top: 30px;
          right: 10px;
        }
      }

      #navigation {
        display: flex;
        justify-content: flex-end;
        height: 125px;
        align-items: center;

        .navi {
          ul {
            display: inline-flex;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              position: relative;
              padding: 0 20px;
              line-height: 12px;

              a {
                text-transform: uppercase;
                font-size: 12px;
                color: #484848;
                transition: all .3s ease-in-out;
                font-family: $font-roboto;
                font-weight: 400;

                &:hover {
                  color: $green;
                  text-decoration: none;
                }

                &:active {
                  color: $green;
                  text-decoration: underline;
                }
              }

              &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-color: $gray5;
                width: 1px;
                height: 100%;
              }

              &:first-child {
                &:after {
                  content: none;
                  display: none;
                }
              }
            }
          }
        }

        @media (max-width: 768px) {
          display: none;

          &.expanded {
            display: block;
            position: absolute;
            z-index: 9999;
            top: 0;
            background: white;
            height: 100vh;

            nav {
              ul {
                display: block;
                text-align: center;
                margin: 100px 0;

                li {
                  padding: 10px 0;

                  &:after {
                    content: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    #underlay {
      background: $gray6;
      padding: 17px 0;
      margin-top: -120px;

      h1 {
        margin: 0;
        padding: 0;
        color: $yellow-srecomobil;
        font-family: $font-normal;
        text-align: right;

        span {
          font-family: $font-raph;
        }

        @media (max-width: 425px) {
          text-align: center;
        }
      }

      @media (max-width: 575px) {
        margin-top: -55px;
      }
      
      @media (max-width: 425px) {
        margin-top: 20px;
      }
    }

    &.fixed-menu {
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      height: 80px;
      z-index: 600;
      width: 100%;

      #srecomobil-navi {
        #navigation {
          height: 50px;
          /*position: fixed;
          top: 0;
          max-width: 1000px;
          flex: 0 0 100%;
          background: #fff;
          z-index: 500;*/

          .navi {
            ul {
              li {
                a {
                  font-weight: bold;
                }
              }
            }
          }

          &.expanded {
            height: 100vh;
          }
        }

        + #underlay {
          opacity:0;
        }

        .logo {
          position: fixed;
          top: 10px;
          z-index: 550;
          width: 60px;
        }
      }
    }
  }

  #banner {
    margin-top: -115px;
    position: relative;
    z-index: -1;
    height: 585px;

    .image {
      margin-bottom: -40px;

      img {
        width: 100%;
      }
    }
  }

  #spoznajte {
    margin-top: 45px;
    margin-bottom: 25px;
    
    @include media-breakpoint-up(md) {
      margin-top: 85px;
      margin-bottom: 0px;
    }

    img {
      max-width: 90%;
      
      @include media-breakpoint-up(md) {
        max-width: 100%;
      }
    }

    h1 {
      font-family: $font-raph;
      font-size: 56px;
      color: $yellow-srecomobil;
      -ms-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
  }

  #sreco {
    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 200px 200px;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      margin-top: 40px;
    }
  }

  #sreco-who {
    grid-area: 1 / 1 / 4 / 7;
    background: $green;
    position: relative;

    .img {
      flex: 0 0 100%;
      padding: 50px 50px 10px;
      
      @include media-breakpoint-up(md) {
        flex: 1 0 50%;
      }

      img {
        width: 100%;
      }
    }

    .separator {
      position: relative;
      width: 88%;
      height: 1px;
      left: 6%;
      background: $white;
      margin: 20px 0;

      &:before {
        content: "";
        width: 5px;
        height: 5px;
        position: absolute;
        bottom: -2px;
        border: 2px solid #fff;
        border-radius: 50%;
        left: -2px;
      }

      &:after {
        content: "";
        width: 5px;
        height: 5px;
        position: absolute;
        bottom: -2px;
        border: 2px solid #fff;
        border-radius: 50%;
        right: -2px;
      }

      @include media-breakpoint-up(md) {
        position: absolute;
        left: 50%;
        height: 73%;
        width: 1px;
        margin: 55px 0;

        &:before {
          bottom: auto;
          top: -5px;
        }
  
        &:after {
          bottom: -5px;
          right: auto;
          left: -2px;
        }
      }

      
    }

    .text {
      flex: 0 0 100%;
      padding: 40px 25px;

      @include media-breakpoint-up(md) {
        padding: 60px;
      }

      @include media-breakpoint-up(md) {
        flex: 1 0 50%;
      }

      h2 {
        font-family: $font-roboto;
        color: $white;
        font-weight: 600;
        font-size: 30px;
      }

      p {
        font-family: $font-roboto;
        color: $white;
        font-size: 24px;
        font-weight: 300;
        line-height: 29px;
      }
    }
  }

  #sreco-where {
    grid-area: 4 / 1 / 4 / 5;
    background: $green;

    .icon {
      flex: 1 0 40%;
      padding: 60px;

      img {
        width: 100%;
      }
    }

    .content {
      flex: 1 0 50%;
      padding: 40px 25px;

      @include media-breakpoint-up(md) {
        padding: 60px;
      }


      h2 {
        font-family: $font-roboto;
        color: $white;
        font-weight: 700;
        font-size: 35px;
      }

      p {
        font-family: $font-roboto;
        color: $white;
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
      }
    }
  }

  #where-img {
    background: $green;
    position: relative;
    
    @include media-breakpoint-up(md) {
    grid-area: 4 / 5 / 4 / 7;
      img {
        position: absolute;
        bottom: -52px;
        left: 0;
      }
    }
  }

  #events {
    margin-top: 90px;
    padding: 60px 0 70px;
    background: #e1e0e0;

    #dogodki {
      @include media-breakpoint-down(sm) {
        padding: 25px;
      }
    }

    h2 {
      margin-bottom: 40px;

      span {
        display: inline-block;
        position: relative;
        z-index: 1;
        padding: 0 30px;
        margin: 0 auto;
        background: #e1e0e0;
        font-size: 24px;
        font-weight: 700;
        font-family: $font-roboto;
        text-transform: uppercase;

        @media (max-width: 575px) {
          display: initial;
        }
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: 15px;
        left: 0;
        z-index: 0;
        border-bottom: 2px dotted #88888b;

        @media (max-width: 575px) {
          content: none !important;
        }
      }
    }

    #event {
      margin-bottom: 30px;

      #col {
        .event-title-container {
          h3 {
            font-size: 28px;
            font-weight: 700;
            font-family: $font-roboto;
            color: #3a393d;

            span {
              text-transform: uppercase;
            }

            &:after {
              content: "";
              display: block;
              border-bottom: 4px solid #3a393d;
              height: 100%;
              width: 65%;
              margin-top: 10px;
            }
          }
        }

        p {
          font-family: $font-roboto;
          font-size: 18px;
          line-height: 1.3;

          strong {
            font-family: $font-roboto;
            font-size: 18px;
          }
        }

        img {
          width: 100%;
          border: 7px solid $white;
        }
      }
    }

    #announcing {
      h2 {
        display: block;
        position: relative;
        font-size: 28px;
        font-weight: 200;
        font-family: $font-roboto;
        margin-bottom: 10px;

        &:before {
          content: none;
          display: none;
        }

        &:after {
          content: "";
          display: block;
          border-bottom: 4px solid #3a393d;
          height: 100%;
          margin-top: 10px;
        }
      }

      #event {
        h3 {
          font-size: 28px;
          font-weight: 700;
          font-family: $font-roboto;
          color: #3a393d;

          span {
            text-transform: uppercase;
          }
        }

        p {
          font-family: $font-roboto;
          font-size: 23px;

          strong {
            font-family: $font-roboto;
            font-size: 23px;
          }
        }
      }
    }
  }

  #gallery {
    margin-top: 35px;
    padding: 70px 0 35px;
    background: #262a34;

    h2 {
      margin-bottom: 25px;
      color: $white;

      span {
        display: inline-block;
        position: relative;
        z-index: 1;
        padding: 0 30px;
        margin: 0 auto;
        background: #262a34;
        font-size: 24px;
        font-weight: 700;
        font-family: $font-roboto;
        text-transform: uppercase;

        @media (max-width: 575px) {
          display: initial;
        }
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: 15px;
        left: 0;
        z-index: 0;
        border-bottom: 2px dotted #7b7979;

        @media (max-width: 575px) {
          content: none !important;
        }
      }
    }

    h3 {
      margin-bottom: 25px;
      color: $white;

      span {
        display: block;
        position: relative;
        z-index: 1;
        width: 280px;
        margin: 0 auto;
        background: #262a34;
        font-size: 18px;
        font-weight: 600;
        font-family: $font-roboto;
        text-transform: uppercase;

        @media (max-width: 575px) {
          display: initial;
        }
      }

      /*&:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: 20%;
        left: 0;
        z-index: 0;
        border-bottom: 2px dotted #7b7979;

        @media (max-width: 575px) {
          content: none !important;
        }
      }*/
    }

    #gallery-title {
      background: $green;

      p {
        color: $white;
        font-family: $font-roboto;
        font-size: 23px;
        margin-left: 40px;
        line-height: 30px;

        strong {
          font-family: $font-roboto;
          font-size: 23px;
        }
      }
    }

    #cover-image {
      width: 100%;
      height: 416px;

      .overlay {
        .subtitle {
          background: rgba(0, 0, 0, 0.75);
          color: $white;
          font-family: $font-roboto;
          font-size: 35px;
          width: 90%;
          height: 80%;
          font-weight: 300;
          padding: 75px;
          line-height: 44px;
        }
      }
    }

    .images {
      padding: 0 70px;

      .image {
        padding: 0 10px;

        img {
          width: 100%;
        }
      }
    }


    #ended-events {
      .element-inner {
        background: $green;
        padding: 55px 40px;
        height: 280px;

        h4 {
          font-size: 18px;
          font-family: $font-roboto;
          font-weight: 600;
          color: $white;
          margin-bottom: 20px;
        }

        p {
          color: $white;
          font-family: $font-roboto;
          font-weight: 300;
          font-size: 18px;
        }

        .btn-more {
          display: block;
          width: 100%;
          height: 40px;
          color: #d5e011;
          font-size: 18px;
          font-family: $font-roboto;
          font-weight: 300;
          line-height: 35px;
          text-align: center;
          border: 2px solid #d5e011;
          text-transform: uppercase;
          transition: all .3s ease-in-out;

          &:hover {
            color: $green;
            background-color: #d5e011;
            text-decoration: none;
          }

          @include media-breakpoint-up(lg) {
            width: 190px;
          }
        }
      }
    }
  }

  .page-item-subtitle {
    h2 {
      margin: 60px 0 25px;
      color: #282e39;

      span {
        display: inline-block;
        position: relative;
        z-index: 1;
        padding: 0 75px;
        margin: 0 auto;
        background: $white;
        font-size: 24px;
        font-weight: 700;
        font-family: $font-roboto;
        text-transform: uppercase;

        @media (max-width: 575px) {
          display: initial;
        }
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: 65%;
        left: 0;
        z-index: 0;
        border-bottom: 2px dotted #7b7979;

        @media (max-width: 575px) {
          content: none !important;
        }
      }
    }
  }

  #sreco-hashtags {
    .sreco-hashtag {
      margin-bottom: 23px;

      h2 {
        color: $green;
        font-family: $font-roboto;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 25px;

        &:after {
          content: "";
          display: block;
          height: 2px;
          background-color: $green;
          width: 160px;
          position: absolute;
          left: 50%;
          transform: translate(-50%,0);
          margin-top: 7px;
        }
      }

      p {
        font-size: 18px;
        font-family: $font-roboto;
        text-align:center;
        line-height: 22px;
        color:#282e39;
        font-weight: 300;
      }

      &.grey-light {
        background-color: #e5e4e4;

        p {
          strong {
            color: $green;
            font-family: $font-roboto;
          }
        }
      }

      &.grey-dark {
        background-color: #262a34;

        p {
          color: #fefefe;
          strong {
            font-family: $font-roboto;
            font-weight: 700;
          }
        }
      }
    }
  }

  .po-sreco-container {
    margin: 70px 0;
  }

  #contact-form {
    padding: 50px 25px 45px;
    background: $green;
    margin-bottom: 15px;

    .subtitle {
      h2 {
        color: $white;
        margin-bottom: 25px;

        span {
          display: block;
          position: relative;
          z-index: 1;
          width: 400px;
          margin: 0 auto;
          background: $green;
          font-size: 24px;
          font-weight: 700;
          font-family: $font-roboto;
          text-transform: uppercase;

          @media (max-width: 575px) {
            display: initial;
          }
        }
      }
    }

    #caption {
      font-size: 18px;
      font-family: $font-roboto;
      color: $white;
      margin-bottom: 20px;
      font-weight: 300;
    }

    input[type="text"] {
      font-size: 18px;
      font-weight: 300;
      border: 3px solid white;
      background: $green;
      color: $white;
      padding: 20px 10px;
      margin-bottom: 14px;
      border-radius: 0;
      font-family: $font-roboto;

      &::placeholder {
        color: $white;
        font-size: 18px;
      }
    }

    .btn-send {
      margin-top: 30px;
      display: block;
      width: 190px;
      height: 40px;
      color: #d5e011;
      font-size: 18px;
      font-family: $font-roboto;
      font-weight: 300;
      border: 2px solid #d5e011;
      text-transform: uppercase;
      transition: all .3s ease-in-out;
      background: transparent;

      &:hover {
        color: $green;
        background-color: #d5e011;
        text-decoration: none;
      }
    }
  }

  #main {
    &.single-gallery {
      padding: 80px 0 !important;
      background: #262a34;
      h1, h2, h3 {
        color:#fff;
      }

      h2 {
        font-family: $font-normal;
        margin-bottom: 20px;
      }

      h3 {
        margin-bottom: 10px;
        font-size: 16px;
      }

      @include media-breakpoint-down(sm) {
        .container {
          padding: 0 25px;
        }
      }

      .btn-back {
        width: 280px;
        text-align:center;
        display: block;
        padding: 0 10px;
        height: 40px;
        line-height: 37px;
        margin: 50px auto 0;
        color: #d5e011;
        font-size: 18px;
        font-family: $font-roboto;
        font-weight: 300;
        border: 2px solid #d5e011;
        text-transform: uppercase;
        transition: all .3s ease-in-out;
        background: transparent;
  
        &:hover {
          color: $green;
          background-color: #d5e011;
          text-decoration: none;
        }
      }
    }
  }

  #srecomobil-footer {
    padding: 40px 0;

    .container {
      @include media-breakpoint-down(sm) {
        padding: 0 25px;
      }
    }

    p {
      font-size: 18px;
      font-family: $font-roboto;
      font-weight: 600;
      color: #d5e011;
    }

    #icons {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          width: 50px;
          height: 50px;
          padding: 10px 0;
          margin: 0 10px;
          background: transparent;
          border: 2px solid #d5e011;
          border-radius: 50%;

          a {
            color: #d5e011;
            height: 100%;

            &:hover {
              text-decoration: none;
            }

            .icon {
              margin-top: 2px;
            }

            i {
              &.facebook:before {
                display: block;
                content: "";
                background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='%23d5e011' d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'/%3e%3c/svg%3e") no-repeat center center /cover;
                width: 10px;
                height: 23px;
              }

              &.instagram:before {
                display: block;
                content: "";
                background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%23d5e011' d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'/%3e%3c/svg%3e") no-repeat center center / cover;
                width: 21px;
                height: 21px;
              }

              &.youtube:before {
                display: block;
                content: "";
                background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%23d5e011' d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'/%3e%3c/svg%3e") no-repeat center center / cover;
                width: 23px;
                height: 23px;
              }
            }
          }
        }
      }
    }

    .play-responsibly-sreco {
      font-size: 13px;
      font-family: $font-roboto;

      span.eighteen {
        width: 45px;
        height: 45px;
        font-size: 19px;
        font-weight: bold;
        font-family: "Arial";
        border: 2px solid $white;
        border-radius: 50%;
        line-height: 40px;
        text-align: center;
        margin-left: 10px
      }
    }
  }

  #foot {
    padding: 20px 0;

    .container {
      @include media-breakpoint-down(sm) {
        padding: 0 25px;
      }
    }

    p {
      font-size: 13px;
      color:#58585b;

      &.text-left {
        font-size: 18px;
      }
    }

    a {
      color:#58585b;
    }
  }
}
