.sitemap {
  ul.list {
    list-style: none;
    margin: 0;
    padding: 0;

    li.list-item {
      margin: 5px 0;
      padding: 0;
      a {
        font-size: 15px;
        color: $text-secondary;
        display: inline-block;
      }

      &.level1 {
        > a {
          color:$text-secondary;
          font-size: 20px;
          font-family: $font-bold;
        }
      }
    }
  }

  .top-sitemap {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $gray3;

    h3 {
      font-size: 20px;
      color: $primary;
      font-family: $font-bold;
      margin-bottom: 25px;
    }
    ul.list {
      li.list-item {
        &.level1 {
          position: relative;
        }

        .icon {
          display: none;
        }

        &.collapsible {
          > a {
            line-height: 20px;
          }
          
          ul.list.level2 {
            padding-left: 15px;
            transition: all 0.3s;
          }

          .icon {
            width: 20px;
            height: 22px;
            position: relative;
            top:5px;
            display: inline-block;
            cursor: pointer;
            background: none;
            outline:none;
            border: none;
            border: 1px solid transparent;
            
            &:before, &:after {
              position: absolute;
              top: 6px;
              left: 9px;
              content: ' ';
              height: 7px;
              width: 2px;
              background-color: $text-secondary;
            }

            &:after {
              transform: rotate(-90deg);
            }

            &:focus {
              border: 1px solid $text-primary;
            }
          }

          &.active {
            ul.level2 {

            }

            .icon {
              &:before {
                transform: rotate(90deg);
              }
            }
          }
        }
      }

      &.level1 {          
        @include media-breakpoint-up(md) {
          max-height: 760px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
        }

        .list-item.level1 {
          max-width: 100%;
          flex: 0 0 100%;

          @include media-breakpoint-up(md) {
            max-width: 25%;
            flex: 0 0 25%;

            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10),
            &:nth-child(11) {
              margin-left: 10.5%;
              margin-right: 8.5%;
            }
          }
        }

        &.collapsible {
          margin: 0;
        }
      }
    }
  }

  .bottom-sitemap {
    ul.list {
      &.level1 {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
      }

      li.list-item {
        &.level1 {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 30px;

          @include media-breakpoint-up(sm) {
            flex: 0 0 50%;
            max-width: 50%;
          }
          
          @include media-breakpoint-up(lg) {
            flex: 0 0 25%;
            max-width: 25%;
            &:nth-child(3n+2) {
              margin-left: 12.5%;
              margin-right: 12.5%;
            }
          }

          > a {
            margin-bottom: 10px;
            color: $primary;
          }
        }

        a {
          font-size: 18px;
        }
      }
    }
  }
}