@import "editor.scss";

/*@import "variables";
@import "fonts";
*/
@function encodecolor($string) {
  @if type-of($string)=='color' {
    $hex: str-slice(ie-hex-str($string), 4);
    $string:unquote("#{$hex}");
  }

  $string: '%23'+$string;
  @return $string;
}

html {
  overflow-x: hidden;
}

body {
  color: $text-primary;
  font-weight: normal;
  font-family: $font-normal;
  background: #f9f9f9;
  overflow-x: hidden;

  &.srecke,
  &.ticket-check {
    background: #fff;
  }
}


h1 {
  font-family: $font-bold;
  font-size: 25px;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 35px;
    margin-bottom: 30px;
  }
}

h2 {
  font-size: 18px;
  font-family: $font-bold;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
}

h3 {
  font-size: 16px;
  margin-bottom: 10px;

  @include media-breakpoint-up(md) {
    font-size: 22px;
  }
}

h4 {
  font-size: 15px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
}

::-webkit-selection {
  background-color: $green;
  color: #fff
}

:-ms-selection {
  background-color: $green;
  color: #fff
}

::-moz-selection {
  background-color: $green;
  color: #fff
}

::selection {
  background-color: $green;
  color: #fff
}

/**:focus,
button:focus {
  border: 0px solid green;
  outline-color: cornflowerblue;
  outline-style: solid;
  outline-offset: 0;
  border-radius: 0;
  outline-width: 2px;
}*/

* {
  outline: 0;

  &:focus-visible {
    outline: 2px solid #6495ed;
    outline-offset: 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }
}

a {
  color: $primary;

  &:hover {
    color: $primary;
  }

  &:focus {
    outline-color: cornflowerblue;
    outline-style: solid;
    outline-offset: 0;
    border-radius: 0px;
    outline-width: 2px;
  }
}

p {
  margin: 0;
}

strong,
b {
  font-family: $font-bold;
}

.cf {
  clear: both;
}

caption {
  caption-side: top;
}

.container-s,
.container-s-lg,
.container-xs,
.container-large {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 12px;

  .row {
    margin-left: -12px;
    margin-right: -12px;

    &.no-gutters {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.container-s {
  padding: 0 20px;
}

.container-large {
  padding-left: 12px;
  padding-right: 12px;
}

.container-s-lg {
  padding: 0;

  @include media-breakpoint-up(lg) {
    padding: 0 12px;
  }
}

.flex-even {
  flex: 1;
}

/*.container-fluid {
  max-width: 1220px;
  padding: 0;
}*/

table {

  &.table-two-col-responsive {
    @include media-breakpoint-down(md) {
      width: 100% !important;
      display: block;
    }

    tbody {
      width: 100%;
      display: block;

      tr {
        width: 100%;
        display: block;
        margin-bottom: 20px;

        td {
          @include media-breakpoint-up(lg) {
            padding-right: 10px;

            &:last-child {
              padding-left: 10px;
            }
          }

          @include media-breakpoint-down(md) {
            width: 100% !important;
            display: block;
          }
        }
      }
    }
  }
}

.content-edit,
.accordion {
  a[href$=".pdf"] {
    display: inline-flex;
    align-items: baseline;
    margin: 8px 0px;
    position: relative;
  }

  a[href$=".doc"],
  a[href$=".docx"],
  a[href$=".dot"],
  a[href$=".xls"],
  a[href$=".xlsx"],
  a[href$=".zip"],
  a[href$=".rar"] {
    display: inline-flex;
    align-items: center;
    margin: 8px 0px;
    position: relative;
  }

  a[href$=".pdf"] {
    &:before {
      content: "";
      width: 19px;
      height: 26px;
      position: relative;
      top: 3px;
      margin-right: 10px;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3e%3cpath fill='%23008533' d='M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  table {
    &.delnicarji {
      width: 100% !important;

      tr {
        th {
          font-size: 14px;
          font-family: $font-normal;
          padding: 10px 0 10px 40px;
          font-weight: normal;
        }

        td {
          padding: 15px 0;
          border-top: 1px solid #e1e1e1;

          @include media-breakpoint-down(sm) {
            &.d-theme-none {
              display: none;
            }
          }

          &:first-child {
            display: flex;
          }

          &:last-child {
            border-top: none;
          }

          &:nth-child(2) {
            border-top: 1px solid #e1e1e1;
          }
        }

        &:nth-child(2) {
          td:first-child {
            &:before {
              content: "";
              background: #009539;
              width: 18px;
              height: 18px;
              display: inline-flex;
              margin: 4px 12px 0px;
            }
          }
        }

        &:nth-child(3) {
          td:first-child {
            &:before {
              content: "";
              background: #52ab69;
              width: 18px;
              height: 18px;
              display: inline-flex;
              margin: 4px 12px 0px;
            }
          }
        }

        &:nth-child(4) {
          td:first-child {
            &:before {
              content: "";
              background: #7dc08e;
              width: 18px;
              height: 18px;
              display: inline-flex;
              margin: 4px 12px 0px;
            }
          }
        }

        &:nth-child(5) {
          td:first-child {
            &:before {
              content: "";
              background: #a9d5b4;
              width: 18px;
              height: 18px;
              display: inline-flex;
              margin: 4px 12px 0px;
            }
          }
        }

        &:nth-child(6) {
          td:first-child {
            &:before {
              content: "";
              background: #d4ead9;
              width: 18px;
              height: 18px;
              display: inline-flex;
              margin: 4px 12px 0px;
            }
          }
        }
      }
    }

    &.games-category {
      td {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100% !important;
        position: relative;
        padding: 0 5px 0 45px;
        vertical-align: top;
        line-height: 1.71;
        letter-spacing: 0.22px;
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
          display: table-cell;
          margin: 0;
          width: 25% !important;
          flex: unset;
          max-width: none;
          padding: 0 5px 0 55px;
        }

        &:before {
          content: "";
          width: 18px;
          height: 18px;
          position: absolute;
          left: 10px;
          top: 4px;
          object-fit: contain;
          border-style: solid;
          border-width: 1px;
          border-image-slice: 1;
          background-image: linear-gradient(to bottom, #b4ec51, #429321);
          display: block;
          border-radius: 50%;

          @include media-breakpoint-up(md) {
            left: 20px;
          }
        }

        &:nth-child(2) {
          &:before {
            background-image: linear-gradient(to bottom, #ffedad, #c09b00);
          }
        }

        &:nth-child(3) {
          &:before {
            background-image: linear-gradient(to bottom, #fad961, #f76b1c);
          }
        }

        &:nth-child(4) {
          &:before {
            background-image: linear-gradient(to bottom, #f5515f, #9f041b);
          }
        }
      }
    }

    &.dobitki {
      width: 84% !important;
      margin: 0 8%;

      @include media-breakpoint-down(md) {
        margin: 0;
      }

      tr {
        td {
          border: none;
          padding: 25px 0;

          @include media-breakpoint-down(md) {
            padding: 0;
          }
        }
      }
    }

    &.vplacila {
      counter-reset: number;
      padding-top: 60px;

      tr {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        td {
          vertical-align: middle;
          font-size: 14px;
          line-height: 24px;
          padding: 5px 0;
          position: relative;
          max-width: 100%;
          flex: 0 0 100%;
          width: 100% !important;
          margin-bottom: 20px;

          @include media-breakpoint-up(md) {
            max-width: 20%;
            flex: 0 0 20%;
            width: 20% !important;
            margin-bottom: 0px;
          }

          @include media-breakpoint-up(md) {
            &:before {
              content: counter(number);
              counter-increment: number;
              font-family: $font-bold;
              position: relative;
              left: calc(50% - 5px);
              position: absolute;
              top: -74px;
            }
          }

          img {
            @include media-breakpoint-up(md) {
              position: absolute;
              top: -53px;
              left: 50%;
              transform: translate(-50%, 0px);
            }

          }

          &:nth-child(2) {
            img {
              @include media-breakpoint-up(md) {
                top: -44px;
              }
            }
          }
        }



        &:last-child {
          td {
            @include media-breakpoint-up(md) {
              border-right: 1px solid $gray;
            }

            padding: 7px;

            &:last-child {
              border: none;
            }
          }
        }
      }
    }
  }
}

.prevzem-dobitkov {
  table {
    tr {
      td {
        font-size: 14px;
      }

      &:first-child {
        td {
          font-family: $font-bold;
        }
      }
    }
  }
}

#main {
  padding: 0 !important;

  .breadcrumbs {
    font-size: 14px;
    color: $text-secondary;
    height: 48px;

    div {
      span {
        color: $text-secondary;
        text-transform: none;
      }

      a {
        color: $text-secondary;
        text-transform: none;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

      }
    }

    >div {
      line-height: 20px;

      &:last-child {
        span {
          text-decoration: none;
        }
      }
    }

    .separator {
      margin: 0 8px 0 6px;
      width: 6px;
      height: 6px;
      border: solid $gray;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    @media (max-width: 768px) {
      padding: 0 20px !important;
    }
  }


  &.loto,
  &.tikitaka,
  &.threexthree,
  &.vikinglotto,
  &.ejp {
    padding: 0 !important;
  }

  .content-edit {
    margin-bottom: 30px;
  }

  .main-visual {
    max-height: 488px;
    width: 100%;
    max-width: 1866px;
    margin: 0 auto;

    @media (max-width: 575px) {
      transition: unset;
    }

    .splide__arrow {
      position: absolute;
      top: 50%;
      left: -24px;
      transform: translate(0, -50%);
      z-index: 100;
      width: 55px;
      height: 50px;
      cursor: pointer;
      background: rgba(0,0,0,.42);
      border-radius: 50%;
      opacity: 1;
      
      @media (min-width: 1866px) {
        left: 20px;
        height: 50px;
        width: 50px;
      }
      
      @media (min-width: 2000px) {
        background: none;
        left: -58px;
      }

      &:focus {
        outline: 5px auto -webkit-focus-ring-color;
      }

      &:after {
        content:'';
        width: 10px;
        height: 10px;
        display: block;
        border-left: 2px solid $white;
        border-top: 2px solid $white;
        transform: rotate(-45deg);
        margin-left: 22px;
        
        @media (min-width: 1866px) {
          margin-left: 3px;
        }
        
        @media (min-width: 2000px) {
          width: 14px;
          height: 14px;
          border-color:$primary;
        }
      }

      &.splide__arrow--next {
        left: auto;
        right: -24px;

        @media (min-width: 1866px) {
          right: 20px;
        }

        @media (min-width: 2000px) {
          right: -58px;
        }
        
        &:after {
          border: none;
          border-right: 2px solid $white;
          border-top: 2px solid $white;
          transform: rotate(45deg);
          margin: 0 22px 0 0;

          @media (min-width: 1866px) {
            margin: 0 3px 0 0;
          }
          
          @media (min-width: 2000px) {
            border-color:$primary;
          }
        }
      }

      svg {
        display: none;
      }
    }

    .splide__pagination {
      bottom: -40px;

      .splide__pagination__page {
        width: 12px;
        height: 12px;
        margin: 0 8px;
        background:rgba(0, 122, 47, .3);
        opacity: 1;
        
        &.is-active {
          background:#007A2F;
          transform: none;
        }
      }

    }

    .splide__toggle {
      background: none;
      border: none;
      position: absolute;
      bottom: 6px;
      left: 6px;
      z-index: 20;
      padding: 0;

      @media (min-width: 991px) {
        bottom: 20px;
        left: 20px;
      }

      .icon {
        display: block;
        width: 20px;
        height: 20px;
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath d='M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9l0-176c0-8.7 4.7-16.7 12.3-20.9z'/%3e%3c/svg%3e") no-repeat center center / contain;

        @media (min-width: 991px) {
          height: 45px;
          width: 45px;
        }
      }


      &.is-active {
        .icon {
          display: block;
          width: 20px;
          height: 20px;
          background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM224 192l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128c0-17.7 14.3-32 32-32s32 14.3 32 32zm128 0l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128c0-17.7 14.3-32 32-32s32 14.3 32 32z'/%3e%3c/svg%3e") no-repeat center center / contain;

          @media (min-width: 991px) {
            height: 45px;
            width: 45px;
          }
        }
      }
    }

    .image {
      /*position:relative;
      img {
        width: 100%;
        max-width: none;
      }*/

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;

        &:focus {
          border: 3px solid #6495ed;
          border-radius: 0;
        }
      }
    }
  }


  .theme-title-wrapper {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 500;
    font-size: 4.5vw;
    line-height: 5.3vw;

    @media (min-width: 991px) {
      font-size: 30px;
    }
  }

  .inner-visual {
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 110;
      opacity: 0.3;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
    }

    overflow: hidden;
    max-height: 300px;
    position: relative;
    z-index: 100;

    .expand-icon {
      display: block;
      width: 50px;
      height: 50px;
      right: 0;
      bottom: 0;
      z-index: 120;
      cursor: pointer;
      position: absolute;
      background: url("data:image/svg+xml,%3Csvg width='51px' height='50px' viewBox='0 0 51 50' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='TikiTaka' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Igra-Tikitaka,-Hitro-3' transform='translate(-1058.000000, -1100.000000)'%3E%3Cg id='fullscreen-copy' transform='translate(1058.990408, 1100.000000)'%3E%3Cpolygon id='Path' fill='%23000000' opacity='0.5' points='0 0 50 0 50 50 0 50'%3E%3C/polygon%3E%3Cpath d='M16.4285714,28.4285714 L13,28.4285714 L13,37 L21.5714286,37 L21.5714286,33.5714286 L16.4285714,33.5714286 L16.4285714,28.4285714 Z M13,21.5714286 L16.4285714,21.5714286 L16.4285714,16.4285714 L21.5714286,16.4285714 L21.5714286,13 L13,13 L13,21.5714286 Z M33.5714286,33.5714286 L28.4285714,33.5714286 L28.4285714,37 L37,37 L37,28.4285714 L33.5714286,28.4285714 L33.5714286,33.5714286 Z M28.4285714,13 L28.4285714,16.4285714 L33.5714286,16.4285714 L33.5714286,21.5714286 L37,21.5714286 L37,13 L28.4285714,13 Z' id='Shape' fill='%23FFFFFF' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") center center/contain no-repeat;
    }

    .element {
      position: relative;

      .content {
        position: relative;
        z-index: 200;
        bottom: 12px;
        left: 50%;
        position: absolute;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        max-width: calc(100% - 20px);

        @include media-breakpoint-up(sm) {
          max-width: 100%;
        }

        @include media-breakpoint-up(md) {
          max-width: 728px;
        }

        @include media-breakpoint-up(lg) {
          bottom: 20px;
        }

        .suptitle {
          color: #fff;
          font-size: 14px;
          margin-bottom: 0px;
          padding: 0;
          line-height: 16px;

          @include media-breakpoint-up(md) {
            font-size: 20px;
          }

          @include media-breakpoint-up(lg) {
            line-height: 29px;
          }
        }

        .title {
          width: 100%;
          color: #fff;
          font-size: 30px;
          line-height: 33px;
          font-family: $font-bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: .5px;

          @include media-breakpoint-up(md) {
            letter-spacing: .8px;
            font-size: 40px;
            line-height: 1.3;
          }

          @include media-breakpoint-up(lg) {
            font-size: 50px;
            line-height: 55px;
          }
        }

        .time-container {
          .time-title {
            color: $ejp;
            font-family: $font-bold;
            font-size: 16px;
            margin: 0 0 5px;
            line-height: 1;

            @include media-breakpoint-up(md) {
              font-size: 17px;
              margin: 0;
            }
          }

          .time {
            color: $ejp;
            font-family: $font-bold;
            font-size: 26px;
            margin: 0;
            letter-spacing: 0;
            line-height: 1;

            @include media-breakpoint-up(md) {
              font-size: 40px;
              line-height: 1.3;
              letter-spacing: .8px;
            }

            @include media-breakpoint-up(lg) {
              font-size: 50px;
              letter-spacing: 0.78px;
            }

            span {
              margin: 0 3px;
            }

            &:after {
              font-family: 'Assets' !important;
              font-size: 12px;
              content: '\e90e';
              margin-left: 5px;
              color: $ejp;

              @include media-breakpoint-up(md) {
                font-size: 22px;
              }
            }
          }
        }
      }

      .link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 200;
      }

      .image {
        max-height: 300px;
        overflow: hidden;

        img {
          width: 100%;
        }

        img {
          vertical-align: middle;
          border-style: none;
          height: auto;
          position: relative;
          margin: 0;
          max-height: 100%;
          max-width: 100%;
        }
      }
    }

    &.novoletni-loto {
      @include media-breakpoint-only(xs) {
        min-height: 100px;
      }
      .element {
        .content {
          bottom: 12px;
          padding: 0 20px !important;
          .suptitle {
            font-size: 14px;
            line-height: normal;
            
            @include media-breakpoint-up(md) {
              font-size: 20px;
              line-height: 20px;
            }
          }
          
          .title {
            font-size: 27px;
            line-height: 1.1;
            letter-spacing: 0.5px;
            
            @include media-breakpoint-up(md) {
              font-size: 40px;
              line-height: 1.3;
              letter-spacing: 0.8px;
            }


            @include media-breakpoint-up(lg) {
              filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
              letter-spacing: 0.8px;
              font-size: 50px;
              line-height: 1.4;
            }
          }
        }
        
        .image {
          img {
            @include media-breakpoint-only(xs) {
              min-height: 100px;
              width: 100%;
              object-fit: cover;
              object-position: right;
            }
          }
        }
      }
    }
  }

  &.ejp {
    .sub-navi {
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $ejp-text;

            &:after {
              background: $ejp-text;
            }
          }
        }
      }
    }
  }

  .home-games-container {
    @include media-breakpoint-down(md) {
      padding: 0px;
    }

    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .home-webgames-container {
    @include media-breakpoint-down(md) {
      padding: 0px;
    }

    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  #main-content {
    position: relative;
    padding-top: 30px;

    @include media-breakpoint-up(lg) {
      padding-top: 40px;
    }

    @include media-breakpoint-up(xl) {
      padding: 40px 0 0px;

      .top-img {
        position: absolute;
        right: 0px;
        top: -195px;
        width: 266px;
        height: 265px;
        transform: translate(0, 0);
        z-index: 80;
      }
    }

    a:not(.btn) {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &.statistic {
      padding: 55px 0 0;
    }

    &.preveri-potrdilo {
      padding: 40px 0 0;

      @include media-breakpoint-up(lg) {
        padding-top: 80px;
      }
    }

    &.home-games-container {
      a {
        text-decoration: none;
      }
    }

    .main-game-content {
      position: relative;

      @include media-breakpoint-up(xl) {
        .left-img {
          position: absolute;
          left: -180px;
          top: 10%;
          width: 360px;
          height: 360px;
        }

        .right-img {
          position: absolute;
          right: -135px;
          top: 30%;
          width: 266px;
          height: 265px;
        }
      }
    }

    &.spletne-igre {
      .main-game-content {
        @include media-breakpoint-up(xl) {
          .left-img {
            position: absolute;
            left: 0px;
            top: 10%;
            width: 230px;
            height: 371px;
          }

          .right-img {
            position: absolute;
            right: 0px;
            top: 30%;
            width: 171px;
            height: 344px;
          }
        }
      }
    }

    &.loto,
    &.novoletni-loto,
    &.ejp,
    &.vikinglotto,
    &.tikitaka,
    &.threexthree,
    &.superloto,
    &.spletne-igre {
      padding-top: 0;
    }
  }

  .sub-navi {
    height: 50px;

    @include media-breakpoint-up(md) {
      height: 60px;
    }

    .navbar-nav {
      .nav-item {
        margin-right: 27px;

        .nav-link {
          font-size: 12px;
          letter-spacing: .5px;
          font-family: $font-bold;
          color: #fff;
          text-transform: uppercase;
          position: relative;
          padding: 8px 0;
          text-decoration: none;

          @include media-breakpoint-up(lg) {
            font-size: 13px;
            letter-spacing: 1.4px;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            background-color: #fff;
            width: 0;
            left: 50%;
            bottom: 12px;
            height: 2px;
            transform: translate(-50%, 0);
            transition: width 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

            @media (max-width: 575px) {
              transition: unset;
            }
          }

          &:hover:after,
          &.active:after {
            width: 100%;
            transition: width 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

            @media (max-width: 575px) {
              transition: unset;
            }
          }
        }

        &.joker {
          background: #fff;
          border-radius: 17px;

          .nav-link {
            padding: 6px 31px 6px 17px;
            color: #000;
            display: inline-block;

            &:before {
              content: '';
              width: 26px;
              height: 26px;
              position: absolute;
              top: 5px;
              right: 7px;
              background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg filter='url(%23filter0_d)'%3e%3cpath d='M16.502 12.1944L20.2284 8.91992L15.3411 8.05471L13.3308 3.52026L10.8951 7.81712L5.9355 8.21047L9.39604 11.8504L8.29609 16.6705L12.8238 14.6298L17.1882 17.1457L16.502 12.1944Z' fill='url(%23paint0_linear)'/%3e%3c/g%3e%3cdefs%3e%3cfilter id='filter0_d' x='0' y='0' width='25.1115' height='25.1115' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3e%3cfeOffset dy='2'/%3e%3cfeGaussianBlur stdDeviation='2'/%3e%3cfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'/%3e%3cfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3e%3cfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3e%3c/filter%3e%3clinearGradient id='paint0_linear' x1='0.815482' y1='17.3927' x2='25.9802' y2='16.749' gradientUnits='userSpaceOnUse'%3e%3cstop offset='0.244792' stop-color='%23FFBE14'/%3e%3cstop offset='0.489583' stop-color='%23CC0007'/%3e%3cstop offset='0.833333' stop-color='%230000FF'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e") no-repeat center center / contain;
            }

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .sub-navi-mobile {
    position: relative;
    height: 31px;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      height: 40px;
    }

    .secondary-nav-selected-text {
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 5px;
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      padding: 0 10px;
      height: 31px;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      min-width: 150px;
      text-indent: 10px;
      color: #fff;

      &:after {
        content: "";
        width: 9px;
        height: 5px;
        display: inline-block;
        margin-left: 10px;
        transition: -webkit-transform .3s ease;
        transition: transform .3s ease;
        transition: transform .3s ease, -webkit-transform .3s ease;
        background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8.333px' height='5px' viewBox='-1.792 5.063 8.333 5' enable-background='new -1.792 5.063 8.333 5' xml:space='preserve'%3E%3Cpolygon id='Triangle-2-Copy-3' fill='%23FFFFFF' points='2.375,10.063 6.541,5.063 -1.791,5.063 '/%3E%3C/svg%3E%0A") no-repeat center center / contain;

        @media (max-width: 575px) {
          transition: unset;
        }
      }

      &.is-open {
        border-radius: 5px 5px 0 0;

        &:after {
          -webkit-transform: translate(0, -50%) rotate(-180deg);
          -ms-transform: translate(0, -50%) rotate(-180deg);
          transform: translate(0, -50%) rotate(-180deg);
        }
      }
    }

    .secondary-nav {
      padding-bottom: 3px;
      display: none;
      border: 1px solid #fff;
      border-radius: 0 0 5px 5px;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      z-index: 9;
      margin-top: -1px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0;
          padding: 0;

          .secondary-nav-link {
            display: block;
            padding: 7px 10px;
            text-align: center;
            top: 0;
            color: #fff;
            font-size: 12px;
            line-height: 13px;
            font-family: $font-bold;
          }
        }
      }

      &.is-open {
        display: block;
      }
    }

    &.nologo {
      margin: 0 5%;
      width: 90%;

      @include media-breakpoint-up(sm) {
        width: 220px;
        margin: 0 auto;
      }

      .secondary-nav-selected-text {
        width: 100%;
      }

      .secondary-nav {
        background: $primary;
      }
    }
  }

  .sub-sub-navi-mobile {
    position: relative;
    height: 31px;
    text-align: center;
    text-transform: uppercase;
    margin: 0 5%;
    width: 90%;

    @include media-breakpoint-up(sm) {
      width: 220px;
      margin: 0 auto;
    }

    @include media-breakpoint-up(lg) {
      height: 40px;
    }

    .secondary-sub-nav-selected-text {
      cursor: pointer;
      border: 1px solid $primary;
      border-radius: 5px;
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      padding: 0 10px;
      height: 31px;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      min-width: 150px;
      width: 100%;
      text-indent: 10px;
      color: $primary;

      &:after {
        content: "";
        width: 9px;
        height: 5px;
        display: inline-block;
        margin-left: 10px;
        transition: -webkit-transform .3s ease;
        transition: transform .3s ease;
        transition: transform .3s ease, -webkit-transform .3s ease;
        background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8.333px' height='5px' viewBox='-1.792 5.063 8.333 5' enable-background='new -1.792 5.063 8.333 5' xml:space='preserve'%3E%3Cpolygon fill='%23007a2f' points='2.375,10.063 6.541,5.063 -1.791,5.063 '/%3E%3C/svg%3E%0A") no-repeat center center / contain;

        @media (max-width: 575px) {
          transition: unset;
        }
      }

      &.is-open {
        border-radius: 5px 5px 0 0;

        &:after {
          -webkit-transform: translate(0, -50%) rotate(-180deg);
          -ms-transform: translate(0, -50%) rotate(-180deg);
          transform: translate(0, -50%) rotate(-180deg);
        }
      }
    }

    .secondary-sub-nav {
      padding-bottom: 3px;
      display: none;
      border: 1px solid $primary;
      border-radius: 0 0 5px 5px;
      position: absolute;
      left: 0;
      top: 100%;
      background: #fff;
      width: 100%;
      z-index: 9;
      margin-top: -1px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0;
          padding: 0;

          .secondary-sub-nav-link {
            display: block;
            padding: 7px 10px;
            text-align: center;
            top: 0;
            color: $primary;
            font-size: 12px;
            line-height: 13px;
            font-family: $font-bold;
          }
        }
      }

      &.is-open {
        display: block;
      }
    }
  }

  .sub-sub-navi {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    scrollbar-color: $primary #fff !important;
    scrollbar-width: thin;

    -moz-appearance: none !important;

    ::-webkit-scrollbar {
      height: 3px;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: $primary;
    }

    -ms-scrollbar-highlight-color: #fff;
    scrollbar-highlight-color: #fff;
    -ms-scrollbar-face-color: $primary;
    scrollbar-face-color: $primary;
    -ms-scrollbar-arrow-color: transparent;
    scrollbar-arrow-color: transparent;

    /*
    @include media-breakpoint-down(sm) {
      &:before {
        content: '';
        display: block;
        width: 40px;
        height: 100%;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 34%, rgba(255, 255, 255, 1) 100%);
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 100;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 6px;
        border-color: transparent transparent transparent $primary;
        display: block;
        position: absolute;
        bottom: 24px;
        right: 10px;
        z-index: 100;
      }
    }*/

    .navbar {
      overflow: auto;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        overflow: hidden;
        white-space: none;
      }


      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $primary;
            letter-spacing: normal;
            margin-left: 2px;
            text-decoration: none;

            &:after {
              background-color: $primary;
            }
          }
        }
      }
    }
  }

  .sub-navigation-container {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 20;

    &.joker {
      .inner {
        background: #fff;

        .sub-navi {
          .navbar-nav {
            .nav-item {
              .nav-link {
                color: $loto;

                &:after {
                  background: $loto;
                }
              }

              &.joker {
                position: relative;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                z-index: 100;
                transform-style: preserve-3d;

                .nav-link {
                  position: relative;
                  z-index: 100;

                  &.active {
                    &:after {
                      display: block;
                      background: $loto;
                      width: 44px;
                      left: 16px;
                      bottom: 6px;
                      transform: translate(0, 0);
                    }
                  }
                }

                &:before {
                  content: '';
                  position: absolute;
                  width: 76px;
                  display: block;
                  height: 27px;
                  right: 5px;
                  bottom: 0;
                  background: linear-gradient(73.74deg, #FFBE14 -18.61%, #CC0007 37.15%, #0000FF 126.39%);
                  filter: blur(13px);
                  border-radius: 40px;
                  transform: translateZ(-1px);
                }

                /*&:after {
                  content:'';
                  position: absolute;
                  width: 76px;
                  display: block;
                  height: 27px;
                  left: 0;
                  top: 3px;
                  background: linear-gradient(73.74deg, #FFBE14 -18.61%, #CC0007 37.15%, #0000FF 126.39%);
                  filter: blur(13px);
                  border-radius: 40px;
                }*/
              }
            }
          }
        }

        .sub-navi-mobile {
          .secondary-nav-selected-text {
            color: $text-primary;
            border-color: $text-primary;

            &:after {
              background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8.333px' height='5px' viewBox='-1.792 5.063 8.333 5' enable-background='new -1.792 5.063 8.333 5' xml:space='preserve'%3E%3Cpolygon id='Triangle-2-Copy-3' fill='%23575757' points='2.375,10.063 6.541,5.063 -1.791,5.063 '/%3E%3C/svg%3E%0A") center center / contain no-repeat;
            }
          }

          .secondary-nav {
            background-color: white;
            color: $text-primary;
            border-color: $text-primary;

            .secondary-nav-link {
              color: $text-primary;
            }
          }
        }
      }

      &.vikinglotto {
        .inner {
          .sub-navi {
            .navbar-nav {
              .nav-item {
                .nav-link {
                  color: $viking-game;

                  &:after {
                    background: $viking-game;
                  }
                }

                &.joker {
                  .nav-link {
                    &.active {
                      &:after {
                        background: $viking-game;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.ejp {
        .inner {
          .sub-navi {
            .navbar-nav {
              .nav-item {
                .nav-link {
                  color: $ejp-text;

                  &:after {
                    background: $ejp-text;
                  }
                }

                &.joker {
                  .nav-link {
                    &.active {
                      &:after {
                        background: $ejp-text;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.ejp {
      .sub-navi-mobile {
        .secondary-nav-selected-text {
          color: $ejp-text;
          border-color: $ejp-text;

          &:after {
            background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8.333px' height='5px' viewBox='-1.792 5.063 8.333 5' enable-background='new -1.792 5.063 8.333 5' xml:space='preserve'%3E%3Cpolygon id='Triangle-2-Copy-3' fill='%23581e00' points='2.375,10.063 6.541,5.063 -1.791,5.063 '/%3E%3C/svg%3E%0A") center center / contain no-repeat;
          }
        }

        .secondary-nav {
          color: $ejp-text;
          border-color: $ejp-text;

          .secondary-nav-link {
            color: $ejp-text;
          }
        }
      }
    }
  }
}

div.bg-ejp {
  .secondary-nav-selected-text {
    border-color: $ejp-text;
    color: $ejp-text;

    &:after {
      background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8.333px' height='5px' viewBox='-1.792 5.063 8.333 5' enable-background='new -1.792 5.063 8.333 5' xml:space='preserve'%3E%3Cpolygon id='Triangle-2-Copy-3' fill='%23581e00' points='2.375,10.063 6.541,5.063 -1.791,5.063 '/%3E%3C/svg%3E%0A") center center / contain no-repeat;
    }
  }

  .secondary-nav {
    background-color: $ejp;
    border-color: $ejp-text;

    ul {
      li {
        .secondary-nav-link {
          color: $ejp-text;
        }
      }
    }
  }
}

div.bg-vikinglotto {
  .secondary-nav {
    background-color: $viking;
  }
}

div.bg-tikitaka {
  .secondary-nav {
    background-color: $tikitaka;
  }
}

div.bg-threexthree {
  .secondary-nav {
    background-color: $threexthree;
  }
}

div.bg-spletne-igre,
div.bg-srecke {
  .secondary-nav {
    background-color: $srecke;
  }
}

#account-fixed2 {
  position: fixed;
  right: 0;
  top: 70px;
  z-index: 300;
  padding: 0;
  display: none;

  @media (max-width: 768px) {
    top: auto;
    bottom: 0;
    width: 100%;
    background-color: #f8f7f4;
    text-align: -webkit-right;
  }

  @include media-breakpoint-up(lg) {
    top: 140px;
  }

  .popup-element {
    position: relative;
    text-decoration: none;
    width: 50px;
    height: 50px;
    border-radius: 5px 0 0 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    background-color: #f8f7f4;
    cursor: pointer;
    transition: all 0.3s;

    @include media-breakpoint-up(md) {
      background: #fff;
    }

    @media (max-width: 575px) {
      transition: unset;
    }

    &.star {
      div.icon {
        position: relative;
        width: 50px;
        height: 50px;
        text-align: center;
        float: left;
        border-radius: 5px 0 0 5px;

        .icon {
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='22px' height='22px' viewBox='0 0 22 22' enable-background='new 0 0 22 22' xml:space='preserve'%3E%3Cpolygon id='Shape_1_' fill='%23008533' points='11,16.592 17.802,21.541 15.198,13.549 22,8.708 13.658,8.708 11,0.458 8.342,8.708 0,8.708 6.802,13.549 4.198,21.541 '/%3E%3C/svg%3E%0A");
          width: 22px;
          height: 22px;
          display: block;

          @media (max-width: 768px) {
            margin-right: 14px;
          }
        }

        @media (max-width: 768px) {
          justify-content: flex-end !important;
          height: 30px;
          right: 10px;
        }
      }

      .notifications-count {
        position: absolute;
        top: 11px;
        left: 8px;
        background: #d0021b;
        color: #fff;
        width: 14px;
        height: 14px;
        z-index: 110;
        font-size: 9px;
        border-radius: 50%;
        text-align: center;
        display: none;
      }

      @media (max-width: 768px) {
        border: 0;
        box-shadow: none;
      }
    }

    .close {
      position: absolute;
      top: 17px;
      right: 13px;
      opacity: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: thin solid #b6b6b6;
      transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
      transition: transform 0.3s ease, opacity 0.3s ease;
      transition: transform 0.3s ease, opacity 0.3s ease,
        -webkit-transform 0.3s ease;
      -webkit-transform: translate(calc(100% + 20px), 0);
      -ms-transform: translate(calc(100% + 20px), 0);
      transform: translate(calc(100% + 20px), 0);

      @media (max-width: 575px) {
        transform: unset;
        transition: unset;
      }

      span {
        width: 8px;
        height: 8px;
        border-style: solid;
        border-color: #b6b6b6;
        border-width: 1px 1px 0 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        display: block;
        top: 10px;
        left: 9px;
        position: absolute;

        @media (max-width: 768px) {
          transform: unset;
          width: 24px;
          height: 24px;
          right: 12px;
          top: 14px;
          border: 1px solid #00953a;
          border-radius: 50%;
          background: transparent url("data:image/svg+xml,%3Csvg width='15px' height='15px' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Elements--/-iC-Clear'%3E%3Crect id='Rectangle' x='2.27373675e-13' y='0' width='15' height='15'%3E%3C/rect%3E%3Cpath d='M7.5,1.5 L7.5,13.5' id='Line' stroke='%2300953A' stroke-width='1.5' stroke-linecap='square' transform='translate(7.500000, 7.500000) rotate(45.000000) translate(-7.500000, -7.500000) '%3E%3C/path%3E%3Cpath d='M7.5,1.5 L7.5,13.5' id='Line' stroke='%2300953A' stroke-width='1.5' stroke-linecap='square' transform='translate(7.500000, 7.500000) rotate(135.000000) translate(-7.500000, -7.500000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") center center/10px auto no-repeat;
        }
      }

      @media (max-width: 768px) {
        border: none;
        top: 0;
        right: 14px;
        transition: unset;
        background: transparent !important;
      }
    }

    .notifications-content {
      opacity: 0;

      .inner {
        height: 50px;
      }
    }

    &.wallet {
      .icon-wallet {
        font-size: 20px;
        line-height: 26px;
      }
    }

    &.countdown {
      .icon-clock {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &.active {
      width: 470px;
      min-height: 400px;
      height: 100%;
      padding: 10px 16px;
      background-color: $white;

      div.icon {
        border-radius: unset !important;
        margin-bottom: 10px;

        .icon {
          margin-right: 24px;
        }

        @media (max-width: 768px) {
          position: absolute;
          bottom: 0;
          margin-bottom: 0;
          width: 100%;
          left: 0;
          right: 0;
          background-color: #f8f7f4;
        }
      }

      .notifications-content {
        opacity: 1;
      }

      .close {
        opacity: 1;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      height: 30px;
      transition: unset;
    }
  }

  iframe {
    .body-standalone {
      padding-right: 10px;
    }
  }

  .notification-list {
    padding: 20px !important;
  }
}

.notifications-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 700;
}

#account-fixed {
  position: fixed;
  right: 0;
  top: 70px;
  z-index: 710;
  padding: 0;

  @include media-breakpoint-up(md) {
    border-radius: 5px 0 0 5px;
    margin-bottom: 10px;
  }

  @include media-breakpoint-up(lg) {
    top: 140px;
  }

  @include media-breakpoint-down(sm) {
    top: inherit;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 0 20px;
    background-color: #f8f7f4;
    display: -ms-flexbox;
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .fixed-button-popup {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    display: inline-block;

    @include media-breakpoint-up(md) {
      display: block;
      position: relative;
      overflow: hidden;
      margin-left: auto;
      width: 50px;
      height: 50px;
      border-radius: 4px 0 0 4px;
      box-shadow: 0 2px 4px #0000004d;
      background-color: #fff;
      z-index: 100;
      transition: all .3s ease;
    }

    button {
      border: none;
      outline: none;
      background: none;
    }

    .fixed-button {
      @include media-breakpoint-down(sm) {
        width: 30px;
        height: 30px;
      }

      cursor: pointer;
      position: relative;
      display: inline-flex;
      align-items:center;
      justify-content: center;

      @include media-breakpoint-up(md) {
        width: 50px;
        height: 50px;
        transition: transform .3s ease;
      }

      .favorite-count {
        left: -3px;
        top: 3px;
        border-radius: 100%;
        font-size: 8px;
        height: 14px;
        padding: 3px 1px;
        position: absolute;
        text-align: center;
        width: 14px;
        z-index: 1;
        color: #fff;
        line-height: 9px;

        @include media-breakpoint-up(md) {
          top: 11px;
          left: 8px;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: #cc0007;
          border-radius: inherit;
          z-index: -1;
        }

      }

      .icon-star {
        width: 22px;
        min-width: 22px;
        height: 22px;
        position: relative;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        display: block;
        outline-offset: 6px;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='22px' height='22px' viewBox='0 0 22 22' enable-background='new 0 0 22 22' xml:space='preserve'%3E%3Cpolygon id='Shape_1_' fill='%23008533' points='11,16.592 17.802,21.541 15.198,13.549 22,8.708 13.658,8.708 11,0.458 8.342,8.708 0,8.708 6.802,13.549 4.198,21.541 '/%3E%3C/svg%3E%0A");

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          opacity: 0;
          transition: opacity .3s ease;
        }
      }
    }

    .fixed-button-close {
      right: 10px;
      top: 20px;
      position: absolute;
      opacity: 0;
      pointer-events: none;
      z-index: 9;
      display: none;
      transition: opacity .3s ease;

      width: 30px;
      height: 30px;
      display: block;
      border-radius: 100%;
      border: thin solid #b6b6b6;
      background: transparent url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='15px' height='15px' viewBox='0 0 15 15' enable-background='new 0 0 15 15' xml:space='preserve'%3E%3Cg id='Group'%3E%3Crect id='Rectangle' fill='none' width='15' height='15'/%3E%3Cpolyline id='ic_arrow-copy-3' fill='none' stroke='%23575757' stroke-width='1.5' points='4.75,2 10.25,7.5 4.75,13 '/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center center/10px auto;

      @include media-breakpoint-up(md) {
        transition: transform .3s ease, opacity .3s ease;
        -ms-transform: translate(calc(100% + 20px), 0);
        transform: translate(calc(100% + 20px));
      }

      .fixed-button-close {
        -ms-transform: translate(0, 0);
        transform: translate(0);
        display: block;
        opacity: 1;
        pointer-events: auto;
      }

      @include media-breakpoint-down(sm) {
        width: 24px;
        height: 24px;
        right: 14px;
        top: 12px;
        border-color: #007a2f;
        background: transparent url("data:image/svg+xml,%3Csvg width='15px' height='15px' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Elements--/-iC-Clear'%3E%3Crect id='Rectangle' x='2.27373675e-13' y='0' width='15' height='15'%3E%3C/rect%3E%3Cpath d='M7.5,1.5 L7.5,13.5' id='Line' stroke='%23007A2F' stroke-width='1.5' stroke-linecap='square' transform='translate(7.500000, 7.500000) rotate(45.000000) translate(-7.500000, -7.500000) '%3E%3C/path%3E%3Cpath d='M7.5,1.5 L7.5,13.5' id='Line' stroke='%23007A2F' stroke-width='1.5' stroke-linecap='square' transform='translate(7.500000, 7.500000) rotate(135.000000) translate(-7.500000, -7.500000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center/10px auto;
      }

    }

    .notifications-popup-outside-wrapper {
      @include media-breakpoint-down(sm) {
        display: none;
        position: fixed;
        bottom: 30px;
        left: 0;
        width: 100%;
        margin: 0;
        background-color: #fff;
        max-height: 70vh;
        overflow: hidden;
      }

      .notifications-popup-header {
        position: relative;
        width: 100%;
        height: 10vh;
        max-height: 60px;

        @include media-breakpoint-up(md) {
          display: none;
        }

        .fixed-button-close {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);

          @include media-breakpoint-up(md) {
            right: 30px;
          }
        }
      }
    }


    .notifications-popup-wrapper {
      display: none;
      margin: 10px 10px 10px 0;
      scrollbar-track-color: #ffffff;
      scrollbar-darkshadow-color: #b6b6b6;
      scrollbar-arrow-color: #d8d8d8;

      @include media-breakpoint-down(sm) {
        max-height: 400px;
      }
    }

    &.is-open {
      @include media-breakpoint-up(md) {
        width: 470px;
        height: auto;
      }

      .fixed-button {
        @include media-breakpoint-up(md) {
          -ms-transform: translate(16px, 10px);
          transform: translate(16px, 10px);
        }
      }

      .fixed-button-close {
        -ms-transform: translate(0, 0);
        transform: translate(0);
        display: block;
      }

      .right-arrow--circle {
        opacity: 1;
        pointer-events: auto;
      }

      .notifications-popup-outside-wrapper {
        @include media-breakpoint-down(sm) {
          display: block;
        }
      }

      .notifications-popup-wrapper {
        display: block;
      }
    }
  }

}

.section-heading {
  position: relative;
  padding: 0 20px;
  height: 70px;

  @include media-breakpoint-only(md) {
    padding: 0 30px;
  }

  @include media-breakpoint-up(md) {
    height: 101px;
  }

  h2 {
    font-family: $font-bold;
    color: $primary;
    font-size: 16px;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(md) {
      font-size: 27px;
    }
  }
}

.report {
  position: relative;
  z-index: 200;
}

#main.all-results {
  .number {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 15px;

    &:before {
      height: 32px;
      top: -1px;
    }
  }
}


#create-account-banner {
  position: relative;
  overflow: hidden;
  margin-top: 30px;

  @include media-breakpoint-down(md) {
    overflow: unset;
    margin-bottom: 30px;

    .btn .create-account-btn .ismobile {
      margin-top: 20px !important;
    }
  }

  &:before {
    content: "";
    background: url(../img/gradient-account.svg) no-repeat center center;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    left: 50%;
    opacity: 0.4;
    transform: translate(-51%, 0%);

    @include media-breakpoint-up(md) {
      background: url(../img/gradient-account.svg) no-repeat 65% 6px;
      transform: translate(0%, 0%);
      left: 0;
      bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      background: url(../img/gradient-account.svg) no-repeat 58% 6px;
      transform: translate(0%, 0%);
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .title {
    font-size: 26px;
    font-family: $font-bold;
    color: $text-primary;
    margin-bottom: 5px;

    @include media-breakpoint-down(md) {
      font-size: 27px;
      margin-bottom: 10px;
      line-height: 32px;
    }
  }

  .subtitle {
    font-size: 16px;
    font-family: $font-bold;
    color: $primary;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 22px;
    }
  }

  .line {
    font-size: 16px;
    font-family: $font-bold;
    color: $text-secondary;
    margin: 9px 0;
    letter-spacing: 0.3px;

    .icon {
      width: 70px;
      height: 70px;

      @include media-breakpoint-up(md) {
        margin-right: 17px;
      }

      .access-theme {}
    }

    .text {
      @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 14px;
      }
    }

    @include media-breakpoint-down(md) {
      margin: 0;
    }
  }

  .create-account-btn {
    &.isdesktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: inline-block;
      }
    }

    &.ismobile {
      display: inline-block;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}

#players-account {
  .element {
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-bottom: 0 !important;
    }

    .title {
      font-size: 14px;
      line-height: 24px;
      font-family: $font-bold;
    }

    .icon {
      height: 75px;
      margin-bottom: 5px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 25px;
      }

      .animation {
        height: 52px;
      }
    }

    &:first-child {
      .icon {
        .animation {
          height: 61px;
        }
      }
    }

    &:last-child {
      .icon {
        .animation {
          height: 77px;
        }
      }
    }
  }

  .create-account.btn {
    min-width: 180px;

    .icon-user-plus {
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3e%3cpath fill='%23ffffff' d='M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
    }

    &:hover {
      .icon-user-plus {
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3e%3cpath fill='%23007A2F' d='M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
      }
    }
  }
}

#account-advantages {
  padding-top: 10px;
  padding-bottom: 30px;

  @include media-breakpoint-up(md) {
    padding-bottom: 60px;
  }

  h2 {
    font-size: 26px;
    font-family: $font-bold;
    line-height: 30px;

    @include media-breakpoint-up(md) {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 46px;
    }
  }

  .element {
    .title {
      font-size: 20px;
      line-height: 24px;
      font-family: $font-bold;
    }

    .icon {
      background: #e6e7e8;
      width: 85px;
      height: 85px;
      text-align: center;
      border-radius: 50%;

      .animation {
        width: 90px;
        width: 80px;
        margin: 0 auto;
      }
    }

    .description {
      font-size: 14px;
      line-height: 24px;
      font-family: $font-normal;
      color: $text-primary;
    }

    &:first-child {
      .icon {
        .animation {
          width: 61px;
        }
      }
    }

    &:nth-child(2) {
      .icon {
        .animation {
          width: 80px;
        }
      }
    }
  }

  .create-account {
    min-width: 0;
    width: 180px;
    height: 40px;
  }
}

#sales-action {
  color: #fff;
  font-size: 14px;
  margin-bottom: 90px;

  .image {
    margin-bottom: -60px;
  }

  .content-inner *:not(h2) {
    opacity: 0.8;
  }

  .content-inner {
    h2 {
      font-size: 35px;
      font-family: $font-bold;
      margin-bottom: 20px;
    }
  }
}

.igralni-racun-first,
.igralni-racun-second,
.igralni-racun-third {
  width: 100%;
  justify-content: center;
  align-items: center !important;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    width: 760px;
  }

  lottie-player {
    width: 180px;
    margin: 30px auto 0;
  }
}

.igralni-racun-first,
.igralni-racun-third {
  lottie-player {
    width: 145px;
  }

  @include media-breakpoint-up(md) {
    div:first-child {
      order: 2;
    }
  }

  div:last-child {
    display: flex;
    justify-content: center;
  }
}

.igralni-racun-third {
  lottie-player {
    width: 144px;

    @include media-breakpoint-up(md) {
      width: 180px;
    }
  }
}

.igralni-racun-title {
  h2 {
    font-size: 16px;
    font-family: $font-bold;
    color: $primary;
  }

  lottie-player {
    width: 180px;
  }
}

.logos {
  padding-left: 2px !important;
  padding-right: 2px !important;

  .logo {
    @include media-breakpoint-up(lg) {
      height: 185px;
    }

    .inner {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
      background-color: #ffffff;
      position: relative;
      transition: box-shadow 0.2s;

      @media (max-width: 575px) {
        transition: unset;
      }

      .image {
        height: 70px;
      }

      .title {
        color: $text-primary;
        font-size: 14px;
        text-decoration: underline;
        font-family: $font-bold;
      }

      .overlay {
        width: 100%;
        text-align: center;
        background: #fff;
        padding-bottom: 25px;

        @include media-breakpoint-up(lg) {
          position: absolute;
          top: 87%;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.15s, visibility 0.15s;

          @media (max-width: 575px) {
            transition: unset;
          }
        }

        a {
          color: $primary;
          font-size: 12px;

          .icon {
            font-size: 26px;
          }
        }

        a[href$=".pdf"] {
          margin: 0;

          &:before {
            content: "";
            margin-right: 7px;
            top: 0;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {

      &:hover,
      &:focus,
      &:focus-within {
        .inner {
          box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.5);

          .overlay {
            opacity: 1;
            visibility: visible;
            z-index: 100;
          }
        }
      }
    }
  }
}

.photos {
  .photo {
    height: 170px;

    a {
      display: block;
      border: solid 1px #979797;
    }
  }
}

#subscribe {
  .subscribe-content {
    position: relative;
    z-index: 100;
  }

  .aeroplane-animation-mobile {
    position: relative;
    top: -20px;
  }

  .aeroplane-animation {
    position: relative;
    top: -10px;

    @include media-breakpoint-up(lg) {
      top: -30px;
    }

    @include media-breakpoint-up(xl) {
      top: -50px;
    }
  }

  h2 {
    font-size: 26px;
    font-family: $font-bold;
    margin-bottom: 15px;
  }

  #newsletter-subscribe {
    margin: 40px auto 15px;

    @include media-breakpoint-down(md) {
      margin: 0 auto 15px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -50px;
    }

    small {
      @include media-breakpoint-up(sm) {
        width: 380px;
        margin-bottom: 3px;
      }
    }

    label {
      @include media-breakpoint-up(sm) {
        width: 380px;
      }
    }

    input {
      &.input {
        width: 250px;
        border: none;
        border: 1px solid $gray3;
        padding: 15px;
        background: none;
        border-radius: 4px;

        @include media-breakpoint-up(sm) {
          width: 380px;
        }

        &:focus {
          @extend .input-focus;
        }

        &::-webkit-placeholder {
          color: $gray3;
        }
      }

      &.error {
        border: 2px solid #b40007;
      }
    }

    .newsletter-options {
      margin-top: 25px;
      display: none;
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 380px;
      }

      &.active {
        display: block;
      }

      .games {
        display: none;
        margin: 5px 0 0 30px;

        &.active {
          display: block;
        }
      }

      .newsletter-option {
        position: relative;

        label {
          margin: 0;
          display: block;
          position: relative;
          padding-left: 30px;
          padding-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          font-size: 13px;
          line-height: 20px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: 1px solid $green;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:focus~.checkmark {
              border: 1px solid $green;
              outline: 2px solid $green;
              outline-offset: 2px;
            }

            &:checked~.checkmark {
              background-color: $green;
            }

            &:checked:focus~.checkmark {
              //border: 1px solid #fff;
              outline: 2px solid $green;
              outline-offset: 2px;
            }

            &:checked~.checkmark:after {
              display: block;
            }
          }

          .checkmark:after {
            left: 6px;
            top: 2px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          height: 20px;

          &:focus {
            outline: 2px solid $green;
            outline-offset: 2px;
          }

          .icon {
            width: 20px;
            height: 20px;
            z-index: 400;
            display: inline-block;
            cursor: pointer;
            position: relative;

            &:after {
              position: absolute;
              content: ' ';
              top: 5px;
              left: 9px;
              height: 9px;
              width: 2px;
              background-color: $primary;
            }

            &:after {
              transform: rotate(-90deg);
            }
          }

          &.collapsed {
            .icon {
              width: 20px;
              height: 20px;
              z-index: 400;
              display: inline-block;
              cursor: pointer;
              position: relative;

              &:before,
              &:after {
                position: absolute;
                content: ' ';
                top: 5px;
                left: 9px;
                height: 9px;
                width: 2px;
                background-color: $primary;
              }

              &:after {
                transform: rotate(-90deg);
              }
            }
          }
        }

        .collapse,
        .collapsing {
          p {
            line-height: 18px;
            font-size: 12px;
            padding-left: 30px;
            margin-bottom: 10px;
            max-width: 400px;

            &:last-child {
              margin-bottom: 20px;
            }
          }
        }

        &.hasErrors {
          label {
            .checkmark {
              border: 2px solid #b40007;
            }

            input {
              &:focus~.checkmark {
                border: 2px solid #b40007;
              }

              &:checked~.checkmark {
                border: 1px solid $green;
              }

            }
          }
        }
      }

      a {
        text-decoration: underline;
      }
    }

    .privacy-text {
      font-size: 13px;
      margin-top: 20px;
      line-height: 1.31;
      letter-spacing: 0.25px;
      color: rgba(74, 74, 74, .8);

      a {
        color: $primary;
        text-decoration: underline;
      }
    }

    .newsletter-error {
      margin-top: 10px;
      color: #b40007;
      width: 100%;
      max-width: 380px;
      text-align: left;
      display: none;
      font-size: 13px;

      &.show {
        display: flex;
      }
    }
  }

  #newsletter-success {
    text-align: center;

    .icon {
      width: 100px;
      margin: 0 auto;
    }
  }
}

.select2-container--default .select2-selection--single {
  height: 40px !important;
  line-height: 40px;

  .select2-selection__rendered {
    height: 40px !important;
    line-height: 40px;
    color: #4a4a4a;
    font-size: 16px;
    padding-right: 35px;
  }

  .select2-selection__arrow {
    width: 40px;
    height: 34px !important;
    top: 3px !important;
    right: 2px !important;

    b {
      border-color: #747474 transparent transparent transparent;
      border-width: 5px 5px 0 5px;
    }
  }
}

.datepicker table {
  width: auto !important;

  tr {

    td.active,
    td span.active {
      background: $primary !important;
      color: #fff;
    }
  }
}

.pagination {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 30px;

  .pagination-page {
    color: $gray;
    text-align: center;
    line-height: 20px;
    margin: 0 8px;
    font-size: 18px;

    &:hover,
    &.pagination-active {
      color: $green;
      text-decoration: none;
    }
  }

  .pagination-arrow {
    width: 9px;
    height: 9px;
    border-style: solid;
    border-color: green;
    border-width: 2px 2px 0 0;
    transform: rotate(45deg);
    margin: 0 0 0 12px;
    display: block;

    &.left {
      border-width: 0 0 2px 2px;
      margin: 0 12px 0 0;
    }
  }

  .pagination-separator {
    margin: 0 7px;
  }
}

.accordion-container {
  h2 {
    font-size: 26px;
    font-family: $font-bold;
    line-height: 30px;

    @include media-breakpoint-up(md) {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 46px;
    }
  }

  .accordion-suptitle {
    font-family: $font-bold;
    color: $primary;
    margin: 40px 0 10px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .31px;

    h2 {
      font-family: $font-bold;
      color: $primary;
      margin: 40px 0 10px;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: .31px;
    }
  }

  .accordion {
    margin-top: 30px;
    margin-bottom: 30px;

    .acc-element {
      border: none;
      border-bottom: 1px solid #e1e1e1;

      .acc-element-header {
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;

        .acc-element-title {
          padding: 11px 0px 12px 0px;
          margin: 0;

          @include media-breakpoint-up(md) {
            padding: 11px 20px 12px 0px;
          }

          button {
            border: none;
            background: none;
            padding: 0;
            font-size: 16px;
            font-family: $font-normal;
            color: $primary;
            text-decoration: none;
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
              font-size: 20px;
            }

            .icon {
              width: 20px;
              height: 20px;
              position: relative;
              display: inline-block;
              cursor: pointer;

              &:after {
                content: ' ';
                position: absolute;
                top: 4px;
                right: 9px;
                height: 12px;
                width: 2px;
                background-color: $primary;
                transform: rotate(-90deg);
              }
            }

            &.collapsed {
              color: $text-primary;

              .icon {

                &:before,
                &:after {
                  content: ' ';
                  position: absolute;
                  top: 4px;
                  right: 9px;
                  height: 12px;
                  width: 2px;
                  background-color: $primary;
                }

                &:after {
                  transform: rotate(-90deg);
                }
              }
            }
          }
        }
      }

      .acc-element-body {
        padding: 5px 20px 20px 0;
        font-size: 16px;
        line-height: 26px;

        @include media-breakpoint-up(md) {
          padding-right: 60px;
        }

        ul {
          padding-left: 20px;
        }


        .dobitki {
          width: 100% !important;
          margin: 0;

          @include media-breakpoint-up(md) {
            width: 84% !important;
            margin: 0 8%;
          }
        }

        .content-wrapper {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: 60%;
          }
        }

        .dobitki {
          width: 100% !important;
          margin: 0;

          @include media-breakpoint-up(md) {
            width: 84% !important;
            margin: 0 8%;
          }
        }

        .nav-pills {
          padding: 0;
          margin-bottom: 20px;

          .nav-item {
            .nav-link {
              background: #fff;
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
              color: $primary;
              border-radius: 0;
              padding: 15px;

              @include media-breakpoint-up(lg) {
                height: 110px;
                padding: 30px;
              }

              &.active {
                background: $primary;
                color: #fff;

                @include media-breakpoint-up(lg) {
                  &:after {
                    content: "";
                    border-left: 13px solid transparent;
                    border-right: 13px solid transparent;
                    border-bottom: 13px solid $primary;
                    position: absolute;
                    bottom: -20px;
                  }
                }
              }
            }
          }
        }

        .tab-content {
          .tab-pane {
            background: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
            color: $primary;
            border-radius: 0;
            padding: 30px;
            font-size: 14px;

            &.active {
              background: $primary;
              color: #fff;
            }
          }
        }
      }

      &:nth-last-of-type(1) {
        border-bottom: none;
      }
    }
  }
}

.footer-banner {
  max-height: 300px;
  overflow: hidden;

  a {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }

    &:focus {
      border: 2px solid #6495ed;
    }
  }

  &.srecazavse {
    max-height: initial;
  }
}

.tickets-banner {
  a {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }

    &:focus {
      border: 2px solid #6495ed;
    }
  }
}

.tickets-banner {
  a {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }

    &:focus {
      border: 2px solid #6495ed;
    }
  }
}

body {
  &.footer-navi {
    background: #fff;

    h1 {
      color: $primary;
    }
  }

  &.error-page {
    background: #fff;

    h1 {
      font-size: 50px;
      font-family: $font-normal;
      margin-bottom: 25px;
      color: $text-primary;
      line-height: 54px;
    }

    #main {
      .container-s {
        @include media-breakpoint-down(sm) {
          padding: 0;

          .row {
            margin: 0;
          }
        }
      }

      .content-edit {
        @include media-breakpoint-down(sm) {
          padding: 0 20px;
        }
      }
    }
  }

  &.players {
    .content-edit {
      h2 {
        margin-bottom: 10px;
        font-size: 20px;
      }

      a {
        color: $primary;
        text-decoration: underline;
      }
    }
  }

  &.cookies {

    h1,
    h2 {
      color: $green;
    }
  }

  &.vplacila,
  &.druzbena,
  &.pricelist {
    background: #f9f9f9;
  }

  &.loto,
  &.novoletni-loto,
  &.ejp,
  &.tikitaka,
  &.vikinglotto,
  &.threexthree,
  &.spletne-igre,
  &.prodajna-mesta {
    #main {
      .inner-visual {
        max-height: 500px;

        .element {
          .image {
            max-height: 500px;
          }
        }

        .content {
          padding: 0 10px !important;


          @include media-breakpoint-up(lg) {
            max-width: 1000px;
          }

          @include media-breakpoint-up(xl) {
            max-width: 1200px;
          }
        }
      }
    }
  }

  &.tikitaka {
    #main {
      .inner-visual {
        iframe {
          display: block;
          position: relative;
          z-index: 120;
        }

        &:before {
          background: none;
        }

        .element {
          background: #105d95;

          /*
          .content {
            .row {
              @include media-breakpoint-down(sm) {
                justify-content: center;
                flex-direction: column;
                align-items: center !important;
              }

              .tikitaka_banner_logo {
                position: relative;
                max-width: 100%;
                text-align: center;
                margin: -50px 0 25px;
                right: 0;
                bottom: -7px;


                @include media-breakpoint-up(md) {
                  max-width: 240px;
                  position: absolute;
                  right: -10px;
                  bottom: 40px;
                  margin: 0;
                }

                @include media-breakpoint-up(lg) {
                  max-width: 340px;
                  bottom: 10px;
                  right: 0;
                }

                @include media-breakpoint-up(xl) {
                  max-width: 384px;
                  right: 0;
                  bottom: -7px;
                }

                img {
                  width: auto;
                  vertical-align: middle;
                  border-style: none;
                  height: auto;
                  position: relative;
                  margin: 0;
                  max-height: 100%;
                  max-width: 100%;
                  @include media-breakpoint-down(sm) {
                    max-width: 200px;
                    display: inline-block;
                  }
                }
              }
            }
          }*/
        }
      }
    }
  }

  &.threexthree {
    #main {
      .inner-visual {
        iframe {
          display: block;
          position: relative;
          z-index: 120;
        }

        &:before {
          background: none;
        }
      }
    }
  }
}

.animation-images {
  position: relative;

  @include media-breakpoint-up(sm) {
    height: 440px;
  }

  .animation-ball {
    transition: transform .3s cubic-bezier(0.08, 0.61, 0.28, 0.96);
    max-width: 90%;
    position: absolute;

    max-width: 100%;

    @media (max-width: 575px) {
      transition: unset;
    }

    &.red-center {
      top: 65px;
      left: 50%;
      margin-left: -180px;
    }

    &.red-left {
      top: 90px;
      left: 210px;
    }

    &.red-right {
      top: 99px;
      right: 231px;
    }

    &.yellow {
      right: 62px;
      top: 245px;
    }

    &.purple {
      right: 207px;
      top: 50px;
    }

  }


  .animation-bg {
    max-width: 100%;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 0px;
      left: 50%;
      margin-left: -250px;
      transition: transform .24s cubic-bezier(0.08, 0.61, 0.28, 0.96);
    }
  }
}

.content-container {
  &.mediji {
    .accordion {
      margin-top: 0;

      .acc-element {
        .acc-element-header {
          .acc-element-title {
            font-family: $font-normal;
          }
        }
      }
    }
  }

  &.vplacila,
  &.druzbena {
    .accordion {
      margin-top: 0;

      .acc-element {
        .acc-element-header {
          .acc-element-title {
            h3 {
              font-family: $font-bold;
              font-size: 20px;
            }
          }
        }

        .acc-element-body {
          font-size: 14px;
        }
      }
    }
  }

  &.igralni-racun {
    .accordion {
      margin-top: 0;

      .acc-element {
        .acc-element-header {
          .acc-element-title {
            h3 {
              font-family: $font-normal;
              font-size: 14px;
              color: $primary;
            }

            &.collapsed {
              h3 {
                color: $text-primary;
              }
            }

            i {
              font-size: 16px;

              &:before {
                font-size: 17px;
              }
            }
          }
        }

        .acc-element-body {
          font-size: 14px;
        }
      }
    }
  }

  &.pricelist {
    h2 {
      color: $primary;
    }

    div.cenik {
      @media (max-width: 1024px) {
        overflow-x: auto;
      }

      table {
        overflow: auto;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.22px;
        width: 100% !important;

        a {
          color: $primary;
          margin-right: 2px;
        }

        tr {
          td {
            vertical-align: middle;
            padding: 12px 0;
            white-space: nowrap;
          }

          &:nth-child(3) {
            td {
              font-family: $font-normal;
            }
          }
        }

        thead {
          @media (max-width: 1024px) {
            width: 100% !important;
          }

          tr {
            display: flex;

            td {
              font-family: $font-normal;

              &:nth-child(4) {
                padding-left: 92px;

                @media (max-width: 1024px) {
                  padding-left: 0;
                }
              }

              &:nth-child(5) {
                padding-left: 48px;

                @media (max-width: 1024px) {
                  padding-left: 0;
                }
              }

              @media (max-width: 1024px) {
                width: 100% !important;
              }
            }
          }
        }

        tbody {
          @media (max-width: 1024px) {
            width: 100% !important;
          }

          tr {
            display: flex;

            td {
              white-space: inherit;
              font-family: $font-normal;

              &:nth-child(4) {
                padding-left: 50px;
              }

              &:nth-child(5) {
                padding-left: 48px;
              }

              @media (max-width: 992px) {
                padding-left: 12px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .youtube-video {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.table-container {
  @media (max-width: 800px) {
    overflow-x: auto;
  }
}

.kombinacijaTable,
table.table1,
table.table2 {
  @media (max-width: 800px) {
    width: 930px !important;
    overflow-x: auto;
  }
}

#kategorije-iger {
  .table-container {
    overflow-x: initial !important;

    table {
      width: auto !important;
    }
  }
}

.popover {
  border-radius: 10px;
  color: #fff;
  background: $primary;
  border: 1px solid $primary;
  font-size: 12px;
  line-height: 17px;
  top: -10px !important;
  max-width: 290px !important;

  .popover-body {
    padding: 25px;
    color: #fff;
  }

  .arrow {

    &:before,
    &:after {
      border-top-color: $primary;
    }
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: rgba(0, 149, 57, .1);
  color: $text-secondary;
}

#zgodovina {
  .history-container {
    visibility: hidden !important;

    ul.slick-dots {
      margin: 20px auto;
      width: 183px;
      height: 1px;
      background: #008533;
      display: flex;
      padding: 0 !important;
      position: relative;

      >li {
        list-style: none;
        flex: 1 1;
        height: 31px;
        position: relative;
        z-index: 2;
        top: -15px;
        cursor: pointer;

        button {
          display: none;
        }
      }

      .sliding-indicator {
        position: absolute;
        z-index: 1;
        height: 3px;
        background: #008533;
        width: 50px;
        top: -1px;
        left: 0;
        transition: left 1s;

        @media (max-width: 575px) {
          transition: unset;
        }
      }
    }

    .title {
      position: relative;
      border-bottom: 1px solid #e1e1e1;
      color: $green;
      font-size: 14px;
      padding-bottom: 5px;
      margin-bottom: 15px;

      &:after {
        content: "";
        display: block;
        top: 29px;
        left: 0;
        position: absolute;
        background: $green;
        z-index: 120;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }

    .description {
      padding-right: 30px;
    }
  }

  &.show {
    .history-container {
      visibility: visible !important;
    }
  }
}

/*
.sr-only {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}*/

@import "./header";
@import "./splide";
@import "./footer";
@import "./sitemap";
@import "./cookie-consent.scss";

@import "./games";
@import "./webgames";
@import "./tickets";
@import "./blog";

@import "./mega-dobitniki";
@import "./prodajna-mesta";
@import "./preveri-potrdilo";

@import "./druzbena";
@import "./application";
@import "./statistics";
@import "./results";
@import "./results-archive";
@import "partnerska-mreza/partnerska-mreza";

@import "srecomobil";
@import "srecazavse";
@import "preveri-potrdilo";
@import "prodaja-na-blagajnah";
@import "hip-igre";
@import "./pages/museum";

@import "./mq";

$className: black-white;
$themeColor: $theme-black;
$themeBgColor: $theme-white;
@import "./_accessibility.scss";

$className: white-black;
$themeColor: $theme-white;
$themeBgColor: $theme-black;
@import "./_accessibility.scss";

$className: black-beige;
$themeColor: $theme-black;
$themeBgColor: $theme-beige;
@import "./_accessibility.scss";

$className: yellow-blue;
$themeColor: $theme-yellow;
$themeBgColor: $theme-blue;
@import "./_accessibility.scss";

$className: green-black;
$themeColor: $theme-green;
$themeBgColor: $theme-black;
@import "./_accessibility.scss";