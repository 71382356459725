/**** APLIKACIJA ****/
.app-top {
  padding-top: 90px;
  padding-bottom: 170px;
  z-index: 100;

  @media (max-width: 768px) {
    padding-bottom: 60px;
  }

  @media (max-width: 992px) {
    max-width: 100%;
  }

  .app-sreca {
    color: #fff;
    width: 530px;
    background: #009836;
    border-radius: 16px;
    padding: 50px 40px;
    box-shadow: rgba(0, 0, 0, 0.22) 0 40px 50px 0;

    .content-edit {
      margin-bottom: 0 !important;
    }

    h2 {
      color: #fff;
      font-size: 60px !important;
      font-weight: bold !important;
      line-height: 60px;
      position: relative;
      z-index: 100;

      @media (max-width: 768px) {
        font-size: 30px !important;
        line-height: 42px;
        text-align: center;
      }
    }

    p {
      color: #fff;
      font-size: 20px !important;
      line-height: 25px;
      position: relative;
      z-index: 100;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    @media (max-width: 768px) {
      margin: 315px 20px 0;
      width: auto;
    }

    @media (max-width: 575px) {
      width: auto;
      margin: 250px 20px 0;
    }

    @media (max-width: 450px) {
      width: auto;
      margin: 130px 0 0;
    }

    @media (max-width: 1200px) {
      position: relative;
      z-index: 100;
    }

    .android-cta-mobile {
      display: none;
      text-align: left;

      .icon {
        padding: 15px 0;
      }

      a {
        font-size: 16px !important;
        line-height: 25px;
        position: relative;
        z-index: 700;
        color: $white;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      @media (max-width: 768px) {
        text-align: center;
      }

      @media (max-width: 1200px) {
        display: block;
      }
    }
  }

  .app-cta-top {
    color: #fff;
    width: 530px;
    background: #03712A;
    border-radius: 16px;
    padding: 30px 40px;
    box-shadow: rgba(0, 0, 0, 0.22) 0 40px 50px 0;

    .app-icons {
      position: relative;
      z-index: 100;

      img {
        width: 156px;
        height: auto;
        border: 1px solid $white;
        border-radius: 10px;
        margin: 0 20px 17px 0;
      }
    }

    .text {
      position: relative;
      z-index: 100;

      small {
        font-size: 13px;
        line-height: 18px;

        a {
          text-decoration: underline;
          color: $white;

          &:hover {
            text-decoration: none;
            color: $white;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .under-text {
    width: 530px;
    text-align: center;
    padding: 25px 0;
    position: relative;
    z-index: 1;

    small {
      font-size: 13px;
      line-height: 18px;
      color: $black;
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .app-background {
    position: absolute;
    top: -130px;
    right: 0;

    @media (max-width: 768px) {
      background: url('/files/application/detelja-tablet-s.png') no-repeat center;
      min-height: 0;
      width: 100%;
      height: 960px;
      z-index: -1;
      background-size: contain;
      top: -120px;
    }

    @media (max-width: 580px) {
      top: -200px;
    }

    @media (max-width: 450px) {
      top: -270px;
    }

    img {
      &.qr-loterija {
        position: absolute;
        right: 150px;
        top: 355px;
      }

      &.bg-tablet {
        display: none;

        @media (max-width: 1200px) and (min-width: 769px) {
          display: block;
        }
      }

      &:not(.bg-tablet) {
        @media (max-width: 1200px) {
          display: none;
        }
      }
    }

    @media (max-width: 1200px) and (min-width: 768px) {
      top: -200px;
    }
  }


  .app-bg-left {
    display: none;
    background: url('/files/application/detelja-over-left.png') no-repeat left -69px;
    position: absolute;
    top: 0;
    left: 0;
    width: 72px;
    height: 632px;
    z-index: 200;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .app-bg-right {
    display: none;
    background: url('/files/application/detelja-over-right.png') no-repeat right -69px;
    position: absolute;
    top: 0;
    right: 0;
    width: 168px;
    height: 632px;
    z-index: 200;

    @media (max-width: 768px) {
      display: block;
    }
  }
}

.modal-backdrop.fade.show {
  opacity: 0.8;
  background-color: $white;
}

.modal-xxl {
  max-width: 1300px;
  width: 100%;
  -webkit-box-shadow: 0px 32px 24px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 32px 24px rgba(0,0,0,0.5);
  box-shadow: 0px 32px 24px rgba(0,0,0,0.5);

  @media (max-width: 1300px) and (min-width: 768px) {
    position: absolute;
    top: 0;
    left: 1px;
  }

  .modal-content {
    border: none;
    border-radius: unset;

    button {
      text-align: right;
      padding: 10px 12px;
    }

    .modal-body {
      padding: 80px 16px 0;

      .android {
        margin-bottom: 80px;

        .img {
          text-align: center;
        }

        .left-text {
          h2 {
            margin-bottom: 15px;
            color: $green;
            font-size: 30px;
            line-height: 40px;
            font-family: $font-normal;
          }

          h3 {
            margin-bottom: 5px;
            color: $green;
            font-size: 20px;
            line-height: 40px;
            font-family: $font-bold;
          }

          p {
            margin-bottom: 0;
            font-size: 20px;
            line-height: 30px;
          }
        }

        .right-text {
          padding: 24px 0;
          position: absolute;
          right: 0;

          p {
            font-size: 17px;
            line-height: 23px;
          }

          .empty {
            height: 20px;
          }

          &:before {
            content: '';
            display: block;
            height: 1px;
            width: 117px;
            position: absolute;
            left: -146px;
            top: 40px;
            background: #d8d8d8;
          }
        }
      }

      .steps {
        .step {
          text-align: center;

          .title {
            margin-bottom: 28px;
            color: $green;
            font-size: 16px;
            font-family: $font-normal;
          }

          .text {
            color: $black;
            height: 100px;
            margin-bottom: 40px;
            font-size: 14px;
            line-height: 20px;

            a {
              color: $green;
            }
          }
        }
      }
    }
  }
}

#app-steps {
  padding-bottom: 230px;

  .app-steps {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding-bottom: 20px;

    @media (max-width: 992px) {
      display: block;
    }

    .step {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 20px;

      .title {
        float: left;
        color: $green;
        font-size: 30px;
        line-height: 40px;
      }

      .img {
        float: right;
      }

      &:nth-child(odd) {
        &:before {
          content: '';
          background: $green;
          height: 1px;
          width: 70%;
          position: absolute;
          left: 0;
          top: 50%;
          z-index: -1;
        }

        .title {
          padding: 120px 15px 100px 0;
        }
      }

      &:nth-child(even) {
        position: relative;
        top: 40%;

        .title {
          order: 2;
          padding: 120px 0 100px 21px;
        }

        .img {
          order: 1;
        }

        &:before {
          content: '';
          background: $green;
          height: 1px;
          width: 70%;
          position: absolute;
          right: 0;
          top: 50%;
          z-index: -1;
        }
      }

      @media (max-width: 992px) {
        display: flex;
        flex-direction: row;
      }

      @media (max-width: 540px) {
        display: block;
        text-align: center;

        &:nth-child(even),
        &:nth-child(odd) {
          .title {
            width: 100%;
            padding: 20px;
          }

          &:before {
            content: none;
          }
        }

        .img {
          float: none;
        }
      }
    }
  }

  @media (max-width: 540px) {
    margin-top: 30px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-bottom: 30px;
  }

  @media (max-width: 992px) {
    padding-bottom: 50px;
  }
}

#app-icons {
  margin-top: 20px;
  margin-bottom: 50px;

  .icon {
    text-align: center;

    .title {
      margin: 0 0 45px;
      color: $green;
      font-size: 20px;
      line-height: 25px;
      padding: 0 90px;

      @media (max-width: 1200px) {
        padding: 0 50px;
      }

      @media (max-width: 992px) {
        padding: 0;
      }
    }

    @media (max-width: 540px) {
      margin-bottom: 50px;
    }
  }
}

.app-download {
  color: $white;
  width: 570px;
  background: #03712A;
  border-radius: 16px;
  padding: 40px 0;
  text-align: center;
  margin: 0 auto -50px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.22) 0 40px 50px 0;
  z-index: 200;

  .app-icons {
    a {
      img {
        width: 156px;
        height: auto;
        border: 1px solid $white;
        border-radius: 10px;
        margin: 0 20px 17px 0;
      }
    }

    .ios {
      @media (max-width: 992px) {
        display: none;
      }
    }

    .android-desktop {
      @media (max-width: 992px) {
        display: none;
      }
    }

    .android-mobile {
      display: none;

      @media (max-width: 992px) {
        display: block;
        img {
          margin: 20px 0;
        }
      }
    }
  }

  .desktop-show {
    font-size: 16px;
    color: $white;
    margin-bottom: 20px;
    line-height: 25px;

    @media (max-width: 992px) {
      display: none;
    }
  }

  .mobile-show {
    display: none;
    font-size: 16px;
    line-height: 25px;

    @media (max-width: 992px) {
      display: block;
    }
  }

  small {
    &.desktop {
      a {
        color: $white;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      @media (max-width: 992px) {
        display: none;
      }
    }

    &.mobile {
      display: none;

      a {
        color: $white;
        text-decoration: underline;
        font-size: 16px;
        line-height: 25px;
      }

      @media (max-width: 992px) {
        display: block;
      }
    }
  }

  @media (max-width: 575px) {
    width: auto;
    margin: 15px 15px -50px;
  }

  @media (max-width: 768px) {
    padding: 20px 30px;
  }
}

.app-download-mobile {
  display: none;
  color: $white;
  width: 570px;
  background: #03712A;
  border-radius: 16px;
  padding: 40px 0;
  text-align: center;
  margin: 0 auto 60px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.22) 0 40px 50px 0;
  z-index: 200;

  .app-icons {
    a {
      img {
        width: 156px;
        height: auto;
        border: 1px solid $white;
        border-radius: 10px;
        margin: 0 20px 17px 0;
      }
    }

    .android-mobile {
      display: none;

      @media (max-width: 992px) {
        display: block;
        img {
          margin: 20px 0;
        }
      }
    }
  }

  .mobile-show {
    display: none;
    font-size: 16px;
    line-height: 25px;

    @media (max-width: 992px) {
      display: block;
    }
  }

  small {
    &.mobile {
      display: none;

      a {
        color: $white;
        text-decoration: underline;
        font-size: 16px;
        line-height: 25px;
      }

      @media (max-width: 992px) {
        display: block;
      }
    }
  }

  @media (max-width: 575px) {
    width: auto;
    margin: 15px 15px -50px;
  }

  @media (max-width: 768px) {
    display: block;
    padding: 20px 30px;
  }
}

#pf-app {
  background: $green;
  padding: 100px 0;
  position: relative;
  z-index: 100;

  h3 {
    font-size: 30px;
    line-height: 40px;
    color: #fff;
    font-weight: normal;
  }

  p {
    font-size: 20px;
    line-height: 27px;
    color: #fff;

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

#app-footer-bg-left {
  display: none;

  @media (min-width: 1570px) {
    display: block;
    position: absolute;
    z-index: 20;
    width: 513px;
    height: 1448px;
    bottom: 25px;
    left: 0;
  }
}

#app-footer-bg-right {
  display: none;

  @media (min-width: 1570px) {
    display: block;
    position: absolute;
    z-index: 20;
    width: 285px;
    height: 1068px;
    bottom: 74px;
    right: 0;
  }
}

#koraki-namestitve {
  display: none;

  h3 {
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 25px;
    color: $green;
    text-align: center;
    display: block;
    font-weight: 700;
  }

  h4 {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    color: $green;
    font-weight: bold;
    text-align: center;
    display: block;
  }

  @media (max-width: 1200px) {
    display: block;
  }

  #korak {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 50px;

    p {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      color: #4a4a4a;

      a {
        text-decoration: none;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: $green;
      }
    }

    img {
      margin-top: 18px;
    }
  }
}