.results-archive, .daily-results-archive {
  overflow: hidden;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;

  h2 {
    font-size: 21px;
    margin-bottom: 15px;
    font-family: $font-bold;
    color:#000;

    @include media-breakpoint-up(md) {
      font-size: 27px;
      margin-bottom: 20px;
    }
  }

  .results-archive-header {
    margin: 20px 0 30px;
  }

  .select-report-hour {
    padding-right: 20px;
    width: 120px;
    height: 40px;

    .select2-container--default .select2-selection--single .select2-selection__rendered {
      text-align: center;
      padding-right: 35px;
    }
  }

  
  .select-report-year {
    height: 40px;
    @include media-breakpoint-up(md) {
      margin-right: 0px;
    }

    input[type="date" i]::-webkit-calendar-picker-indicator {
      background: none;
    }

    select {
      cursor: pointer;
      text-align-last: center;
      text-align: center;
      border-radius: 4px;
      width: 100%;
      height: 40px;
      border: 1px solid #b6b6b6;
      outline: none;

      @include media-breakpoint-up(sm) {
        width: 280px;
      }
    }
    
    &:after {
      content:'';
      border-color: #747474 transparent transparent transparent;
      border-width: 5px 5px 0 5px;
      border-style: solid;
      display: block;
      position: absolute;
      top: 18px;
      right: 16px;
      width: 10px;
      height: 10px;
      z-index: 600;
    }
  }

  .submit-report-year {
    .btn {
      font-size: 12px;
      font-family: $font-bold;
      letter-spacing: 2px;
      width: 100%;
      height: 40px;

      @include media-breakpoint-up(sm) {
        width: 180px;
      }
    }
  }

  .daily-archive-date-submit {
    @media (max-width: 575px) {
      padding-right: 15px;
    }
  }

  /*.archive-header {
    padding: 0 8px 15px;
    margin-top: 27px;
    margin-bottom: 30px;
    font-size: 14px;
  }*/

  .archive-choose-game {
    background: #EDEDED;
    position:relative;
    min-height: 82px;
    height: auto;
    padding: 13px 0;
    margin-bottom: 25px;

    .title {
      font-family: $font-bold;
      text-transform: uppercase;
      font-size: 16px;
      margin-bottom: 0;
      margin-right: 38px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -300%;
      background: #ededed;
      display: block;
      height: 100%;
      width: 300%;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -300%;
      background: #ededed;
      display: block;
      height: 100%;
      width: 300%;
    }
    
    ul.nav-tabs {
      font-family: $font-bold;
      margin: 5px 0 0;
      border: none;
      position: relative;
      top: 2px;
      
      @media (min-width:420px) {
        margin: 0px 0 0 38px;
        font-size: 18px;
      }

      li {
        margin-right: 20px;
        
        @include media-breakpoint-up(sm) {
          margin-right: 30px;
        }

        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 15px;
          text-transform: uppercase;
          color:$text-primary;
          position: relative;
          text-decoration: none !important;
          
          @include media-breakpoint-up(md) {
            font-size: 18px;
          }
          
          &:hover {
            color:$green;
            text-decoration: none;
          }

          &:before {
            content: '';
            width: 17px;
            height: 17px;
            border: 1px solid #575757;
            background:none;
            display: inline-block;
            border-radius: 50%;
            outline: 0;
            cursor: pointer;
            margin-right: 5px;
          }

          &.active {
            color:$green;

            &:before {
              border: 1px solid $green;
              background: $green;
              display: inline-block;
              border-radius: 50%;
              outline: 0;
              cursor: pointer;
            }

            &:after {
              content: '';
              display: block;
              position: absolute;
              left: 5px;
              top: 6px;
              width: 6px;
              height: 10px;
              border: solid #fff;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              
              @include media-breakpoint-up(md) {
                top: 9px;
              }
            }
          }
        }
      }
    }
  }

  .archive-choose-year {
    padding-bottom: 40px;
    margin-bottom: 35px;
    border-bottom: 1px solid #ededed;

    .title {
      font-family: $font-bold;
      text-transform: uppercase;
      font-size: 16px;
      margin-right: 25px;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        margin-right: 38px;
      }
    }

    .archive-results-btn {
      min-width: 200px;
      width: auto;
      @include media-breakpoint-only(xs) {
        margin-top: 20px;
        width: 100%;
      }

      @include media-breakpoint-up(sm) {
        margin-left: 24px;
      }
    }

    select#game-year {
      max-width: 90px;
    }
  }

  div.numbers-title {
    font-family: $font-bold;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 25px;

    @include media-breakpoint-up(sm) {
      width: 180px;
    }

    @include media-breakpoint-up(md) {
      font-size: 18px;
      margin-bottom: 45px;
    }
  }

  .archive-element.new { 
    margin-bottom: 10px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 19px;
    }

    .archive-round-data {
      margin-bottom: 10px;
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: 425px;

        &.game-ejp {
          width: 373px;
        }

        &.game-viking {
          width: 372px;
        }

        &.game-threexthree {
          width: 353px;
        }
        
        &.game-tikitaka {
          width: 100%;
        }
      }
    }

    .date {
      font-family: Arial, sans-serif;
      font-size: 17px;
      line-height: 30px;
      font-weight: 700;
      color:#575757;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 27px;
      }
    }
    
    .round {
      color:#575757;
      font-size: 12px;
      margin-right: 15px;
    }
    
    .report {
      color:#575757;
      font-size: 12px;
      z-index: 10;
    }

    .location {
      color:$green;
      font-size: 12px;
      @include media-breakpoint-up(md) {
        margin-right: 18px;
      }
    }
    .prize {
      color:$green;
      font-family: $font-bold;
      font-size: 20px;
      line-height: 24px;

      @include media-breakpoint-up(md) {
        margin-right: 18px;
      }
    }

    .numbers {
      padding-bottom: 19px;
      border-bottom: 1px solid #ededed;
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 425px;

        &.game-ejp {
          width: 373px;
        }

        &.viking {
          width: 372px;
        }
        
        &.threexthree {
          width: 353px;
        }
      }
    }

    .winnings {
      width: 100%;
      padding: 10px 0px;
      margin-left: 0;
      
      @include media-breakpoint-up(md) {
        width: auto;
        padding: 0 0 19px;
        margin-left: 40px;
        min-width: 215px;

        &.tikitaka,
        &.threexthree {
          margin-left: 0;
        }
      }
      .text {
        color:$green;
        font-size: 16px;
        font-family: Arial, sans-serif;
      }
      .icon {
        transform: rotate(45deg);
        border: 2px solid rgba($green,0.3);
        border-radius: 50%;
        text-align: center;
        position: relative;
        width: 100%;
        max-width: 18px;
        height: 18px;
        line-height: 18px;

        &:before {
          content: " ";
          display: inline-block;
          transform: rotate(-90deg);
          height: 5px;
          width: 9px;
          border-bottom: 2px solid #009539;
          border-left: 2px solid #009539;
          position: absolute;
          top: 4px;
          left: 2px;
        }
      }
    }
  }

  .archive-row-data {
    position: relative;

    &.tikitaka {
      .winnings-container {
        border-bottom: 1px solid #ededed;
      }
    }

    .archive-element:not(.new) {
      padding-bottom: 20px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -300%;
        background: #ededed;
        display: block;
        height: 72px;
        width: 300%;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -300%;
        background: #ededed;
        display: block;
        height: 72px;
        width: 300%;
      }

      @include media-breakpoint-up(sm) {
        &:nth-child(even):before {
          content: "";
          position: absolute;
          top: 0;
          left: -300%;
          background: none;
          display: block;
          height: 72px;
          width: 300%;
        }

        &:nth-child(odd):after {
          content: "";
          position: absolute;
          top: 0;
          right: -300%;
          background: none;
          display: block;
          height: 72px;
          width: 300%;
        }
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 50px;

        &:nth-child(odd):before,
        &:nth-child(even):after {
          background: none;
        }

        &:nth-child(3n + 1):before {
          content: "";
          position: absolute;
          top: 0;
          left: -300%;
          background: #ededed;
          display: block;
          height: 72px;
          width: 300%;
        }

        &:nth-child(3n + 3):after {
          content: "";
          position: absolute;
          top: 0;
          right: -300%;
          background: #ededed;
          display: block;
          height: 72px;
          width: 300%;
        }
      }

      .more-details-header {
        font-size: 13px;
      }

      .details-row-data {
        font-size: 10px;
      }

      .letter {
        color: $text-secondary;
        font-size: 15px;
        font-family: $font-bold;
      }

      .game-name.threexthree {
        opacity: 0.7;
      }
    }

    .archive-round-data:not(.new) {
      position: relative;
      height: 72px;
      font-size: 14px;
      background-color: #ededed;

      .report {
        position: relative;
        z-index: 9;

        a {
          color: $text-secondary;
        }
      }
    }

    .archive-round-content {
      padding: 0px 0 0;

      .archive-round-content-title {
        font-size: 14px;
        color: #000;
        font-family: $font-bold;
      }

      .archive-round-content-title-normal {
        font-size: 14px;
        color: $text-primary;
      }
    }

    .archive-result-more-details {
      @media (max-width: 500px) {
        width: 100%;
        overflow-x: scroll;
  
        .inner {
          width: 550px;
        }
      }

      .more-details-header {
        font-size: 10px;

        @include media-breakpoint-up(md) {
          font-size: 15px;
        }
      }
    }

    .numbers {
      margin: 13px 0;
    }

    .round-title {
      background: rgba(182, 182, 182, 0.5);
      padding: 10px 15px;
      margin-bottom: 10px;
      font-size: 13px;

      @include media-breakpoint-up(lg) {
        background: none;
        padding: 0;
        margin: 0;
        font-size: 16px;
      }
    }

    .game-title {
      font-family: $font-bold;
      color: $text-secondary;

      @include media-breakpoint-up(lg) {
        color: #000;
      }
    }

    .numbers {
      .number {
        @extend .number;
        @include media-breakpoint-up(md) {
          font-size: 24px;
          width: 42px;
          height: 42px;
          line-height: 41px;
          margin-right: 10px;
        }

        &.additional {
          &:before {
            top: -2px;
          }
        }
      }

      &.ejp {
        .number {
          &.bg-prim {
            @extend .bg-ejp;
            color: $ejp-text;
          }

          &.bg-second {
            @extend .bg-ejp-text;
            color: $ejp;
          }
        }
      }

      &.game-lotoplus {
        .number {
          &.bg-prim {
            @extend .bg-green;
          }

          &.bg-second {
            @extend .bg-loto;
          }
        }
      }

      &.tikitaka {
        .number {
          &.bg-prim {
            @extend .bg-tikitaka;
          }
        }
      }

      &.threexthree {
        .number {
          &.bg-prim {
            @extend .bg-threexthree-results;
          }
        }
      }

      &.viking {
        .number {
          &.bg-prim {
            @extend .bg-vikinglotto;
            color: #fff;
          }

          &.bg-second {
            @extend .bg-vikinglotto3;
            color: $viking;
          }
        }
      }
    }

    .archive-header-bottom {
      border-bottom: 1px solid #e1e1e1;
      font-size: 13px;
    }

    &.tikitaka {
      .archive-element {
        padding-bottom: 0;

        @include media-breakpoint-down(xs) {
          &:nth-child(odd) {
            &:before,
            &:after {
              background: #ededed;
            }
          }

          &:nth-child(even) {
            background: #f9f9f9;

            &:before,
            &:after {
              background: #f9f9f9;
            }

            .archive-round-data {
              background: none;
            }
          }
        }

        @include media-breakpoint-between(sm, md) {
          &:nth-child(4n+1) {
            &:before {
              background: #ededed;
            }
          }

          &:nth-child(4n+2) {
            &:after {
              background: #ededed;
            }
          }

          &:nth-child(4n+3) {
            .archive-round-data {
              background-color: #f9f9f9;
            }

            &:before {
              background: #f9f9f9;
            }
          }

          &:nth-child(4n+4) {
            .archive-round-data {
              background-color: #f9f9f9;
            }

            &:after {
              background: #f9f9f9;
            }
          }
        }

        @include media-breakpoint-up(lg) {
          &:nth-child(6n+1) {
            &:before {
              background: #ededed;
            }
          }

          &:nth-child(6n+3) {
            &:after {
              background: #ededed;
            }
          }

          &:nth-child(6n+4) {
            .archive-round-data {
              background-color: #f9f9f9;
            }

            &:before {
              background: #f9f9f9;
            }
          }

          &:nth-child(6n+6) {
            .archive-round-data {
              background-color: #f9f9f9;
            }

            &:after {
              background: #f9f9f9;
            }
          }

          &:nth-child(6n+5) {
            .archive-round-data {
              background-color: #f9f9f9;
            }
          }
        }
      }
    }
  }

  .daily-archive-datepicker {
    background-color: #fff;
    border: 1px solid #b6b6b6;
    border-radius: 4px;
    cursor: pointer;

    appearance: none;
    -webkit-appearance: none;
  }
}

.statistics-dynamic-loader, .archive-dynamic-loader, .daily-archive-dynamic-loader, .dynamic-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
  display: flex;
  top: 0%;
  z-index: 200;
  background: rgba(255, 255, 255, 0.65);
  align-items: center;
  justify-content: center;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 30px auto;
    border: solid 6px rgba(67, 160, 71, 0.1);
    border-top-color: $primary;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
}

.archive-dynamic-loader {
  align-items: flex-start;
  div {
    position:absolute;
    top: 150px;
  } 
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.ejp-results,
.viking-results {
  .results-archive {
    .archive-row-data {
      .archive-element {
        &:before, &:after {
          background: #ededed;
        }

        @include media-breakpoint-up(md) {
          &:nth-child(odd) {
            &:before {
              background: #ededed;
            }
            
            &:after {
              background: none;
            }
          }
          
          &:nth-child(even) {
            &:before {
              background: none;
            }
            
            &:after {
              background: #ededed;
            }
          }
        }
      }
    }
  }
}