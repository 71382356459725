.winnings-year {
  width: 120px;
  height: 40px;
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  margin-bottom: 20px;
}

.annual-winnings {
  margin-bottom: 20px;

  @include media-breakpoint-up(lg) {
    
    z-index: 100;
    margin:0;
  }

  span {
    font-size: 20px;
  }
}

ul.map-legend {
  
  @include media-breakpoint-up(lg) {
    list-style: none;
    margin: 20px 0 0 0;
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 30px;
    height: 45px;
  }

  li {
    display: flex;
    align-items:center;
    font-size: 18px;
    .dot {
      background: $loto;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
      
      &.superloto,
      &.ticket {
        background: #007a2f;
      }

      &.ejp {
        background: $ejp;
      }
      
      &.vikinglotto {
        background: $viking;
      }
      
      &.joker {
        background: #742c56;
      }

      &.threexthree {
        background: #de0283;
      }

      &.tikitaka {
        background: #1475bb;
      }
    }
  }
}

#mega-dobitniki-map {
  width: 891px;
  margin: 0px auto 70px;
  position: relative;

  .map-row {
    height: 11px;
  }
  .map-col {
    width: 11px;
    height: 11px;
    float: left;
    position: relative;
  }
  .map-dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #cdcdcd;
    display: block;
    position: absolute;
    left: 2px;
    top: 2px;

    &.map-dot-lg {
      width: 9px;
      height: 9px;
      left: 1px;
      top: 1px;
    }

    &.map-dot-active {
      width: 11px;
      height: 11px;
      left: 0;
      top: 0;
      cursor: pointer;
      &:hover {
        width: 15px;
        height: 15px;
        top: -2px;
        left: -2px;
        z-index: 1;
      }
    }

    &.map-dot-ejp {
      background: $ejp;
    }
    
    &.map-dot-joker {
      background: #742C56;
    }

    &.map-dot-loto {
      background: $loto;
    }
    
    &.map-dot-novoletniloto {
      background: $novoletni-loto;
    }

    &.map-dot-superloto,
    &.map-dot-ticket {
      background: $primary;
    }

    &.map-dot-vikinglotto {
      background: $viking;
    }
    
    &.map-dot-tikitaka {
      background: $tikitaka;
    }
    
    &.map-dot-threexthree {
      background: $threexthree;
    }
  }
}
.map-dot-tooltip {
  .tooltip-inner {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.34);
    border-radius: 11px;
    background: #fff;
    color: $text-primary;
    font-size: 14px;
    width: 215px;
    max-width: 215px;
    height: 170px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .logo {
      margin-bottom: 18px;
    }

    .yield-type {
      font-family: $font-bold;
      margin-bottom: 8px;
    }

    .yield {
      margin-bottom: 10px;
      font-family: $font-bold;
      color: $loto;
      display: flex;
      align-items:center;
      justify-content: center;

      .icon-trophy {
        margin-right: 10px;
        display: block;
        width: 19px;
        height: 22px;
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%23d0021b' d='M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
      }
    }
  }

  &.ejp {
    .tooltip-inner {
      .yield {
        color: $ejp-brown;
        .icon-trophy {
          background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%23924a21' d='M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
        }
      }
    }
  }

  &.vikinglotto {
    .tooltip-inner {
      .yield {
        color: $viking;
        .icon-trophy {
          background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%230000ff' d='M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
        }
      }
    }
  }

  &.superloto,
  &.ticket {
    .tooltip-inner {
      .yield {
        color: $primary;
        .icon-trophy {
          background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%23008533' d='M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
        }
      }
    }
  }

  &.novoletniloto {
    .tooltip-inner {
      .yield {
        color: $novoletni-loto;
        .icon-trophy {
          background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%23a1663d' d='M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
        }
      }
    }
  }

  &.tikitaka {
    .tooltip-inner {
      .yield {
        color: $tikitaka; 
        .icon-trophy {
          background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%231475bb' d='M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
        }
      }
    }
  }
  
  &.threexthree {
    .tooltip-inner {
      .yield {
        color: $threexthree;
        .icon-trophy {
          background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%23c4007a' d='M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
        }
      }
    }
  }
  
  &.joker {
    .tooltip-inner {
      .yield {
        color: #742C56;
        .icon-trophy {
          background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%23742C56' d='M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
        }
      }
    }
  }
}

.mega-dobitniki {
  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  .accordion {
    margin-top: 0px;
  }

  .top-header-title-container {

    .top-header-title {
      font-family: $font-bold;
      font-size: 16px;
      color:$text-primary;
      padding: 0 0px 10px 0px;
      margin-bottom: 5px;
      border-bottom: 1px solid #e1e1e1;

      @include media-breakpoint-up(md) {
        padding: 0 60px 10px 14px;
        font-size: 22px;
      }
    }
  }

  .accordion .acc-element {
    .acc-element-body {
      font-size: 16px;
      line-height: 27px;
      color:$text-secondary;
      padding: 5px 0px 20px 0px;

      @include media-breakpoint-up(md) {
        padding: 5px 60px 20px 14px;
        font-size: 20px;
      }

      .mega-dobitniki-game-yield-element {
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    
    .acc-element-header .acc-element-title {
      padding: 11px 0px 12px 0px;

      @include media-breakpoint-up(md) {
        padding: 11px 20px 12px 14px;
      }

      h2 {
        font-size: 18px;
        line-height: 27px;
        color:$green;
        font-family: $font-bold;
        margin-bottom: 0;

         @include media-breakpoint-up(md) {
          font-size: 20px;
         }
      }
    
      &.collapsed {
        h3 {
          color:$green;
          font-family: $font-bold;
        }
      }
    }
  }
}