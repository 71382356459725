.dynamic-loader-container {
    height: 450px;
    position: relative;
}

.blog-list {
  display: grid;
  grid-gap: 0px 12px;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: 100%;
  padding: 0px 20px;
  
  @include media-breakpoint-up(sm) {
    grid-template-columns: 50% 50%;
  }
  
  @include media-breakpoint-up(md) {
    padding: 0 12px;
  }
  
  @include media-breakpoint-up(lg) {
    grid-template-columns: auto auto auto;
  }
  
  @include media-breakpoint-up(xl) {
    grid-template-columns: 360px 267px 453px;
    padding: 0px;
  }

  @include media-breakpoint-up(xl) {
    &.container-xl {
      max-width: 1104px;
    }
  }

  .blog-element {
    grid-column-end: span 1;
    grid-row-end: span 1;
    font-size: 14px;
    line-height: 19px;
    color:#000;
    margin-bottom: 55px;
    flex-direction: column;
    display: none;

    p {
      font-size: 14px;
      line-height: 19px;
      color:#000;
    }

    .blog-image {
      margin-bottom: 15px;
      position:relative;

      .blog-filter {
        position:absolute;
        top: 10px;
        left: 10px;
        padding: 0 12px;
        height: 26px;
        line-height: 26px;
        background: #F9F9F9;
        color:$green;
        font-size: 13px;
        font-family: $font-bold;
        letter-spacing: 0.03em;
        border-radius: 2px;
      }
    }
    
    .blog-data {
      .blog-title {
        font-family: $font-bold;
        font-size: 18px;
        line-height: 21px;
        color:#000;
        margin-bottom: 8px;

        @include media-breakpoint-up(md) {
          font-size: 24px;
          line-height: 29px;
        }
        
      }

      .blog-date {
        color:$text-primary;
        margin-bottom: 20px;
      }

      .btn {
        width: 141px;
        min-width: 0;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
      }
    }

    &.blog-element-1 {
      grid-template-columns: 100%;
      display: grid;
      margin-bottom: 100px;
      height: 564px;
      grid-column-end: span 1;
      grid-row-end: span 1;
      grid-template-rows: 1fr;
      margin-left: -20px;
      margin-right: -20px;
      
      @include media-breakpoint-up(sm) {
        grid-column-end: span 2;
      }
      
      @include media-breakpoint-up(md) {
        height: auto;
        grid-template-columns: auto 520px;
        flex-direction: row;
        margin-left: 0px;
        margin-right: 0px;
      }
      
      @include media-breakpoint-up(lg) {
        grid-template-columns: auto 650px;
        grid-column-end: span 3;
      }
      
      @include media-breakpoint-up(xl) {
        margin-right: -40px;
        grid-template-columns: auto 770px;
        margin-bottom: 144px;
      }

      .blog-image {
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
          order: 2;
        }
        
        @include media-breakpoint-down(sm) {
          &:before {
            content:'';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        
        img {
          @include media-breakpoint-down(sm) {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .blog-data {
        order: 1;

        .inner {
          position: absolute;
          top: 50%;
          left: 0px;
          padding-left: 20px;
          padding-right:20px;
          transform: translate(0, -50%);
          width: 100%;
          
          @include media-breakpoint-up(md) {
            width: 40%;
            padding-left: 0px;
            padding-right: 0px;
          }
        }

        .blog-title {
          font-size: 30px;
          line-height: 32px;
          letter-spacing: 0.78px;
          margin-bottom: 26px;
          color:#fff;
          
          @include media-breakpoint-up(md) {
            color:$green;
          }

          @include media-breakpoint-up(lg) {
            font-size: 52px;
            line-height: 107.8%;
          }
        }

        .blog-date {
          color:#fff;
          
          @include media-breakpoint-up(md) {
            color:$green;
          }
        }

        .blog-preview {
          color:#fff;
          font-size: 16px;
          line-height: 20px;
          
          @include media-breakpoint-up(md) {
            color:$green;
            width: 300px;
          }
        }
    
        .btn {
          width: 158px;
          height: 50px;
          line-height: 48px;
        }
      }
    }

    &:nth-child(3n+3) {
      @include media-breakpoint-up(lg) {
        margin-top: 92px;
      }
    }

    &:nth-child(9n+2),
    &:nth-child(9n+7),
    &:nth-child(9n+8) {
      .blog-data {
        @include media-breakpoint-up(lg) {
          padding-right: 70px;
        }
      }
    }

    &:nth-child(9n+4),
    &:nth-child(9n+10) {
      .blog-data {
        @include media-breakpoint-up(lg) {
          padding-right: 80px;
          padding-left: 80px;
        }
      }
    }

    .sly-edit {
      position:absolute;
    }
  }
  
  .blog-element-exposed {
    grid-column-end: span 3;
    grid-row-end: span 1;
    background: aquamarine;
  }
}

.single-blog {
  padding-bottom: 90px;

  .content-edit {
    margin-bottom: 40px;
    margin-top: 40px;

    .blog-img-left {
      @include media-breakpoint-up(md) {
        padding-right: 55px;
      }
    }
    
    .blog-img-right {
      @include media-breakpoint-up(md) {
        padding-left: 55px;
      }
    }
  }

  .blog-top {
    margin-bottom: 55px;
    padding-top: 30px;
    
    @include media-breakpoint-up(md) {
      padding-top: 0px;
      height: 520px;
      margin-bottom: 155px;
    }

    .single-blog-title {
      padding: 0 15px;
      @include media-breakpoint-up(md) {
        padding-left: 0;
      }
      .title,
      .date,
      .subtitle,
      .subtitle * {
        color:#fff;
      }

      .date {
        font-size: 14px;
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }

      .subtitle {
        font-size: 16px;

        @include media-breakpoint-up(md) {
          font-size: 20px;
        }

        p {
          line-height: 24px;
        }
      }

      h1 {
        font-family: $font-bold;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 11px;
        
        @include media-breakpoint-up(md) {
          font-family: $font-semibold;
          margin-bottom: 30px;
          font-size: 50px;
          line-height: 60px;
        }
      }
    }

    .single-blog-content {
      z-index: 100;
    }

    .single-blog-image {
      @include media-breakpoint-down(sm) {
        padding-left: 15px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
      
      @include media-breakpoint-up(md) {
        position:absolute;
        top: 0;
        right: 0;
      }

      @include media-breakpoint-up(xl) {
        right: -77px;
      }
    }
  }

  .description {
    color:#000;
    font-size: 20px;
    line-height: 31px;

    h2 {
      color:$primary;
      font-size: 25px;
      line-height: 40px;
    }

    h3 {
      color:$primary;
      font-size: 17px;
      line-height: 25px;
      font-family: $font-bold;
    }

    ul {
      margin: 0;
      padding: 0 0 0 20px;

      li::marker {
        font-size: 14px;
      }
    }

    .blog-quote {
      padding: 55px 20px;
      font-size: 24px;
      line-height: 29px;
      font-family: $font-semibold;
      margin-bottom: 50px;
      
      @include media-breakpoint-up(md) {
        padding: 55px 0;
      }
      
      @include media-breakpoint-up(lg) {
        font-size: 35px;
        line-height: 40px;
      }
      
      .inner {
        max-width: 570px;
        width: 100%;
        padding: 0 12spx;

        &:before {
          content:'“';
          color:rgba(#fff, 0.2);
          position:absolute;
          font-size: 320px;
          top: 88px;
          right: 10px;

          @include media-breakpoint-up(md) {
            font-size: 350px;
            top: 115px;
            right: 50px;
          }
        }
      }

      &.bg-srecke {
        color:#DEDE20;
        .inner {  
          &:before {
            color:rgba(#FFFFFB, 0.43);
          }
        }
      }

      &.bg-loto,
      &.bg-green {
        color:#fff;
      }

      &.bg-vikinglotto {
        color:#91FFC3;

        .inner {  
          &:before {
            color:rgba(#91FFC3, .43);
          }
        }
      }
    }

    .youtube-iframe-container {
      float: none;
      clear: both;
      width: 100%;
      position:
      relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;
      
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .blog-gallery {
    margin-bottom: 50px;
    .slick-list {
      padding: 0 !important;

      .slick-track {
        margin-left: 0;
      }
    }
    .gallery-arrow {
      position: absolute;
      bottom: -22px;
      left: 38px;
      width: 45px;
      height: 45px;
      background:$primary;
      z-index: 100;
      display: flex;
      align-items:Center;
      justify-content: center;
      cursor: pointer;
      
      @include media-breakpoint-up(lg) {
        left: calc((100% - 940px) / 2);
      }

      &:before {
        content:'';
        border-top: 2px solid #fff;
        border-left: 2px solid #fff;
        transform: rotate(-45deg);
        width: 12px;
        height: 12px;
        display: block;
      }

      &.arrow-next {
        left: 83px;
        
        @include media-breakpoint-up(lg) {
          left: calc((100% - 851px) / 2);
        }

        &:before {
          border-left: none;
          border-right: 2px solid #fff;
          transform: rotate(45deg);
        }
      }
    }
  }

  .recommended-blog {
    > h2 {
      font-size: 35px;
      line-height: 42px;
      margin-bottom: 42px;
    }

    .recommended-blog-list {
      .slick-track {
        margin-left: 0;
      }
      .recommended-blog-item {
        .content {
          padding-left: 30px;
          padding-right: 20px;

          h3.title {
            color: #000;
            font-size: 27px;
            line-height: 32px;
            margin-bottom: 27px;
            font-family: $font-bold;
          }

          .preview {
            color: $text-primary;
            font-size: 16px;
            line-height: 19px;
          }

          .content-edit {
            margin-bottom: 0px;
            margin-top: 0px;
          }
        }
      }

      .slick-dots {
        display: flex;
        justify-content:center;
        list-style: none;
        margin-top: 35px;

        li {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          margin: 0 3px;
          background: none;
          border: 2px solid $text-primary;

          &.slick-active {
            background: $text-primary;
          }

          button {
            display: none;
          }
        }
      }
    }
  }

  .game-tile-inner {
    display: flex !important;
    max-width: 1000px;
    margin: 100px auto;
    padding-left: 12px;
    padding-right: 12px;

    @include media-breakpoint-up(md) {
      max-width: 720px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 960px;
    }
    
    @include media-breakpoint-up(xl) {
      max-width: 1000px;
    }
  }

  &.ejp {
    .blog-top {
      .single-blog-title {
        .title,
        .date,
        .subtitle,
        .subtitle * {
          color:$ejp-text;
        }
      }
    }

    .description {
      color:#2b2b2b;
      h2 {
        color:$ejp-text;
      }
    }
  }

  &.loto {
    .description {
      color:#2b2b2b;
      h2,
      h3 {
        color:$loto;
      }
    }
  }

  &.vikinglotto {
    .bg-vikinglotto {
      background: #000078;
    }
    .description {
      color:#2b2b2b;
    }
  }
  
  &.srecke {
    .blog-top.bg-srecke {
      background:#DEDE20;
      .single-blog-title {
        .title,
        .date {
          color:$green;
        }

        .subtitle,
        .subtitle * {
          color:#000;
        }
      }
    }

    .description {
      color:#2b2b2b;
    }
  }

  .left-img {
    position: absolute;
    left: -200px;
    width: 360px;
    height: 360px;
    z-index: -1;
    top: 65%;
    
    @include media-breakpoint-up(lg) {
      top: 40%;
    }
  }
  
  .left-img-second {
    position: absolute;
    left: -180px;
    top: 100%;
    width: 360px;
    height: 360px;
    transform: rotate(70deg);
    z-index: -1;
  }
  
  .right-img {
    position: absolute;
    right: -135px;
    top: 96%;
    width: 266px;
    height: 265px;
    z-index: -1;
    
    @include media-breakpoint-up(lg) {
      top: 84%;
    }
  }  
}

#main {
  .blog-archive-container {
    padding-left: 33px;
    padding-right: 33px;

    h1 {
      color:#000;
      margin: 0;
    }

    .blog-archive-elements {
      @include media-breakpoint-up(xl) {
        margin-left: -18px;
        margin-right: -18px;
      }

      .blog-archive-element {
        border-radius: 5px;
        margin-bottom: 30px;
        
        @include media-breakpoint-up(xl) {
          padding-left: 18px;
          padding-right: 18px;
        }
        
        .inner {
          background: #fff;
          border-radius: 5px;
          filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.16));
          
          .blog-image {
            position:relative;
            img {
              border-radius: 5px 5px 0 0;
            }

            .blog-filter,
            .blog-date {
              position:absolute;
              top: 10px;
              left: 12px;
              border-radius: 4px;
              background:#fff;
              padding: 2px 10px;
              font-size: 13px;
              font-family: $font-normal;
              color:#000;
              
              @include media-breakpoint-up(md) {
                font-size: 16px;
              }
            }

            .blog-date {
              left: auto;
              right: 10px;
            }
          }

          .blog-data {
            padding: 24px 38px;
            
            h2 {
              font-size: 18px;
              line-height: 22px;
              color:#000;
              margin-bottom: 10px;
            }

            .blog-preview {
              .content-edit {
                margin: 0;

                > .col-12 {
                  padding: 0;
                }
              }
            }
          }

          .url {
            position:absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .pagination {
      .pagination-page {
        font-size: 16px;
        color:#000;
        line-height: 19px;
        letter-spacing: 0.2em;
        font-family: $font-normal;
      }
      
      .pagination-active {
        color:$primary;
        font-family: $font-bold;
      }
    }
  }
}

.sub-navigation-container-blog {
  background:#F0F0F0;
  text-align:center;
  padding: 30px 0;
  margin-bottom: 55px;

  .sub-navi-blog {
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;

    li {
      margin: 0 25px;
      position:relative;

      a {
        color:#000;
        font-size: 17px;
        line-height: 20px;
        font-family: $font-bold;
        text-transform: uppercase;
      }

      ul.level-2 {
        display: none;
        text-align:left;
        background: $green;
        list-style: none;
        padding: 10px 15px;
        position: absolute;
        left: 0;
        top: 30px;
        z-index: 9;
        margin-top: -1px;

        li {
          padding: 2px 0;
          margin: 0;
          a {
            color:#fff;
            font-size: 14px;
          }
        }
      }

      &:hover {
        ul.level-2 {
          display: block;
        }
      }
    }
  }
}

.blog-list {
  .sub-navigation-container-blog {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 564px;
    left: 0px;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      top: 319px;
    }

    @include media-breakpoint-up(lg) {
      top: 398px;
      width: 650px;
      right: 12px;
      left: auto;
    }
    
    @include media-breakpoint-up(xl) {
      top: 472px;
      right: -40px;
      width: 770px;
    }
    
    > .inner {
      @include media-breakpoint-down(sm) {
        overflow: auto;
        white-space: nowrap;
        position:relative;
      }
    }

    .sub-navi-blog {
      justify-content: flex-start;
      padding: 0;
      display: flex;
      width: 100%;
      @include media-breakpoint-up(sm) {
        justify-content:center;
      }

      li {
        margin: 0 20px 0 0;
        
        @include media-breakpoint-up(md) {
          margin: 0;
        }
  
        a {
          color:$text-primary;
          font-size: 16px;
          line-height: 20px;
          font-family: $font-bold;
          text-transform: uppercase;
          
          &.active {
            color: #000;            
          }
        }
      }

      &.sub-navi-blog-primary {
        li {
          margin: 0 15px;
        }
      }
      
      &.sub-navi-blog-secondary {
        display: none;
        justify-content:flex-start;

        .sub-navi-blog-back {
          background: $primary;
          width: 59px;
          height: 100%;
          display: flex;
          align-items:center;
          justify-content: center;

          @include media-breakpoint-up(lg) {
            padding: 0 28px;
            width: auto;
          }

          .icon-back {
            display: block;
            width: 14px;
            height: 17px;
            background: url("data:image/svg+xml;charset=UTF-8, %3csvg width='14' height='17' viewBox='0 0 14 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 10.2321C-0.333332 9.46225 -0.333334 7.53775 1 6.76795L10.75 1.13879C12.0833 0.368986 13.75 1.33124 13.75 2.87084L13.75 14.1292C13.75 15.6688 12.0833 16.631 10.75 15.8612L1 10.2321Z' fill='%23F9F9F9'/%3e%3c/svg%3e") no-repeat center center / contain;
          }

          .text-back {
            color:#fff;
            margin-left: 16px;
          }
        }

        li {
          margin-right: 18px;
          
          @include media-breakpoint-up(md) {
            margin-right: 40px;
          }
        }
      }
    }

    scrollbar-color: $primary #fff !important;
    scrollbar-width: thin;

    -moz-appearance: none !important;

    ::-webkit-scrollbar {
      height: 3px;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: $primary;
    }

    -ms-scrollbar-highlight-color: #fff;
    scrollbar-highlight-color: #fff;
    -ms-scrollbar-face-color: $primary;
    scrollbar-face-color: $primary;
    -ms-scrollbar-arrow-color: transparent;
    scrollbar-arrow-color: transparent;
  }
}