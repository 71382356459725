.weekly-count {
  .counter {
    font-family: $font-bold;
    font-size: 27px;
    line-height: 27px;
  }
}

section.results {
  margin-bottom: 50px;
  h1 {
    color:#000;
    margin-bottom: 30px;
  }

  .results-date {
    .title {
      font-size: 27px;
      color:#000;
      font-family: $font-bold;
    }
  }

  .results-sklad {
    font-size: 14px;
  }

  .results-report {
    font-size: 14px;

    i {
      width: 12px;
      height: 16px;
      margin-right: 7px;
    }
  }

  .numbers-title {
    font-size: 18px;

    &.small {
      font-size: 14px;
    }

    .game-name {
      color:#000;
    }
  }

  .winnings-title {
    font-size: 20px;
    font-family: $font-normal;
    color: $text-primary;

    span {
      color: #000;
    }
  }

  .result {
    //border-bottom: 1px solid #e1e1e1;
    margin-bottom: 20px;

    .numbers {
      border: 1px solid #e5e9ed;
      border-radius: 18px;
      padding: 5px 0 5px 5px;

      @include media-breakpoint-up(md) {
        border: none;
        border-radius: 0px;
        padding: 0;
      }

      .number {
        @include media-breakpoint-up(md) {
          width: 38px;
          height: 38px;
          line-height: 38px;
          font-size: 22px;
        }

        
      }
    }

    .icon {
      border: 2px solid rgba(87,87,87,.5);
      border-radius: 50%;
      text-align: center;
      position: relative;
      width: 100%;
      max-width: 18px;
      height: 18px;
      line-height: 18px;

      i {
        font-size: 11px;
        color: #009539;
      }
    }

    .winning {
      .icon {
        border: 2px solid rgba(0, 149, 57, 0.3);
        transform: rotate(45deg);

        &:before {
          content: " ";
          display: inline-block;
          transform: rotate(-90deg);
          height: 5px;
          width: 9px;
          border-bottom: 2px solid #009539;
          border-left: 2px solid #009539;
          position: absolute;
          top: 4px;
          left: 2px;
        }
      }

      .text {
        color: $green;
      }
    }

    .nowinning {
      color: #000;

      .icon {
        border: 2px solid rgba(155, 155, 155, 0.5);
        transform: rotate(0);

        i {
          color: transparent;
        }

        &:before,
        &:after {
          position: absolute;
          top: 3px;
          left: 6px;
          content: " ";
          height: 8px;
          width: 2px;
          background-color: #000;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    .sklad {
      font-size: 20px;

      @include media-breakpoint-up(md) {
        font-size: 27px;
      }
    }

    .result-more-details {
      .more-details-header {
        padding: 13px 0;
      }

      .details-row-data {
        border-top: 1px solid #e1e1e1;
        padding: 13px 0px;

        &:last-child {
          padding-bottom: 0;
        }

        @media (max-width: 425px) {
          font-size: 12px;
        }
      }
    }

    &.game-lotoplus {
      .numbers {
        .number {
          &.bg-prim {
            @extend .bg-green;
          }

          &.bg-second {
            @extend .bg-loto;
          }
        }
      }
    }

    &.game-ejp {
      border: none;

      .numbers {
        .number {
          &.bg-prim {
            @extend .bg-ejp;
            color: $ejp-text;
          }

          &.bg-second {
            @extend .bg-ejp-text;
            color: $ejp;
          }
        }
      }
    }

    &.game-tikitaka {
      border: none;

      .numbers {
        .number {
          &.bg-prim {
            background: #ffcb16;
            color: #000;
          }
        }

        &.last-hour {
          .number {
            width: 26px;
            height: 26px;
            line-height: 27px;
            font-size: 14px;
            margin-right: 4px;
          }
        }
      }

      .last-hour-element {
        margin-top: 20px;

        @include media-breakpoint-up(md) {
          margin-top: 35px;
        }
      }

      .tikitaka-buttons {
        width: 100%;
        padding-left: 15px;

        @include media-breakpoint-up(sm) {
          width: 340px;
        }
      }
    }

    &.game-threexthree {
      border: none;

      .numbers {
        .number {
          &.bg-prim {
            @extend .bg-threexthree-results;
            color: #fff;
          }

          width: 28px;
          height: 28px;
          line-height: 28px;
          font-size: 15px;
        }

        .letter {
          color: $text-primary;
          font-size: 15px;
          font-family: $font-bold;
        }
      }

      .yield {
        &.threexthree-results {
          color: $threexthree-results;
        }
      }
    }

    &.game-viking {
      border: none;

      .numbers {
        .number {
          &.bg-prim {
            @extend .bg-vikinglotto;
            color: #fff;
          }

          &.bg-second {
            @extend .bg-vikinglotto3;
            color: $viking;
          }
        }
      }
    }
  }

  .result-show-more {
    cursor: pointer;
    font-size: 14px;

    &:after {
      content: "";
      display: inline-block;
      position: relative;
      top: -3px;
      margin-left: 8px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid $text-secondary;
    }

    &.collapsed {
      &:after {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    &:hover {
      text-decoration: none;

      .toggleText:before {
        text-decoration: none;
      }
    }
  }

  //@include media-breakpoint-only(xs) {
  @media (max-width: 500px) {
    .result-more-details {
      width: 100%;
      overflow-x: scroll;

      .inner {
        width: 550px;
      }
    }
  }

  .more-details-header {
    font-size: 13px;
    font-family: $font-bold;
    color: $text-primary;
    border-bottom: 1px solid #e1e1e1;

    @include media-breakpoint-up(md) {
      font-size: 15px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }

    div {
      padding: 5px 5px 10px;
      word-break: break-all;

      @include media-breakpoint-up(md) {
        padding: 5px 8px 10px;
      }

      @include media-breakpoint-down(md) {
        //font-size: 12px;
      }
    }
  }

  .more-details-data {
    .details-row-data {
      font-size: 13px;
      background: #f9f9f9;
      border-bottom: 1px solid #e1e1e1;
      color: $text-primary;
      font-family: $font-bold;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }

      &:nth-child(odd) {
        background: #ededed;
      }

      div {
        padding: 14px 8px;

        &.code {
          > span {
            position:relative;
            &:after {
              content:'/';
              position: absolute;
              top: 50%;
              left: 50%;
              font-size: 8px;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      &.other {
        font-size: 13px;
      }
    }
  }
}

.blagovni-dobitki {
  h2 {
    color:#000;
  }
  .result-show-more {
    font-size: 15px !important;
    font-family: $font-bold;
  }
  .content-blagovni {
    h3 {
      font-size: 18px;
      color: $text-secondary;
      font-family: $font-normal;
      margin-bottom: 6px;
    }

    .table-container {
      overflow: unset;
      width: 100%;
    }

    table {
      width: 100% !important;
      overflow-x: unset;
      font-size: 13px;
      color: $text-primary;
      font-family: $font-bold;
      @include media-breakpoint-up(md) {
        font-size: 16px;
      }

      tr {
        td {
          background: #f2f2f2;
          border-bottom: 1px solid #e1e1e1;
          padding: 14px 8px;
        }

        &:nth-child(even) {
          td {
            background: #f9f9f9;
          }
        }
      }

      thead {
        tr {
          td {
            background: #f9f9f9;
            font-size: 13px;
            padding: 5px 5px 10px;

            @include media-breakpoint-up(md) {
              font-size: 15px;
            }

            @include media-breakpoint-up(lg) {
              font-size: 20px;
              padding: 5px 8px 10px;
            }
          }
        }
      }
    }
  }
}

.blagovni-dobitek {
  z-index: 200;
  //background:$loto;
  //padding: 10px;

  @include media-breakpoint-up(sm) {
    top: 93px;
    position: absolute;
    right: 13px;
  }

  @include media-breakpoint-up(md) {
    top: 102px;
  }
  
  @include media-breakpoint-up(lg) {
    right: 0;
  }

  p {
    line-height: 18px;
    text-align:right;
    color:#fff;
  }

  .image {
    text-align: right;
    margin-top: 14px;

    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }

    img {
      background:$loto;
      padding: 5px;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        height: auto;
      }

      @include media-breakpoint-up(md) {
        width: 160px;
        height: 63px;
      }
      
      @include media-breakpoint-up(lg) {
        width: 180px;
        height: 83px;
      }
    }
  }

  &.game-banner {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 15px;
    width: 100px;
    padding: 0;
    background: none;

    @include media-breakpoint-up(sm) {
      width: auto;
    }

    .title { 
      h3 {
        font-size: 12px;
        color:#fff;
        text-align:left;
        line-height: 1.3;
        letter-spacing: 0.38px;
        
        @include media-breakpoint-up(sm) {
          font-family: $font-bold;
          font-size: 16px;
        }

        @include media-breakpoint-up(md) {
          font-size: 20px;
          line-height: 1.15;
        }
      }
    }

    .image {
      margin-top: 0px;
      img {
        width: 100px;
        height: 46px;
        max-width: none;

        @include media-breakpoint-up(sm) {
          width: 180px;
          height: 83px;
        }

        @include media-breakpoint-up(md) {
          width: 240px;
          height: 110px;
        }
      }
    }
  }
}

.home {
  .blagovni-dobitek {
    .image {
      text-align: left;
      img {
        background: none;
        padding: 0;
      }
    }
  }
}

.results {
  .blagovni-dobitek {
    @include media-breakpoint-only(xs) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.flatpickr-day {
  &.selected {
    background: $primary;
    border-color: $primary;
  }

  &:focus {
    border-radius: 150px;
    background: $primary;
    border-color: $primary;
    color:#fff;
  }
}