.prodaja-na-blagajnah {
  .prodaja-inner-visual {
    overflow: hidden;
    max-height: 300px;
    position: relative;
    z-index: 100;
    height: 300px;

    @include media-breakpoint-up(lg) {
      max-height: 300px;
      height: auto;
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 65%;
      left: 0;
      top: 0;
      content: '';
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 66.5%);
      background-blend-mode: multiply;

      @include media-breakpoint-up(md) {
        width: 64%;
        height: 100%;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 66.5%);
      }
    }

    .element {
      position: relative;
      width: 100%;
      height: 100%;

      .image {
        overflow: hidden;
        height: 300px;

        @include media-breakpoint-up(lg) {
          max-height: 300px;
          height: 300px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          max-height: 300px;
          filter: brightness(0.4);

          @include media-breakpoint-up(md) {
            filter: brightness(1);
          }
        }
      }

      .content {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;

        @include media-breakpoint-up(lg) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-top: 0;
        }

        h1 {
          color: $white;
          font-size: 34px;
          font-family: $font-bold;
          max-width: 300px;
          line-height: normal;

          @include media-breakpoint-up(lg) {
            font-size: 40px;
          }
        }

        img {
          height: 186px;
          width: auto;
          margin-right: -40px;
          margin-top: -55px;

          @include media-breakpoint-up(md) {
            height: 254px;
            margin-top: 0;
          }
        }
      }
    }
  }

  .content-container {
    .steps-container {
      padding-top: 25px;
      padding-bottom: 94px;


      .top-text {
        display: flex;
        margin-bottom: 44px;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        @include media-breakpoint-up(lg) {
          margin-bottom: 80px;
        }

        .text {
          font-family: $font-bold;
          font-size: 25px;
          line-height: normal;
          color: #007a2f;
          width: 100%;

          @include media-breakpoint-up(lg) {
            font-size: 45px;
            max-width: 50%;
          }

          .small {
            font-size: 16px;
            line-height: normal;
            font-family: $font-normal;
            color: #575757;
          }
        }

        .img {
          width: 100%;
          text-align: center;

          @include media-breakpoint-up(lg) {
            max-width: 50%;
            text-align: end;
          }

          img {
            object-fit: cover;
            width: auto;
            height: 322px;
          }
        }
      }

      .steps {
        padding: 0;
        margin: 0;
        gap: 40px;

        @include media-breakpoint-up(lg) {
          gap: 68px;
        }

        .step {
          list-style-type: none;
          text-align: center;

          @include media-breakpoint-up(lg) {
            text-align: start;
          }

          img {
            margin-bottom: 24px;
          }

          p {
            color: #575757;
            font-size: 20px;
            line-height: normal;
          }

          strong {
            font-size: 22px;
            line-height: normal;
            color: #007a2f;

            @include media-breakpoint-up(lg) {
              font-size: 40px;
            }
          }

          em {
            font-family: $font-semibold;
            font-style: normal;
          }
        }
      }
    }

    .video-section {
      position: relative;
      z-index: 10;
      background-color: #e6e7e8;
      padding-top: 65px;
      padding-bottom: 65px;
      padding-left: 19px;
      padding-right: 19px;

      .ball {
        position: absolute;
        bottom: -61px;
        right: -33px;
        transform: rotate(32deg);

        @include media-breakpoint-up(lg) {
          bottom: auto;
          top: -34px;
        }

        img {
          width: 102px;
          height: 102px;
        }
      }

      .row {
        justify-content: space-between;
        row-gap: 40px;
      }

      .ytvideo {
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        max-width: 100%;
        height: 307px;
        background: center;
        background-size: cover;

        @include media-breakpoint-up(lg) {
          max-width: 47%;
        }

        p {
          color: #fff;
          font-family: $font-bold;
          font-size: 24px;
          line-height: normal;
          position: absolute;
          left: 40px;
          top: 50%;
          transform: translateY(-50%);
        }

        .play-btn {
          position: absolute;
          cursor: pointer;
          transform: scale(1);
          transition: all 1s;

          @include media-breakpoint-up(md) {

            transform: scale(1);
          }
        }


        &:hover {
          .play-btn {
            transform: scale(1.1);
            transition: all 1s;
          }
        }
      }


      .youtube-video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .gray-container {
    background: #D9D9D9;
    padding-top: 34px;
    padding-bottom: 34px;

    @include media-breakpoint-up(lg) {
      padding-top: 72px;
      padding-bottom: 72px;
    }

    .third {
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: normal;

      strong {
        font-size: 30px;
        line-height: normal;
        font-family: $font-bold;
        color: #575757;

        @include media-breakpoint-up(lg) {
          font-size: 40px;
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        height: auto;
        max-height: 310px;

        @include media-breakpoint-up(lg) {
          max-height: 387px;
        }
      }

      &:nth-child(1) {
        order: 2;
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
          order: 1;
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        order: 1;
        justify-content: end;

        @include media-breakpoint-up(lg) {
          order: 2;
          justify-content: center;
        }
      }

      &:nth-child(3) {
        order: 3;
      }
    }
  }

  .bottom-section {
    padding-top: 65px;
    padding-bottom: 27px;
    font-size: 20px;
    line-height: normal;

    @include media-breakpoint-up(lg) {
      padding-top: 40px;
      padding-bottom: 27px;
    }

    h2 {
      font-family: $font-bold;
      font-size: 30px;
      line-height: normal;
      color: #007a2f;

      @include media-breakpoint-up(lg) {
        font-size: 40px;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-top: 49px;
      padding-bottom: 89px;
    }

    .bottom-top {
      margin-bottom: 32px;
      position: relative;

      .ball {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
          position: absolute;
          left: -95px;
          top: 20px;
        }


        img {
          width: 250px;
          height: 250px;
        }
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 64px;
      }

      p {
        font-size: 16px;
        line-height: normal;
      }

      .img-right {
        margin-bottom: 69px;

        @include media-breakpoint-up(md) {
          margin-bottom: 41px;
        }

        .image {
          margin-bottom: 34px;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }

          img {
            width: 100px;
            height: 100px;

            @include media-breakpoint-up(md) {
              width: auto;
              height: auto;
            }
          }
        }
      }
    }

    .bottom-bot {
      position: relative;
      padding-bottom: 140px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }

      .ball {
        transform: rotate(-21deg);
        position: absolute;
        right: -24px;
        bottom: 24px;

        @include media-breakpoint-up(lg) {
          right: -72px;
          top: 0;
          bottom: auto;
        }

        img {
          width: 84px;
          height: 84px;

          @include media-breakpoint-up(lg) {
            width: 226px;
            height: 226px;
          }
        }
      }

      .ball-two {
        position: absolute;
        left: -27px;
        bottom: 0;
        transform: rotate(-21deg);

        @include media-breakpoint-up(lg) {
          display: none;
        }

        img {
          width: 78px;
          height: 78px;
        }
      }

      p {
        font-size: 16px;
        line-height: normal;
      }

      .ten-col {
        margin-bottom: 44px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .bottom-packages {
      margin-bottom: 44px;

      p {
        font-size: 16px;
        line-height: normal;
      }


      .packages {
        margin-bottom: 40px;

        @include media-breakpoint-up(md) {
          margin-bottom: 48px;
        }

        .container-xs {
          @include media-breakpoint-up(md) {
            padding: 0 19px;
          }
        }

        .row {
          row-gap: 12px;
        }
      }

      .packages-loto,
      .packages-jackpot {
        margin-bottom: 12px;
      }

      .packages-row {
        overflow: visible !important;

        @include media-breakpoint-up(md) {
          overflow: hidden;
        }
      }

      .splide {
        .splide__list {
          .splide__slide {
            &:focus-visible {
              outline: 2px solid #007bff;
              outline-offset: 4px;
            }
          }
        }

        .splide__pagination {
          position: relative;
          bottom: 0;
          gap: 10px;
          margin-top: 10px;

          li {
            outline: none;
          }

          li {
            &:focus-visible {
              outline: 2px solid #007bff;
              outline-offset: 4px;
            }
          }

          .splide__pagination__page {
            background: #757575;

            &.is-active {
              background: #007a2f;
            }
          }
        }
      }

      .single-package {
        color: #fff;

        @include media-breakpoint-up(md) {
          padding-left: 6px;
          padding-right: 6px;
        }

        .package-redirect {
          color: #fff;
          text-decoration: none !important;
          cursor: pointer;
        }

        .inner-wrapper {
          padding: 13px;
          position: relative;

          @include media-breakpoint-up(md) {
            padding: 9px 8px;
          }

          h3 {
            font-size: 35px;
            line-height: normal;
            margin-bottom: 0;
            font-family: $font-bold;

            @include media-breakpoint-up(md) {
              font-size: 24px;
            }
          }

          p {
            font-size: 24px;
            line-height: normal;

            @include media-breakpoint-up(md) {
              font-size: 17px;
            }
          }

          a {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
          }

          .package-top {
            height: 150px;

            @include media-breakpoint-up(md) {
              height: 105px;
            }
          }

          .package-bot {
            border-top: 1px solid #fff;
            font-family: $font-bold;
            padding-top: 9px;

            .price {
              font-size: 44px;
              line-height: 38px;
              display: flex;

              @include media-breakpoint-up(md) {
                font-size: 30px;
                line-height: 30px;
              }

              span {
                font-size: 24px;
                line-height: 24px;

                @include media-breakpoint-up(md) {
                  font-size: 16px;
                  line-height: normal;
                }
              }
            }

            .decimal {
              font-size: 24px;
              line-height: 24px;

              @include media-breakpoint-up(md) {
                font-size: 28px;
                line-height: 28px;
              }
            }
          }
        }

        &.red {
          .inner-wrapper {
            background-color: #D61A21;
          }

          .logo {
            background-color: #D61A21;
            display: flex;
            align-items: center;

            img {
              height: 24px;
              width: auto;

              @include media-breakpoint-up(md) {
                height: 16px;
              }
            }
          }
        }

        &.yellow {

          .inner-wrapper {
            color: #5E1700;
            background-color: #FCB812;
          }

          .logo {
            background-color: #FCB812;
            margin-top: -12px;

            img {
              height: 60px;
              width: auto;

              @include media-breakpoint-up(md) {
                height: 41px;
              }
            }
          }

          .package-bot {
            border-top: 1px solid #5E1700;
          }
        }

        &.blue {
          .inner-wrapper {
            background-color: #00F;
          }

          .logo {
            background-color: #00F;
            margin-top: -10px;
            padding-left: 12px;

            img {
              height: 51px;
              width: auto;

              @include media-breakpoint-up(md) {
                height: 35px;
              }
            }
          }
        }
      }
    }
  }
}