.partnerska-mreza {
  #header-wrapper-partnerska {
    height: 54px;

    @include media-breakpoint-up(lg) {
      height: 80px;
    }

    header {
      height: 80px;

      &:before {
        content:'';
        background: none;
        -webkit-transition: background .4s;
        -ms-transition: background .4s;
        transition: background .4s;
      }

      &.overlay {
        &:before {
          content:'';
          background: rgba(0,0,0,0.65);
          width: 100%;
          height: 100%;
          position: fixed;
          top:0;
          left:0;
          z-index: 710;
          -webkit-transition: background .4s;
          -ms-transition: background .4s;
          transition: background .4s;
        }
      }

      height: 50px;
      background: $primary;
      position: fixed;
      top: 0;
      width: 100%;
      background: #f4f4f4;
      z-index: 700;
      transition: height 0.2s ease;

      @include media-breakpoint-up(lg) {
        height: 80px;
        background: $primary;
        overflow: visible;
      }

      .header-container {
        position: relative;
        background: $primary;

        .header-fixed {
          flex: 1;
        }

        @include media-breakpoint-up(lg) {
          &.container-lg {
            width: 100%;
            max-width: 1160px;
          }
        }

        @include media-breakpoint-up(lg) {
          height: 100%;
        }

        @include media-breakpoint-down(md) {
          padding: 0 20px;
        }

        @include media-breakpoint-down(sm) {
          padding: 0 10px;
        }

        > .container-fluid {
          padding: 10px 20px;
          position: relative;
          background: $primary;

          @include media-breakpoint-up(lg) {
            height: 100%;
          }
        }

        a {
          color: #fff;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }

        .logo-container {
          @include media-breakpoint-up(lg) {
            width: 150px;
            position: relative;
            margin-left: -2px;
            margin-top: -9px;
          }
        }

        .logo {
          display: block;
          z-index: 50;
          width: 150px;
          transition: width 0.15s;

          img {
            display: block;
            width: 90px;
            height: 24px;
            transition: width 0.3s, height 0.3s;

            @include media-breakpoint-up(lg) {
              width: 110px;
              height: 30px;
            }

            @include media-breakpoint-up(xl) {
              width: 150px;
              height: 40px;
            }

            @include media-breakpoint-down(sm) {
              height: 25px;
            }
          }
        }

        .menu-trigger {
          cursor: pointer;
          width: 23px;
          height: 24px;
          display: block;
          margin-left: -40px;

          .open-menu {
            width: 23px;
            height: 24px;
            position: absolute;
            transition: opacity 200ms cubic-bezier(.455, .03, .515, .955);
            opacity: 1;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='19px' height='19px' viewBox='0 0 23 24' enable-background='new 0 0 23 24' xml:space='preserve'%3e%3cg opacity='0.7'%3e%3cellipse fill='%23FFFFFF' cx='2.738' cy='3.273' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='2.738' cy='12' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='2.738' cy='20.727' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='11.5' cy='3.273' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='11.5' cy='12' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='11.5' cy='20.727' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='20.262' cy='3.273' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='20.262' cy='12' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='20.262' cy='20.727' rx='2.738' ry='2.727'/%3e%3c/g%3e%3c/svg%3e");
          }

          @include media-breakpoint-up(md) {
            margin-right: 30px;
          }

          @include media-breakpoint-down(md) {
            margin-right: 47px;
            margin-top: 4px;
            margin-left: 0;
          }

          @include media-breakpoint-down(sm) {
            margin-right: 57px;
          }

          .close-menu {
            width: 25px;
            height: 25px;
            position: absolute;
            top: 50%;
            opacity: 0;
            transition: opacity 200ms cubic-bezier(.455, .03, .515, .955);
            transform: translate(0, -50%);

            &:before,
            &:after {
              width: 25px;
              height: 4px;
              position: absolute;
              top: 11px;
              left: 0;
              background-color: #fff;
              border-radius: 2px;
              border-radius: 2px;
              content: "";
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }
          }
        }

        ul {
          margin: 0;
          padding: 0;
        }

        li {
          list-style: none;
          margin: 0;
          padding: 0;

          @include media-breakpoint-up(lg) {
            display: inline-block;
          }
        }

        .navigation-container {
          @include media-breakpoint-up(lg) {
            margin-left: 20px;
          }

          @include media-breakpoint-up(xl) {
            margin-left: 42px;
          }
        }

        .top-navi {
          margin: 0;
          padding: 25px;
          position: relative;
          z-index: 100;

          @include media-breakpoint-up(lg) {
            -webkit-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            padding: 2px 0 0;
            transition: -webkit-transform 0.3s ease;
            transition: transform 0.3s ease;
            transition: transform 0.3s ease, -webkit-transform 0.3s ease;
          }

          ul {
            position: relative;

            li {
              position: relative;
              padding: 0;
              margin: 0;

              @include media-breakpoint-up(lg) {
                margin: 0 29px 0 0;
              }

              a {
                font-size: 12px;
                letter-spacing: 1px;
                text-transform: uppercase;
                line-height: 33px;
                cursor: pointer;
                display: block;

                @include media-breakpoint-up(lg) {
                  display: inline-block;
                  line-height: 19px;
                }
              }

              &:first-child {
                padding-left: 0;
              }
            }
          }
        }

        .navi {
          padding: 0 25px;

          @include media-breakpoint-up(lg) {
            margin: 10px 0 0;
            padding: 0;
            -webkit-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            transition: -webkit-transform 0.3s ease;
            transition: transform 0.3s ease;
            transition: transform 0.3s ease, -webkit-transform 0.3s ease;
          }

          li {
            @include media-breakpoint-up(lg) {
              margin-right: 25.5px;
            }

            a {
              line-height: 50px;
              display: block;
              position: relative;
              font-size: 14px;
              letter-spacing: 0.15px;
              font-family: $font-bold;
              border-bottom: 1px solid rgba(255, 255, 255, 0.7);
              padding-left: 4px;

              @include media-breakpoint-up(lg) {
                line-height: 22px;
                font-size: 17px;
                border: none;
                padding-left: 0;
              }

              @include media-breakpoint-up(lg) {
                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  background-color: #fff;
                  width: 0;
                  left: 50%;
                  bottom: 1px;
                  height: 2px;
                  transform: translate(-50%, 0);
                  transition: width 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
                }

                &:hover:after,
                &.active:after {
                  width: 100%;
                  transition: width 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
                }
              }
            }
          }
        }
      }

      #link-container {
        margin: 10px 0 0;

        a {
          display: inline-block;
          text-transform: uppercase;
          transition: all .3s ease-in-out;

          &:hover {
            transform: translate(0,-4px);
            transition: transform 250ms cubic-bezier(.46,.03,.52,.96);
          }
        }
      }
    }
  }
}
