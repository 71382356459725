.partnerska-mreza {
  .funds {
    background-color: $white;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 40px;
    padding: 30px 24px;

    .fund-round h2 {
      font-family: $font-normal;
      font-size: 20px;
      font-weight: 400;
      color: $green2;
    }

    .fund {
      border-bottom: 1px solid #e1e1e1;

      &:last-child {
        border: none;
      }

      .fund-title {
        margin: 0;
        padding: 13px 0;
        font-family: $font-bold;
        font-size: 14px;
        font-weight: 700;
        color: $black;
      }

      .fund-value {
        margin: 0;
        padding: 9px 0;
        font-family: $font-bold;
        font-size: 20px;
        font-weight: 700;
        color: #d0021b;
      }

      .nofund {
        margin: 0;
        padding: 9px 0;
      }
    }

    &.novoletniloto {
      .fund {
        .fund-title {
          font-family: $font-normal;
          font-weight: 400;
        }
      }
    }
  }
}
