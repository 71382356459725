#cookie-consent {
  display: none;
  position: fixed;
  top: auto;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  z-index: 900;
  color: #fff;
  font-style: normal;
  font-size: 12px;
  padding: 35px 0;
  margin: 0;

  a {
    cursor: pointer;
  }

  .cc-text {
    width: 100%;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 35px;
    }

    p {
      font-size: 12px;
      line-height: 20px;
    }

    a {
      color: #069e41;
      text-decoration: underline;

      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .cc-settings {
    .cc-cookie {
      padding: 0;
      margin: 5px 0 10px 0;
      display: flex;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }

      input[type="checkbox"] {
        margin-right: 12px;
        width: 20px;
        height: 20px;
        -webkit-appearance: none;
        position: relative;

        &:before {
          content: '';
          width: 20px;
          height: 20px;
          border: 2px solid #ccc;
          display: inline-block;
          border-radius: 50%;
          outline: none;
          cursor: pointer;
        }

        &:checked:before {
          border: 2px solid $primary;
          outline: none;
        }
        
        &:checked:after {
          content:'';
          display: block;
          position: absolute;
          left: 7px;
          top: 4px;
          width: 6px;
          height: 10px;
          border: solid $primary;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        } 
      }

      label {
        margin: 0;
        .material-icons {
          font-size: 18px;
        }
      }

      .icon {
        border: 0;
        outline: 0;
        background: none;
        cursor: pointer;
        width:24px;
        height:24px;
        display: block;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath fill='%2300953a' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z'/%3e%3c/svg%3e");

        &:focus {
          @extend .input-focus;
        }
      }
    }

    .cc-buttons {
      .btn {
        min-width: 0;
        width: 235px;
        padding: 0 15px;
        color:#fff;

        @include media-breakpoint-up(sm) {
          width: auto;
        }

        &:hover,
        &:focus {
          color: $primary;
        }

        &.cc-save {
          background: none;
          border: 1px solid #fff;
          &:hover,
          &:focus {
            background: #fff;
          }
        }
      }

      .close {
        position: absolute;
        padding: 0px;
        margin-left: 20px;
        text-shadow: none;
        opacity: 1;
        float: none;
        display: inline-block;
        top: auto;

        @include media-breakpoint-up(sm) {
          position: relative;
        }

        span.icon-close {
          width: 15px;
          height: 15px;
          display: block;
          background: url("data:image/svg+xml,%3Csvg width='15px' height='15px' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Elements--/-iC-Clear'%3E%3Crect id='Rectangle' x='2.27373675e-13' y='0' width='15' height='15'%3E%3C/rect%3E%3Cpath d='M7.5,1.5 L7.5,13.5' id='Line' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='square' transform='translate(7.500000, 7.500000) rotate(45.000000) translate(-7.500000, -7.500000) '%3E%3C/path%3E%3Cpath d='M7.5,1.5 L7.5,13.5' id='Line' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='square' transform='translate(7.500000, 7.500000) rotate(135.000000) translate(-7.500000, -7.500000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center / contain;
          position: relative;
          top: 3px;
        }
        
        &:hover {
          opacity: 1;

          span {
            color:$primary;
          }
        }
      }
    }
  }

  .cc-actions-toggle,
  .cc-settings-toggle {
    &.cc-closed {
      display: none;
    }
  }

  a {
    color: #adadad;
    &:hover {
      color: #fff;
    }
  }

  .cc-space-around {
    padding: 0 12px;
  }
}

.cookies {
  .cookies-status {
    font-size: 18px;
  }
  .accordion-container {
    margin-bottom: 30px;
    .accordion {
      .acc-element {
        label {
          margin: 0;
          display: block;
          position: absolute;
          top:0;
          left:0;
          z-index: 999;
          width: 25px;
          height: 100%;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .checkmark {
            position: absolute;
            top: 14px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: 1px solid #b6b6b6;
            border-radius: 50%;
            &:after {
              content: "";
              position: absolute;
              display: none;
              left: 6px;
              top: 2px;
              width: 6px;
              height: 12px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }

            &:before {
              content: '';
              left: -3px;
              top: -3px;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              position: absolute;
              display: block;
              z-index: -1;
            }
          }
      
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            top:21px;
            left: 10px;
      
            &:checked ~ .checkmark {
              background-color: $green;
            }
      
            &:checked ~ .checkmark:after {
              display: block;
            }

            &:focus~.checkmark {
              &:before {
                border: 2px solid #80bdff;
              }
            }
          }
        }
        
        .acc-element-header {
          position: relative;
          .acc-element-title {
            padding-left: 30px !important;
          }
        }

        .acc-element-body {
          padding: 5px 50px 20px 33px;
          font-size: 14px;

          table {
            thead {
              tr {
                td {
                  color:$text-primary;
                  padding: 5px 20px;
                  border-top: none;
                  border-left: 1px solid #e1e1e1;

                  &:first-child {
                    padding-left: 0;
                    border-left: none;
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  border-top: 1px solid #e1e1e1;
                  border-left: 1px solid #e1e1e1;
                  padding: 15px 20px;
                  vertical-align: top;

                  &:first-child {
                    padding-left: 0;
                    border-left: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}