#header-wrapper {
  height: 50px;

  @include media-breakpoint-up(lg) {
    height: 85px;
  }
  &:before {
    content: "";
    height: 50px;
    background: $primary;
    width: 100%;
    display: block;

    @include media-breakpoint-up(lg) {
      height: 85px;
    }
  }

  header {
    &:before {
      content:'';
      opacity:0;
      visibility: hidden;
      width: 100%;
      height: 100%;
      position: fixed;
      top:0;
      left:0;
      z-index: 710;
      -webkit-transition: all 0.25s ease-in;
      background: rgba(0,0,0,0.66);
      -ms-transition: all 0.25s ease-in;
      transition: all 0.25s ease-in;
    }
    
    &.overlay {
      &:before {
        opacity: 1;
        visibility: visible;
        -webkit-transition: all 0.25s ease-in;
        -ms-transition: all 0.25s ease-in;
        transition: all 0.25s ease-in;
      }
    }

    height: 50px;
    background: $primary;
    position: fixed;
    top: 0;
    width: 100%;
    background: #f4f4f4;
    z-index: 700;
    //overflow: hidden;
    transition: height 0.2s ease;

    @include media-breakpoint-up(lg) {
      height: auto;
      background: $primary;
      overflow: visible;
    }

    .header-container {
      position: relative;
      background: $primary;

      @include media-breakpoint-up(lg) {
        &.container-lg {
          width: 100%;
          max-width: 1160px;
        }
      }

      @include media-breakpoint-up(lg) {
        height: 100%;
      }

      @include media-breakpoint-down(md) {
        padding: 0 20px;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 10px;
      }

      > .container-fluid {
        padding: 10px 20px;
        position: relative;
        background: $primary;

        @include media-breakpoint-up(lg) {
          height: 100%;
        }
      }

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
        
        &.my-profile:hover {
          text-decoration: underline;
        }
      }

      .header-fixed {
        height: 50px;
        padding: 0;
        transition: height 0.3s ease;

        @include media-breakpoint-up(lg) {
          height: 100%;
          padding: 11px 0;
        }
      }

      .logo-container {
        h1 {
          margin: 0;
          font-size: initial;
          font-weight: initial;
        }
        .logo {
          display: block;
          margin-top: -8px;

          
          @media (max-width: 1219.98px) {
            max-width: 150px;
            position: relative;
            margin-top: -9px;
          }
          
          @media (max-width: 991.98px) {
            max-width: 90px;
            margin-top:0;
          }

          @media (max-width: 1219.98px) and (min-width: 992px) {
            max-width: 110px;
            margin-left: -5px;
          }          

          @media (min-width: 1220px) {
            width: 150px;
          }

          svg {
            height: auto;
            width: auto;
            
            max-width: 100%;

            g.logo-text {
              opacity: 1;
              transition: all .3s ease-in-out;
            }

            @media (min-width: 1220px) {
              height: 40px;
            }
          }
        }

        @media (min-width: 1025px) {
          margin-top: 12px;
        }
      }

      .menu-trigger {
        cursor: pointer;
        width: 19px;
        height: 19px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background:none;

          
        &:before {
          content:'';
          width: 19px;
          height: 19px;
          display: block;
          position:absolute;
          top:0;
          left: 0;
          background: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='19px' height='19px' viewBox='0 0 23 24' enable-background='new 0 0 23 24' xml:space='preserve'%3e%3cg opacity='0.7'%3e%3cellipse fill='%23FFFFFF' cx='2.738' cy='3.273' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='2.738' cy='12' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='2.738' cy='20.727' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='11.5' cy='3.273' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='11.5' cy='12' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='11.5' cy='20.727' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='20.262' cy='3.273' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='20.262' cy='12' rx='2.738' ry='2.727'/%3e%3cellipse fill='%23FFFFFF' cx='20.262' cy='20.727' rx='2.738' ry='2.727'/%3e%3c/g%3e%3c/svg%3e") no-repeat center center / contain;
        }

        &:after {
          content:'';
          background: none;
          //transition: background 200ms cubic-bezier(.455, .03, .515, .955);
        }

        &.menu-opened {
          width: 21px;
          height: 21px;
          position: absolute;
          top: 50%;

          &:before,
          &:after {
            width: 25px;
            height: 4px;
            position: absolute;
            top: 8px;
            left: -2px;
            background: #b7ddc8;
            border-radius: 2px;
            border-radius: 2px;
            content: "";
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }

      #user-data {
        z-index: 50;
        text-align: -webkit-center;
        position: relative;
        transition: all .2s ease-in-out;

        @include media-breakpoint-up(lg) {
          margin-top: 5px;

          &:hover {
            transform: translate(0,-4px);
          }
        }
        
        /*@include media-breakpoint-up(lg) {
          margin-top: 8px;
          margin-right: -5px;
        }*/

        #avatar {
          height: 30px;
          width: 30px;
          cursor: pointer;
          background-position: center center !important;
          background-size: cover !important;
          order:2;
          margin:0 0 0 8px;

          @include media-breakpoint-up(lg) {
            order: 1;
            margin: 0 0 6px 0;
          }
        }

        span {
          font-size: 12px;
          line-height: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: $white;
          order: 1; 
          @include media-breakpoint-up(lg) {
            order: 2;
          }
        }
      }

      div.login {
        position: relative;
        top: 0px;
        right: 0;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        z-index: 720;

        @include media-breakpoint-up(lg) {
          position: absolute;
          right: 9px;
          top: 25px;
        }

        a {
          display: inline-block;
          width: 100px;
          height: 31px;
          border: solid 1px #ffffff;
          text-align: center;
          font-size: 11px;
          letter-spacing: 1.4px;
          font-family: $font-bold;
          line-height: 11px;
          padding: 9px 0px;
          margin-left: 20px;

          @include media-breakpoint-down(sm) {
            width: 90px;
            padding: 9px 10px !important;
          }

          @include media-breakpoint-down(md) {
            padding: 8px 10px;
            height: 30px;
          }

          @include media-breakpoint-up(lg) {
            width: 130px;
          }

          &:hover {
            background: #fff;
            color: $primary;
          }

          &.signup {
            @include media-breakpoint-up(lg) {
              display: inline-block;
            }

            display: none;
            font-family: $font-normal;
            border: none;
            white-space: nowrap;
            width: auto;
            text-decoration: underline;

            &:hover {
              background: none;
              color: #fff;
            }
          }
        }
      }

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        list-style: none;
        margin: 0;
        padding: 0;

        @include media-breakpoint-up(lg) {
          display: inline-block;
        }
      }

      .navigation-container {
        padding: 53px 0 0;

        @include media-breakpoint-up(lg) {
          margin-left: 20px;
          padding: 0;
        }

        @include media-breakpoint-up(xl) {
          margin-left: 40px;
          padding: 0;
        }

        @media (max-width: 1219px) and (min-width: 992px) {
          margin-left: 20px;
          padding: 0;
        }
      }

      .top-navi {
        margin: 0;
        padding: 20px;
        position: relative;
        z-index: 100;
        margin-top: auto;

        @include media-breakpoint-up(md) {
          padding: 30px;
        }
        
        @include media-breakpoint-up(lg) {
          -webkit-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
          transform: translate(0, 0);
          padding: 2px 0 0;
          margin-top: 0;
          transition: -webkit-transform 0.3s ease;
          transition: transform 0.3s ease;
          transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        }

        ul {
          position: relative;

          &.level1 {
            @media (max-width: 768px) {
              margin-top: 7px;
            }
          }

          li {
            ul.level2 {
              display: none;
            }
          }

          li {
            position: relative;
            padding: 0;
            margin: 0;

            @include media-breakpoint-up(lg) {
              margin: 0 29px 0 0;
            }

            a {
              font-size: 12px;
              letter-spacing: 1px;
              text-transform: uppercase;
              line-height: 30px;
              cursor: pointer;
              display: block;

              @include media-breakpoint-up(lg) {
                display: inline-block;
                line-height: 19px;
              }
            }

            &:first-child {
              padding-left: 0;
            }

            &.level1 {
              a {
                text-transform: uppercase;
                &:hover {
                  text-decoration: underline;
                }
              }

              @include media-breakpoint-up(lg) {
                &:hover {
                  ul.level2 {
                    display: flex !important;
                    flex-direction: column;
                    flex-wrap: wrap;
                    position: absolute;
                    top: 16px;
                    left: 3px;
                    width: 175px;
                    z-index: 200;
                    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);

                    li {
                      width: 100%;

                      a {
                        display: block;
                        padding: 5px 18px;
                        font-size: 14px;
                        color: #fff;
                        background: $primary;
                      }

                      &:nth-child(2) {
                        a {
                          background: $theme-black;
                        }
                      }

                      &:nth-child(3) {
                        a {
                          background: $theme-white;
                          color: $theme-black;
                        }
                      }

                      &:nth-child(4) {
                        a {
                          background: $theme-blue;
                          color: $theme-yellow;
                        }
                      }

                      &:nth-child(5) {
                        a {
                          background: $theme-beige;
                          color: $theme-black;
                        }
                      }

                      &:nth-child(6) {
                        a {
                          background: $theme-black;
                          color: $theme-green;
                        }
                      }
                    }
                  }
                }
              }
            }

            &.has-submenu {
              position: static;
              padding: 0;
              margin-right: 29px;
              transition: padding 0.3s;

              > a {
                &:after {
                  content: "";
                  @extend .arrowdown-white;
                }
              }

              .submenu {
                opacity: 0;
                visibility: hidden;
                height: 0;

                @include media-breakpoint-up(lg) {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  height: auto;
                  transition: height .25s, opacity .35s, visibility .35s;
                }

                li {
                  a {
                    text-transform: none;
                    display: inline-block;

                    @include media-breakpoint-up(lg) {
                      display: inline-block;
                    }
                  }
                }
              }

              &.submenu-open {
                padding-bottom: 0px;

                @include media-breakpoint-up(lg) {
                  padding-bottom: 32px;
                  transition: padding 0.3s;
                }

                > a {
                  &:after {
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                  }
                }

                .submenu {
                  display: block;
                  padding-left: 10px;
                  opacity: 1;
                  visibility: visible;
                  height: auto;

                  @include media-breakpoint-up(lg) {
                    display: inline-block;
                    padding-left: 0;
                    transition: height .25s, opacity .35s, visibility .35s;
                  }

                  li {
                    @include media-breakpoint-up(lg) {
                      margin-right: 27px;
                    }

                    /*&.active {
                      &:before {
                        content: "";
                        position: absolute;
                        bottom: 2px;
                        height: 2px;
                        background-color: #fff;
                        width: 100%;
                        left: 0;
                        pointer-events: none;
                        transition: opacity .3s ease;
                      }
                    }*/
                  }
                }
              }

              .has-theme-submenu {
                position: relative;

                /*> a {
                  padding-right: 18px;
                }*/

                .theme-submenu {
                  display: none;
                }

                > a {
                  &:after {
                    content: "";
                    @extend .arrowdown-white;
                  }
                }

                &.theme-submenu-open {
                  .theme-submenu {
                    display: flex !important;
                    flex-direction: column;
                    flex-wrap: wrap;
                    width: 100%;
                    z-index: 200;

                    @include media-breakpoint-up(lg) {
                      position: absolute;
                      top: 30px;
                      left: -10px;
                      width: 135px;
                    }

                    li {
                      min-width: 132px;
                      padding: 0;

                      a {
                        display: block;
                        padding: 0px 10px;
                        font-size: 12px;
                        line-height: 26px;
                        color: #fff;
                        background: $primary;
                      }

                      &.bela-na-crnem {
                        background: #000 !important;

                        a {
                          background: #000 !important;
                          color: #fff !important;

                          span {
                            background: #000 !important;
                            color: #fff !important;
                          }
                        }
                      }

                      &.crna-na-belem {
                        background: #fff !important;
                        color: #000 !important;

                        a {
                          background: #fff !important;
                          color: #000 !important;

                          span {
                            background: #fff !important;
                            color: #000 !important;
                          }
                        }
                      }

                      &.rumena-na-modri {
                        background: #0a048e !important;

                        a {
                          background: #0a048e !important;
                          color: #fce74a !important;

                          span {
                            background: #0a048e !important;
                            color: #fce74a !important;
                          }
                        }
                      }

                      &.crna-na-bez {
                        background: #f5f5db !important;

                        a {
                          background: #f5f5db !important;
                          color: #000 !important;

                          span {
                            background: #f5f5db !important;
                            color: #000 !important;
                          }
                        }
                      }

                      &.zelena-na-crni {
                        background: #000 !important;

                        a {
                          background: #000 !important;
                          color: #51ff00 !important;

                          span {
                            background: #000 !important;
                            color: #51ff00 !important;
                          }
                        }
                      }
                    }
                  }

                  > a {
                    &:after {
                      -webkit-transform: rotate(180deg);
                      -ms-transform: rotate(180deg);
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
          }

          &.dostopnost {
            &.open {
              padding-bottom: 30px;

              ul.dostopnost {
                display: flex;
              }
            }

            ul.dostopnost {
              display: none;
              white-space: nowrap;
              position: absolute;
            }
          }
        }
      }

      .navi {
        padding: 0 20px;
        
        @include media-breakpoint-up(md) {
          padding: 0 30px;
        }
        
        @include media-breakpoint-up(lg) {
          margin: 10px 0 0;
          padding: 0;
          -webkit-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
          transform: translate(0, 0);
          transition: -webkit-transform 0.3s ease;
          transition: transform 0.3s ease;
          transition: transform 0.3s ease, -webkit-transform 0.3s ease;

          &.submenu-open {
            margin-top: 3px;
          }
        }

        li {
          @include media-breakpoint-up(lg) {
            margin-right: 15px;
          }

          @media (max-width: 1219px) and (min-width: 992px) {
            margin-right: 19px;
          }

          a {
            padding: 14px 0;
            display: block;
            position: relative;
            font-size: 14px;
            letter-spacing: 0.15px;
            font-family: $font-bold;
            border-bottom: 1px solid $white;

            @include media-breakpoint-up(lg) {
              line-height: 22px;
              font-size: 17px;
              border: none;
              padding: 0;
            }

            @include media-breakpoint-up(lg) {
              &:after {
                content: "";
                display: block;
                position: absolute;
                background-color: #fff;
                width: 0;
                left: 50%;
                bottom: 1px;
                height: 2px;
                transform: translate(-50%, 0);
                transition: width 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
              }

              &:hover:after,
              &.active:after {
                width: 100%;
                transition: width 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
              }
            }

            @media (max-width: 1219px) and (min-width: 992px) {
              font-size: 16px;
              letter-spacing: 0;
            }
          }
        }
      }
    }

    &.fixed-menu {
      height: 50px;

      .header-container #user-data {
        @include media-breakpoint-up(lg) {
          #avatar {
            margin-top: 18px;
          }
        }
        
        span {
          opacity: 0;
          transition: opacity 0.2s ease;
          pointer-events: none;
        }
      }

      .header-fixed {
        padding: 11px 0 16px;

        .navigation-container {
          @include media-breakpoint-up(lg) {
            margin-top: 3px;
          }
        }
      }

      .logo-container {
        @include media-breakpoint-up(lg) {
          margin-top: 5px;
        }

        .logo {
          svg {
            margin-top: 5px;
            height: 26px;

            g.logo-text {
              opacity: 0;
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        .top-navi {
          -webkit-transform: translate(0, -45px);
          -ms-transform: translate(0, -45px);
          transform: translate(0, -45px);

          ul li.has-submenu.submenu-open {
            padding-bottom: 0px;
          }

          .submenu {
            display: none !important;
          }
        }

        .navi {
          -webkit-transform: translate(0, -14px);
          -ms-transform: translate(0, -14px);
          transform: translate(0, -14px);
          margin-top: 0;
        }

        .login a.signup {
          opacity: 0;
          pointer-events: none;
        }
      }

      .header-modal {
        top: 40px;
      }

      div.login {
        top: 10px;

        @media (max-width: 992px) {
          top: 3px;
        }
      }
    }

    .footer-mobile {
      padding: 25px 20px 25px;
      background: #f4f4f4;
      margin-top: auto;

      @include media-breakpoint-up(md) {
        padding: 25px 30px 10px;
      }

      .footer-navi {
        display: flex;
        flex-direction: column;
        width: 100%;

        li {
          .footer-navi-div {
            display: flex;
            align-items: center !important;
            justify-content: space-between !important;

            a {
              color: $primary;
              text-transform: uppercase;
              display: block;
              width: 100%;
              padding: 8.5px 0;
            }
          }

          &.sublevels {
            .footer-navi-div {
              .arrow {
                &:before {
                  content: "";
                  display: block;
                  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8.333px' height='5px' viewBox='-1.792 5.063 8.333 5' enable-background='new -1.792 5.063 8.333 5' xml:space='preserve'%3E%3Cpolygon id='Triangle-2-Copy-3' fill='"+ encodecolor($primary) +"' points='2.375,10.063 6.541,5.063 -1.791,5.063 '/%3E%3C/svg%3E%0A") no-repeat center center/cover;
                  width: 8px;
                  height: 5px;
                }
              }
            }
          }

          &.open {
            .footer-navi-div {
              .arrow {
                &:before {
                  content: "";
                  display: block;
                  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='8.333px' height='5px' viewBox='-1.792 5.063 8.333 5' enable-background='new -1.792 5.063 8.333 5' xml:space='preserve'%3E%3Cpolygon id='Triangle-2-Copy-3' fill='"+ encodecolor($primary) +"' points='2.375,10.063 6.541,5.063 -1.791,5.063 '/%3E%3C/svg%3E%0A") no-repeat center center/cover;
                  width: 8px;
                  height: 5px;
                  transform: rotate(-180deg);
                }
              }
            }
          }
        }

        ul.level2 {
          list-style-type: disc;
          padding: 0px 20px 10px 30px;

          li {
            list-style-type: disc;
            color: $primary;

            a {
              padding: 7px 0;
              color: $primary;
              display: block;
            }
          }
        }
      }
    }

    &.expanded {
      /*position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      overflow: auto;
      z-index: 1000;
      height: 100%;*/

      .menu-trigger {
        z-index: 50;

        .open-menu {
          opacity: 0;
        }

        .close-menu {
          opacity: 1;
        }
      }
    }
  }

  .header-modal {
    top: 50px;
    right: 20px;
    max-height: calc(100% - 63px);
    position: absolute;
    width: 300px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 720;
    transform: translate(0, -70px);
    transition: transform 0.5s cubic-bezier(0.46, 0.03, 0.52, 0.96), opacity 0.25s cubic-bezier(0.46, 0.03, 0.52, 0.96), visibility 0s linear 1s;

    @include media-breakpoint-up(md) {
      top: 40px;
      right: 10px;
    }
    
    @include media-breakpoint-up(lg) {
      top: 79px;
    }

    /*@media(max-height:450px) {
      overflow: auto;
      height: calc(100vh - 40px) !important;
      max-height: none;
    }*/

    &.is-active {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: translate(0, 0);
      transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.46, 0.03, 0.52, 0.96), visibility 0s linear 0s;
      transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.46, 0.03, 0.52, 0.96), visibility 0s linear 0s, -webkit-transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
    }

    .header-modal-content {
      background-color: #ffffff;
      z-index: 999;
      border-radius: 5px;
      padding: 15px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
      overflow: auto;
      max-height: 84vh;

      @include media-breakpoint-up(md) {
        padding: 20px;
      }

      @include media-breakpoint-up(lg) {
        padding: 30px;
      }

      a:focus {
        color:$green;
      }
    }

    .link {
      font-family: $font-bold;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      color: $primary;
      font-size: 11px;
      line-height: 11px;
      text-decoration: underline;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }

    .btn {
      max-width: 200px;
      width: 100%;
      height: 40px;
      margin: 20px 0 0;

      @include media-breakpoint-up(md) {
        width: auto;
      }

      @include media-breakpoint-down(md) {
        max-width: 100%;
        width: 100%;
      }

      &:hover {
        color: $green;
      }

      &.create-account-btn {
        background: none;
        color: $primary;

        &:before {
          font-size: 16px;
          margin-right: 5px;
        }

        &:hover {
          background: $primary;
          color: #fff;
        }
      }
    }

    h2 {
      color: $primary;
      margin-bottom: 21px;
    }

    .user-email {
      color: $text-primary;
      display: inline-block;
      font-size: 16px;
      margin-bottom: 17px;

      @include media-breakpoint-up(md) {
        font-size: 21px;
      }
    }

    p {
      margin-bottom: 15px;
      color: $gray;
    }

    .validation-failed input {
      box-shadow: none;
      border: 1px solid #d0021b;
      color: #d0021b;
    }

    &.header-modal--login {
      width: 280px;
      
      @include media-breakpoint-up(md) {
        width: 300px;
      }
      
      @include media-breakpoint-up(lg) {
        width: 360px;
        top: 79px;
      }

      form {
        input {
          font-size: 16px;
          &:focus {
            @extend .input-focus;
          }
        }

        label {
          color: #575757;
        }

        .error-label {
          color: #b40007;
          position: relative;
          padding-top: 0;
          font-size: 11px;
          line-height: 0px;
          display: none;
          transition: opacity .33s ease-out,padding .25s ease-out;
          
          &.validated {
            line-height: 11px;
            display: flex;
            transition: opacity .33s ease-out,padding .25s ease-out;
          }
        }

        .create-account,
        a.btn {
          color: $green2;
          background-color: $white;
          
          &:hover,
          &:focus {
            color: #fff!important;
            background: $green2;

            .icon-user-plus {
              background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3e%3cpath fill='%23fff' d='M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'%3e%3c/path%3e%3c/svg%3e") no-repeat center center / contain;
            }
          }
        }

        .invalid-feedback {
          padding-top: 1px;
          color: #b40007;

          &.full {
            padding-top: 5px;
            font-size: 11px;
            line-height: 11px;
            margin: 0;
          }

          &.w-icon {
            align-items: flex-start;
            line-height: 16px;
            &:before {
              content:'';
              display: inline-block;
              width: 15px;
              min-width: 15px;
              height: 15px;
              margin-right: 8px;
              position:relative;
              top: 0px;
              background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%23b40007' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3e%3c/svg%3e") no-repeat center center / contain;
            }
            
            &.show {
              display: flex;
            }
          }
        }
      }
    }

    &.header-modal--logout {
      @include media-breakpoint-up(lg) {
        width: 300px;
        top: 80px;
      }
    }
  }

  .header-modal-title {
    font-family: $font-normal;
    font-size: 23px;
    line-height: 23px;
    margin-bottom: 23px;
    color: $primary;

    @include media-breakpoint-up(md) {
      font-size: 26px;
      line-height: 20px;
    }

    &.login-title {
      font-family: $font-bold;
      font-size: 21px;
      margin: 4px 0 23px;
    }

  }

  .form-group {
    margin-bottom: 16px;
    line-height:1.35;

    label {
      margin: 0 0 10px;
      line-height: 14px;
      color: #000;
      display: block;
    }

    input {
      border: 1px solid #b6b6b6;
    }
  }

  #logged-modal {
    width: 300px;

    #account_avatar {
      width: 40px;
      height: 40px;
      margin-bottom: 18px;
      background-size: cover !important;
      background-repeat: no-repeat !important;

      @include media-breakpoint-up(md) {
        width: 60px;
        height: 60px;
      }
    }

    #account_name {
      margin-top: 21px;
      margin-bottom: 25px;
    }

    #account_num_title {
      color: #575757;
      font-size: 16px;
      line-height: 15px;
    }

    #account_num {
      color: $green;
      font-size: 16px;
      line-height: 16px;
      margin-top: 3px;
      margin-bottom: 10px;
    }

    #account_email {
      color: $text-primary;
      font-size: 16px;
      margin-bottom: 32px;

      @include media-breakpoint-up(md) {
        font-size: 21px;
      }
    }

    .btn {
      margin: 20px 0 0;
    }
  }

  .header-modal-avatar {
    margin-bottom: 18px;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 100%;

    @include media-breakpoint-up(md) {
      width: 60px;
      height: 60px;
    }
  }
}

#system-notification {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  background: #fff;
  z-index: 1;

  .notification {
    .system-notification {
      position: relative;
      padding: 10px 0 9px 69px;

      .icon {
        background: #dadb2b !important;
        width: 50px;
        height: 100%;
        text-align: center;
        position: absolute;
        left: 0px;
        top: 0;

        span {
          display: block;
          width: 20px;
          height: 20px;
          background: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='39.969px' height='39.972px' viewBox='0 0 39.969 39.972' enable-background='new 0 0 39.969 39.972' xml:space='preserve'%3E%3Cg%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%230C8932' d='M39.969,20.127c-0.075,10.937-9.188,19.92-20.126,19.844 C8.907,39.894-0.076,30.783,0,19.843C0.077,8.908,9.189-0.076,20.128,0C31.064,0.078,40.044,9.187,39.969,20.127z M35.927,19.937 C35.899,11.278,28.72,4.087,20.06,4.044C11.409,4.001,4.155,11.13,4.043,19.787c-0.114,8.84,7.28,16.246,16.116,16.143 C28.82,35.829,35.955,28.591,35.927,19.937z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23008433' d='M21.974,23.926c0.001,0.825,0.088,1.661-0.018,2.473 c-0.188,1.435,0.799,3.607-2.035,3.528c-2.805-0.077-1.755-2.247-1.889-3.656c-0.156-1.636-0.117-3.303-0.01-4.946 c0.086-1.315-0.661-3.164,1.778-3.291c2.799-0.145,2.035,1.925,2.161,3.418C22.03,22.271,21.974,23.101,21.974,23.926z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23008433' d='M21.931,12.182c0.092,1.694-1.029,1.782-2.275,1.763 c-1.729-0.028-1.645-1.24-1.613-2.416c0.036-1.351,0.963-1.474,2.027-1.51C21.644,9.965,22.069,10.819,21.931,12.182z'/%3E%3C/g%3E%3C/svg%3E") no-repeat center center / contain !important;
        }
      }

      .content {
        font-size: 12px;
        line-height: 1.33;
        color: #000;
        padding-right: 40px;
      }

      .close {
        opacity: 1;
        top: 50%;
        right: 10px;
        color: $text-secondary;
        transform: translate(0, -50%);
        padding: 0;
        width: 15px;
        height: 15px;
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='15px' height='15px' viewBox='0 0 15 15' enable-background='new 0 0 15 15' xml:space='preserve'%3e%3cg%3e%3cpath fill='none' stroke='%23747474' stroke-width='1.5' stroke-linecap='square' d='M11.743,3.257l-8.486,8.486 M11.743,11.743 L3.257,3.257'/%3e%3c/g%3e%3c/svg%3e") no-repeat center center;

        @include media-breakpoint-up(lg) {
          right: 57px;
        }
      }

      .close:hover {
        opacity: 1;
      }

      .close:before,
      .close:after {
        position: absolute;
        top: 3px;
        left: 8px;
        height: 15px;
        width: 1.5px;
        background-color: $text-secondary;
      }

      .close:before {
        transform: rotate(45deg);
      }

      .close:after {
        transform: rotate(-45deg);
      }
    }
  }
}