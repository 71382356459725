
@media (min-width: 576px) {
  .container-xs {max-width: 540px;	}
  .col-sm-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .container-large {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container-s, .container-xs {max-width: 720px;}
  .container-s {
    max-width: 720px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .col-md-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .container-large {
    max-width: 728px;
  }
}

@media (max-width: 768px) {
  .container-large {
    padding: 0 20px;
  }
}

@media (min-width: 992px) {
  .container-s,
  .container-s-lg {
    max-width: 960px;
  }
  .col-lg-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .container-large {
    max-width: 1000px;
  }
}

@media (min-width: 1200px) {
  .container-s,
  .container-s-lg {max-width: 1024px;}
  .container, .container-sm, .container-md, .container-xl {
    max-width: 1170px;
  }
  .container-lg {
    max-width: 1200px;
    padding-right: 10px;
    padding-left: 10px;

    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  .container-large {
    max-width: 1200px;
  }
}

@media (max-width: 991.98px) {
  .navigation-container {
    background-color: $primary;
    z-index: -1;
    height: 100%;
    left: 0;
    line-height: 1.5;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    padding: 60px 0px 0;
    transition: opacity 200ms cubic-bezier(.455,.03,.515,.955);
  }

  header {
    &.expanded {
      .navigation-container {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .sub-navigation-container {
    .container-s {
      max-width: 100%;
      padding: 0 20px;
    }
  }
}

@media only screen and (max-width: 990.98px) {
  #main.single-ticket .ticket-banner .background {
    background: none !important;
  }

}

@media only screen and (max-width: 575.98px) {
  #main.all-results {
    .btn.check {
      width: 100%;
    }

    .gradient {
      img {
        height: 26px;
        width: auto;
      }
    }

    .game-ejp, .game-viking {
      .gradient {
        img {
          height: 37px;
        }
      }
    }

    .game-novoletniloto {
      .gradient {
        img:not(.gift) {
          height: 42px;
        }
      }
    }

    .game-tikitaka {
      .gradient {
        img {
          height: 52px;
        }
      }
    }
  }
}

.btn {
  @include media-breakpoint-up(md) {
    min-width: 200px;
    width: auto;
  }
}

.img-none {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

