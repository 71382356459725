.partnerska-mreza {
  #partnerska-visual {
    position: relative;
    padding: 40px 0;

    &.partner-loto {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(208, 2, 27, 0.8);
      }
    }

    &.partner-novoletniloto {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 70, 27, 0.8);
        background: rgba(0, 122, 47, 0.8);
      }
    }

    &.partner-eurojackpot {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 209, 90, 0.8);
      }
    }

    &.partner-vikinglotto {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 255, 0.8);
      }
    }

    &.partner-tikitaka {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(16, 91, 144, 0.8);
      }
    }

    &.partner-threexthree {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(196, 0, 122, 0.8);
      }
    }

    &.partner-srecke {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $green;
      }
    }

    .results {
      p {
        color: $white;
        font-size: 14px;
      }

      .select2-container--default .select2-selection--single {
        background-color: transparent;
        border: none;

        .select2-selection__rendered {
          display: block;
          padding-left: 0;
          padding-right: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 27px;
          color: $white;
          font-family: $font-bold;
        }

        .select2-selection__arrow {
          width: 12px;
          height: 7px!important;
          top: 15px!important;
          right: -20px!important;

          b {
            border-color: $white transparent transparent transparent;
            border-width: 7px 6px 0 6px;
          }
        }
      }
    }
  }

  #report {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding: 27px;
    margin-left: 0;
    margin-right: 0;

    .results-report {
      i {
        font-size: 24px;
      }

      span {
        font-size: 16px;
      }
    }
  }
}

/*
.modal-dialog {
  max-width: 97% !important;
  width: 100%;
  height: 95%;
  padding: 0;
}*/

.modal-content {
  height: 100%;
  border-radius: 0;

  iframe {
    width: 100%;
    height: 100%;
  }
}
