@import "header-partnerska";
@import "footer-partnerska";
@import "content";
@import "results";
@import "funds";

body.partnerska-mreza {
  background-color: #f9f9f9;

  .content {
    background: $white;
  }

  .text-secondary-add {
    font-size: 14px;
  }

  #pdfs {
    margin-bottom: 47px;

    a {
      font-family: $font-normal;
      font-size: 16px;
      color: $text-primary;

      &:before {
        font-size: 24px;
      }
    }
  }
}

.accordion-container {
  h2 {
    font-size: 26px;
    font-family: $font-bold;
    line-height: 30px;

    @include media-breakpoint-up(md) {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 46px;
    }
  }

  .accordion-suptitle {
    font-family: $font-bold;
    color: $primary;
    margin: 40px 0 10px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .31px;
    h2 {
      font-family: $font-bold;
      color: $primary;
      margin: 40px 0 10px;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: .31px;
    }
  }

  .accordion {
    margin-top: 30px;
    margin-bottom: 30px;

    .acc-element {
      border: none;
      border-bottom: 1px solid #e1e1e1;

      .acc-element-header {
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;

        .acc-element-title {
          padding: 11px 0px 12px 0px;          
          margin: 0;
          
          @include media-breakpoint-up(md) {
            padding: 11px 20px 12px 0px;
          }

          button {
            border: none;
            background:none;
            padding: 0;
            font-size: 16px;
            font-family: $font-normal;
            color: $primary;
            text-decoration: none;
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
              font-size: 20px;
            }

            .icon {
              width: 20px;
              height: 20px;
              position: relative;
              display: inline-block;
              cursor: pointer;
              
              &:after {
                content: ' ';
                position: absolute;
                top: 4px;
                right: 9px;
                height: 12px;
                width: 2px;
                background-color: $primary;
                transform: rotate(-90deg);
              }
            }

            &.collapsed {
              color: $text-primary;

              .icon {
                &:before, &:after {
                  content: ' ';
                  position: absolute;
                  top: 4px;
                  right: 9px;
                  height: 12px;
                  width: 2px;
                  background-color: $primary;
                }
  
                &:after {
                  transform: rotate(-90deg);
                }
              }
            }
          }
        }
      }

      .acc-element-body {
        padding: 5px 20px 20px 0;
        font-size: 16px;
        line-height: 26px;

        @include media-breakpoint-up(md) {
          padding-right: 60px;
        }

        ul {
          padding-left: 20px;
        }

        
        .dobitki {
          width: 100% !important;
          margin: 0;
          
          @include media-breakpoint-up(md) {
            width: 84% !important;
            margin: 0 8%;
          }
        }

        .content-wrapper {          
          width: 100%;
          @include media-breakpoint-up(md) {
            width: 60%;
          }
        }

        .dobitki {
          width: 100% !important;
          margin: 0;
          
          @include media-breakpoint-up(md) {
            width: 84% !important;
            margin: 0 8%;
          }
        }

        .nav-pills {
          padding: 0;
          margin-bottom: 20px;

          .nav-item {
            .nav-link {
              background: #fff;
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
              color: $primary;
              border-radius: 0;
              padding: 15px;

              @include media-breakpoint-up(lg) {
                height: 110px;
                padding: 30px;
              }

              &.active {
                background: $primary;
                color: #fff;

                @include media-breakpoint-up(lg) {
                  &:after {
                    content: "";
                    border-left: 13px solid transparent;
                    border-right: 13px solid transparent;
                    border-bottom: 13px solid $primary;
                    position: absolute;
                    bottom: -20px;
                  }
                }
              }
            }
          }
        }

        .tab-content {
          .tab-pane {
            background: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
            color: $primary;
            border-radius: 0;
            padding: 30px;
            font-size: 14px;

            &.active {
              background: $primary;
              color: #fff;
            }
          }
        }
      }

      &:nth-last-of-type(1) {
        border-bottom: none;
      }
    }
  }
}
