@font-face {
  font-family: "Facit";
  font-display: swap;
  src: url("./fonts/facit/Facit-Regular.eot");
  src: url("./fonts/facit/Facit-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/facit/Facit-Regular.woff2") format("woff2"),
    url("./fonts/facit/Facit-Regular.woff") format("woff"),
    url("./fonts/facit/Facit-Regular.ttf") format("truetype"),
    url("./fonts/facit/Facit-Regular.svg#Facit-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Facit Bold";
  font-display: swap;
  src: url("./fonts/facit/Facit-Bold.eot");
  src: url("./fonts/facit/Facit-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/facit/Facit-Bold.woff2") format("woff2"),
    url("./fonts/facit/Facit-Bold.woff") format("woff"),
    url("./fonts/facit/Facit-Bold.ttf") format("truetype"),
    url("./fonts/facit/Facit-Bold.svg#Facit-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Facit Semibold";
  font-display: swap;
  src: url("./fonts/facit/Facit-Semibold.woff2") format("woff2"),
    url("./fonts/facit/Facit-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Assets";
  font-display: swap;
  src: url("./fonts/assets/assets.eot");
  src: url("./fonts/assets/assets.eot?#iefix") format("embedded-opentype"),
    url("./fonts/assets/assets.woff2") format("woff2"),
    url("./fonts/assets/assets.woff") format("woff"),
    url("./fonts/assets/assets.ttf") format("truetype"),
    url("./fonts/assets/assets.svg#assets") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raph Lanok Future';
  src: url('./fonts/RaphLanokFuture/RaphLanokFuture.eot');
  src: url('./fonts/RaphLanokFuture/RaphLanokFuture.eot?#iefix') format('embedded-opentype'),
  url('./fonts/RaphLanokFuture/RaphLanokFuture.woff2') format('woff2'),
  url('./fonts/RaphLanokFuture/RaphLanokFuture.woff') format('woff'),
  url('./fonts/RaphLanokFuture/RaphLanokFuture.ttf') format('truetype'),
  url('./fonts/RaphLanokFuture/RaphLanokFuture.svg#RaphLanokFuture') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* roboto-slab-200 - latin-ext_latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-200.eot'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-200.woff2') format('woff2'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-200.woff') format('woff'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-200.ttf') format('truetype'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-200.svg#RobotoSlab') format('svg');
}

/* roboto-slab-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-regular.eot'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-regular.woff2') format('woff2'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-regular.woff') format('woff'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-regular.ttf') format('truetype'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-regular.svg#RobotoSlab') format('svg');
}
/* roboto-slab-600 - latin-ext_latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-600.eot'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-600.woff2') format('woff2'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-600.woff') format('woff'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-600.ttf') format('truetype'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-600.svg#RobotoSlab') format('svg');
}
/* roboto-slab-700 - latin-ext_latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-700.eot'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-700.woff2') format('woff2'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-700.woff') format('woff'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-700.ttf') format('truetype'),
  url('./fonts/Roboto-slab/roboto-slab-v12-latin-ext_latin-700.svg#RobotoSlab') format('svg');
}

$font-normal: 'Facit', Arial, sans-serif;
$font-bold: 'Facit Bold', Arial, sans-serif;
$font-semibold: 'Facit Semibold', Arial, sans-serif;
$font-assets: 'Assets';
$font-raph: 'Raph Lanok Future', cursive;
$font-roboto: 'Roboto Slab', Arial, sans-serif;
