footer#footer-partnerska {
  background: $primary;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 100;

  a {
    color: #fff;
    opacity: 1;
    transition: opacity 0.3s ease;

    &:hover {
      color: #fff;
      opacity: 0.8;
    }
  }

  .main {
    border: none;
    padding: 20px 0 0;

    @include media-breakpoint-up(lg) {
      padding: 20px 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 48px 0 0;
    }

    address {
      line-height: 1.7;
      margin-bottom: 5px;
    }

    .support {
      p {
        margin-bottom: 5px;

        a {
          i {
            width: 14px;
            height: 14px;
            display: inline-block;
            top: 1px;
            position: relative;
            margin-right: 4px;

            &.icon-phone {
              background: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='9px' height='14px' viewBox='0 0 9 14' enable-background='new 0 0 9 14' xml:space='preserve'%3e%3cg%3e%3cpath fill='%23FFFFFF' d='M7.042,0.583H2.375c-0.805,0-1.458,0.654-1.458,1.459v9.916c0,0.806,0.653,1.459,1.458,1.459h4.667 c0.806,0,1.458-0.653,1.458-1.459V2.042C8.5,1.237,7.848,0.583,7.042,0.583z M4.708,12.833c-0.483,0-0.875-0.392-0.875-0.875 s0.392-0.875,0.875-0.875s0.875,0.392,0.875,0.875S5.191,12.833,4.708,12.833z M7.333,10.5h-5.25V2.333h5.25V10.5z'/%3e%3cpath fill='none' d='M-2,0h14v14H-2V0z'/%3e%3c/g%3e%3c/svg%3e") no-repeat center center / contain;
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }

  .copyright {
    font-size: 9px;
    padding: 12px 15px;
    left: -15px;
    position: relative;
    width: calc(100% + 30px);
    border: none;

    &:before {
      content: "";
      display: block;
      width: 52px;
      height: 1px;
      background: rgba(255,255,255,0.5);
      position: absolute;
      top: 0;
    }

    @include media-breakpoint-up(md) {
      font-size: 12px;
      padding: 16px 0;
      left: auto;
      width: auto;
    }

    .cr {
      margin-right: 2px;
    }

    .copyright-links {
      span {
        width: 2px;
        height: 12px;
        background: rgba(255, 255, 255, 0.7);
        display: inline-block;
        margin: 0 4px;
        position: relative;
        top: 3px;
      }
    }
  }
}