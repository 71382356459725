#main.hip-igre-page {
  background: #000a1e;

  .inner-visual {
    overflow: initial !important;

    &:before {
      display: none !important;
    }

    .element {
      display: flex;
      align-items: center;
      justify-content: center;

      .image {
        height: 256px;
        -webkit-mask-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_2_00000095336518466620867400000012600676148865049737_' x='0px' y='0px' width='1849px' height='190.1px' viewBox='0 0 1849 190.1' style='enable-background:new 0 0 1849 190.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23000A1E;%7D%0A%3C/style%3E%3Cpath class='st0' d='M1849,0.7v189.6H0V0C0,0,845.3,417.6,1849,0.7z'/%3E%3C/svg%3E"),linear-gradient(#000 0 0);
        mask-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_2_00000095336518466620867400000012600676148865049737_' x='0px' y='0px' width='1849px' height='190.1px' viewBox='0 0 1849 190.1' style='enable-background:new 0 0 1849 190.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23000A1E;%7D%0A%3C/style%3E%3Cpath class='st0' d='M1849,0.7v189.6H0V0C0,0,845.3,417.6,1849,0.7z'/%3E%3C/svg%3E"),linear-gradient(#000 0 0);
        -webkit-mask-position: calc(100% + 1px) 0;
        mask-position: calc(100% + 1px) 0;
        -webkit-mask-position-y: calc(100% + 1px);
        -webkit-mask-size: 100vw auto;
        mask-size: 100vw auto;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        background: linear-gradient(#000 0 0);
    
        @include media-breakpoint-down(sm) {
          -webkit-mask-size: calc(200% + 1px) auto;
          mask-size: 200% auto;
          -webkit-mask-position-y: calc(100% + 1px);
          -webkit-mask-position-x: 50%
        }

        @include media-breakpoint-up(md) {
          height: 363px;
        }

        img {
          min-height: 256px;
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }
      }

      .content {
        position: absolute;
        bottom: auto;
        top: 46px !important;
        @include media-breakpoint-up(md) {
          top: 91px !important;
        }
        
        .title {
          margin-bottom: 25px;
          font-size: 32px;
          @include media-breakpoint-up(md) {
            font-size: 52px;
            margin-bottom: 27px;
          }
        }
      }

      .sub-navigation-container {
        box-shadow: none !important;

        .container-s {
          padding: 0;

          @include media-breakpoint-up(md) {
            padding: 0 20px;
          }
        }

        .navbar-nav {
          background: rgba(13, 23, 52, 0.60);
          background:#080613;
          backdrop-filter: blur(7px);
          border-radius: 32px;
          border: 1px solid #525776;
          height: 39px;

          @include media-breakpoint-up(md) {
            gap: 16px;
            height: auto;
            border: none;
            background: none;
            backdrop-filter: none;
          }
        }

        .nav-item {
          margin-right: 0;

          .nav-link {
            padding: 9px 12px 12px;
            letter-spacing: 0;
            font-size: 14px;
            line-height: normal;
            font-family: $font-normal;
            font-family: $font-semibold;
            color: #BDC0D1;
            position: relative;
            text-transform: initial;
            white-space: nowrap;

            @media screen and (min-width: 386px) {
              padding: 9px 16px 12px;
              font-size: 16px;
            }

            @include media-breakpoint-up(md) {
              border-radius: 32px;
              background: rgba(13, 23, 52, 0.60);
              backdrop-filter: blur(7px);
              border: 1px solid #ffffff;
              padding: 14px 32px;
              color: #FFFFFF;
            }

            &::after {
              display: none;
            }

            &::before {
              position: absolute;
              bottom: -7px;
              left: calc(50% - 15px);

              width: 30px;
              height: 7px;
              content: '';
              display: none;
              background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='31' height='7' viewBox='0 0 31 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 0H31C31 0 26.7613 0.18895 24.6176 0.941176C21.0597 2.18967 17.0588 6.11765 17.0588 6.11765C17.0588 6.11765 16.31 7 15.5 7C14.69 7 13.9412 6.11765 13.9412 6.11765C13.9412 6.11765 9.9403 2.18967 6.38235 0.941176C4.23867 0.18895 0 0 0 0Z' fill='%230A8137'/%3e%3c/svg%3e") no-repeat center center / contain;

              @include media-breakpoint-up(md) {
                background: url("data:image/svg+xml,%3Csvg width='32' height='7' viewBox='0 0 32 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0H31.5C31.5 0 27.2613 0.18895 25.1176 0.941176C21.5597 2.18967 17.5588 6.11765 17.5588 6.11765C17.5588 6.11765 16.81 7 16 7C15.19 7 14.4412 6.11765 14.4412 6.11765C14.4412 6.11765 10.4403 2.18967 6.88235 0.941176C4.73867 0.18895 0.5 0 0.5 0Z' fill='%234BB26D'/%3E%3C/svg%3E%0A") no-repeat center center / cover;
              }
            }

            &.current {
              background: rgb(0, 122, 47);
              border: none;
              border-radius: 32px;
              height: 39px;
              right: -1px;
              color: #fff;

              @include media-breakpoint-up(md) {
                background: linear-gradient(180deg, #007A2F 0%, #4BB26D 100%);
                line-height: normal;
              }

              @include media-breakpoint-up(md) {
                height: auto;
                padding: 15px 32px;
                right: 0;
              }

              &::before {
                display: inline-block !important;
              }
            }

            span {
              position:relative;
              &:after {
                content: "";
                width: 0;
                height: 2px;
                background-color: #fff;
                bottom: -2px;
                left: 50%;
                position: absolute;
                transition: .25s cubic-bezier(.455,.03,.515,.955);
                transform-origin: center;
              }
            }
            
            &:hover {
              @include media-breakpoint-up(md) {
                background-color: rgba(255,255,255,.3);
              }

              span {
                &:after {
                  width: 100%;
                  left: 0;
                }
              }
            }
          }
        }
      }
    }

    .elipse {
      &:before {
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 340px;
        z-index: -1;
        margin: auto;
        aspect-ratio: 1250/570;
        width: 100%;
        max-width: 1360px;
        content: "";
        background: url("data:image/svg+xml,%3Csvg width='1440' height='559' viewBox='0 0 1440 559' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_575_11441)'%3E%3Cellipse cx='720' cy='279.5' rx='625' ry='85.5' fill='%233B4580'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_575_11441' x='-99' y='0' width='1638' height='559' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='97' result='effect1_foregroundBlur_575_11441'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / 90% auto;
      
        @include media-breakpoint-up(md) {
          bottom:0;
          top: auto;
          --tw-translate-y: 50%;
          transform: translate(0,50%) rotate(0) skew(0) skewY(0) scaleX(1) scaleY(1);
        }
      }
      
    }
  }

  .bg-game-spletne-igre {
    background: none;
    padding-bottom: 48px;
    position: relative;
    z-index: 200;

    @include media-breakpoint-up(md) {
      padding-bottom: 60px;
    }

    .game-about {
      h2 {
        font-size: 26px;
        line-height: normal;
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
          font-size: 32px;
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 16px;
        line-height: 21.6px;
      }

      p,
      a {
        color: #bdc0d1;
      }

      .row {
        position: relative;
        z-index: 10;


        .col-sm-12 {
          margin-bottom: 48px;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .half-w-images {
        .images {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 250px;

          .single-image {
            position: relative;
            padding: 1px;
            border-radius: 16px;
            display: inline-block;
            background: rgba(0, 10, 30, 0.69);
            backdrop-filter: blur(4px);
            position: absolute;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: 16px;
              padding: 5px;
              /* This will act as the border width */
              background: linear-gradient(223.28deg, #6270AD 0.04%, rgba(98, 112, 173, 0) 18.4%, rgba(98, 112, 173, 0.62) 39.89%, rgba(98, 112, 173, 0.7) 62.9%, rgba(98, 112, 173, 0) 82.63%, #6270AD 97.3%);
              -webkit-mask-composite: destination-out;
            }

            .background {
              background: rgba(0, 10, 30, 1);
              backdrop-filter: blur(4px);
              padding: 10px;
              content: '';
              position: relative;
              border-radius: 16px;
            }

            img {
              transform: none;
              left: 0;
              border-radius: 16px;
            }

            .content {
              text-align: center;
              background: rgba(15, 16, 21, 0.80);
              backdrop-filter: blur(2px);
              position: absolute;
              bottom: 10px;
              width: 100%;
              padding: 10px;
              border-bottom-left-radius: 16px;
              border-bottom-right-radius: 16px;

              .title {
                color: #fff;
                font-size: 18px;
                text-align: center;
                font-family: $font-bold;
                line-height: normal;
                margin-bottom: 8px;
                display: block;
              }

              .buttons {
                gap: 10px;

                .hearth {
                  width: 44px;
                  height: 44px;
                  content: '';
                  display: inline-block;
                  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44' fill='none'%3E%3Crect x='0.5' y='0.5' width='43' height='43' rx='21.5' stroke='%23BDC0D1'/%3E%3Cpath d='M20.9102 29.7383L20.8398 29.6328L14.6875 23.9375C13.5977 22.918 13 21.5117 13 20.0352V19.9297C13 17.4688 14.7578 15.3242 17.1836 14.8672C18.5547 14.5859 19.9609 14.9023 21.1211 15.7109C21.4375 15.9219 21.7188 16.2031 22 16.4844C22.1406 16.3438 22.2812 16.168 22.457 16.0273C22.5977 15.9219 22.7383 15.8164 22.8789 15.7109C24.0039 14.9023 25.4102 14.5859 26.7812 14.8672C29.2422 15.3242 31 17.4688 31 19.9297V20.0352C31 21.5117 30.3672 22.918 29.2773 23.9375L23.125 29.6328L23.0547 29.7383C22.7734 29.9844 22.3867 30.1602 22 30.1602C21.5781 30.1602 21.2266 29.9844 20.9102 29.7383ZM21.4023 18.3477C21.3672 18.3477 21.3672 18.3477 21.3672 18.3125L20.7344 17.6094C19.9258 16.6953 18.6953 16.3086 17.5 16.5195C15.8477 16.8359 14.6875 18.2773 14.6875 19.9297V20.0352C14.6875 21.0547 15.0742 22.0039 15.8125 22.6719L22 28.4023L28.1523 22.6719C28.8906 22.0039 29.3125 21.0547 29.3125 20.0352V19.9297C29.3125 18.2773 28.1172 16.8359 26.4648 16.5195C25.2695 16.3086 24.0391 16.6953 23.2305 17.6094L22.5977 18.3125C22.5977 18.3477 22.5977 18.3477 22.5625 18.3828C22.4219 18.5234 22.2109 18.6289 22 18.6289C21.7539 18.6289 21.543 18.5234 21.4023 18.3828V18.3477Z' fill='%23BDC0D1'/%3E%3C/svg%3E") no-repeat center center / cover;
                }

                .play {
                  font-size: 14px;
                  line-height: normal;
                  font-family: $font-bold;
                  color: #fff;
                  padding: 7px 20px;
                  border-radius: 40px;
                  background: #007a2f;
                  display: flex;
                  align-items: center;
                }
              }
            }

            &:first-of-type {
              transform: rotate(-7deg) translate(-73px);
            }

            &:last-of-type {
              transform: rotate(5deg) translate(73px);
            }
          }
        }
      }
    }
  }

  .main-game-content {
    //background: #000a1e;

    #account-advantages {
      position: relative;
      padding: 1px;
      border-radius: 16px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 16px;
        padding: 5px;
        /* This will act as the border width */
        background: linear-gradient(223.28deg, #6270AD 0.04%, rgba(98, 112, 173, 0) 18.4%, rgba(98, 112, 173, 0.62) 39.89%, rgba(98, 112, 173, 0.7) 62.9%, rgba(98, 112, 173, 0) 82.63%, #6270AD 97.3%);
        -webkit-mask-composite: destination-out;
      }

      .elipse {
        object-fit: cover;
        width: 100%;
        height: auto;
        position: absolute;
        top: -65px;
        left: 0;
        z-index: 0;

        @include media-breakpoint-up(sm) {
          top: -95px;
        }

        @include media-breakpoint-up(md) {
          top: -130px;
        }

        @include media-breakpoint-up(lg) {
          top: -165px;
        }
      }

      .advantages-wrapper {
        background: #000a1e;
        padding: 10px;
        content: '';
        position: relative;
        border-radius: 16px;
      }

      .wrapper {
        background: #007a2f;
        padding: 40px 10px;
        border-radius: 16px;
        z-index: 1;
        position: relative;

        .row {
          padding-left: 12px;
          padding-right: 12px;

          @include media-breakpoint-up(md) {
            padding-left: 30px;
            padding-right: 30px;
          }
        }

        .advantages-svg {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: auto;
        }
      }

      h2 {
        font-size: 32px;
        line-height: normal;
        font-weight: 700;
        margin-bottom: 27px;
        color: #eceffb;
      }

      .element {
        color: #ffffff;
        margin-bottom: 27px;

        h2 {
          font-size: 20px;
          line-height: normal;
          margin-bottom: 6px;
        }

        .description {
          font-size: 14px;
          line-height: normal;
          color: #ffffff;
        }

        .icon {
          background: none;
          width: auto;
          height: auto;
          border-radius: unset;
          margin-right: 18px;
        }
      }

      .btn-round {
        color: #007a2f;
        font-size: 14px;
        line-height: normal;
        font-family: $font-bold;
        padding: 12px 20px;
        background: #ffffff;
        border-radius: 40px;
        text-decoration: none !important;
        display: block;
        width: auto;
        height: auto;
      }
    }


    .accordion-container {
      padding-top: 48px;
      padding-bottom: 48px;

      @include media-breakpoint-up(md) {
        padding-top: 60px;
        padding-bottom: 60px;
      }

      h2 {
        margin: 0;
        color: #eceffb;
        font-size: 32px;
        line-height: normal;
        margin-bottom: 15px;
      }

      .accordion {
        margin: 0;

        .accordion-suptitle {
          margin-top: 48px;
        }

        .acc-element {
          border-radius: 16px;
          border: 1px solid #BDC0D1;
          margin-bottom: 15px;

          .acc-element-title {
            padding: 0;
            margin: 0;

            button {
              padding: 19px 16px 18px;
              color: #eceffb;
              font-family: $font-semibold;
              font-size: 16px;
              line-height: normal;

              .icon {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;

                &::before {
                  display: none;
                }

                &:after {
                  width: 10px;
                  height: 10px;
                  background: none;
                  border-bottom: 2px solid #bdc0d1;
                  border-right: 2px solid #bdc0d1;
                  top: 4px;
                  left: auto;
                  right: auto;
                  transform: rotate(45deg);
                  transition: all 0.3s ease;
                }
              }

              &:not(.collapsed) {
                .icon {
                  &::after {
                    transform: rotate(-135deg);
                  }

                }
              }
            }
          }

          .acc-element-body {
            padding-left: 16px;
            padding-right: 16px;
            color: #BDC0D1;

            caption {
              color: rgb(189, 192, 209);
            }

            .table {
              color: #BDC0D1;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .table {
          margin-bottom: 0;
        }

        table.veljavnost-iger {
          margin-bottom: 0;

          @media screen and (max-width: 768px) {
            display: block;

            caption,
            tbody,
            th,
            td,
            tr {
              display: block;
            }

            thead {
              display: none;
            }

            tr {
              margin-bottom: 12px;
              border-bottom: 1px solid #bdc0d1;
              padding: 0 0 12px;

              td {
                text-align: right !important;
                padding: 5px 0;
                border: none;
                width: 100% !important;
                display: flex;
                //justify-content: space-between;
                align-items: flex-end;

                &:first-child {
                  font-family: $font-bold;
                }
              }

              td:not(:first-child):before {
                content: attr(data-label);
                display: inline-block;
                text-align: left !important;
                width: 69%;
              }
            }
          }
        }
      }
    }
  }
}