body.museum {
  background:#fff;
}

#main.muzej {
  #main-content {
    @include media-breakpoint-up(md) {
      padding-top: 80px;
    }

    .btn {
      width: auto;
    }
    
    h1 {
      color:$primary;
    }
    
    h2 {
      font-size: 23px;
      
      @include media-breakpoint-up(md) {
        font-size: 25px;
      }
    }

    h3 {
      font-size: 23px;
      font-family: $font-bold;
      
      @include media-breakpoint-up(md) {
        font-size: 24px;
      }
    }
    
    strong {
      color:$primary;
    }
  }
  .container-xs {
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
    padding: 0;
    padding-bottom: 44px;
    padding-bottom: 72px;
  }

  em {
    font-family:$font-semibold;
    font-size: 14px;
    letter-spacing: .7px;
    font-style: normal;
    display: inline-block;
    margin-bottom: 20px;
  }

  .content-edit {
    margin-bottom: 0px;
  }

  .content-inner {
    margin-bottom: 44px !important;
    padding-bottom: 0 !important;
    @include media-breakpoint-up(md) {
      margin-bottom: 72px !important;
    }
    
    > .row {
      @include media-breakpoint-up(md) {
        align-items:center;
      }
    }

    .image-container {
      display:block !important;
      margin-bottom: 24px;
      
      @include media-breakpoint-down(sm) {
        order: 1;
      }
      
      @include media-breakpoint-up(md) {
        margin-bottom: 0px;
        flex: 0 0 60%;
      }
      @include media-breakpoint-up(xl) {
        flex: 0 0 789px;
        max-width: 789px;
      }
    }    
    
    #vsebina {
      width: 100%;

      @include media-breakpoint-down(sm) {
        order: 2;
      }
      @include media-breakpoint-up(lg) {
        max-width: 363px;
      }
    }
  }
  
  .top-content {
    padding-bottom: 80px;
    @include media-breakpoint-up(md) {
      padding-bottom: 120px;
    }
    
    .container-xs {
      margin: 0;
      @include media-breakpoint-up(lg) {
        width: calc(100% - 500px);
      }
      @include media-breakpoint-up(xl) {
        width: 100%;
      }
    }

    .top-img-boy {
      position: relative;
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto;

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0px;
        right: -2px;
      }
    }
  }

  .white-content {
    position: relative;
    padding-bottom: 100px;

    em {
      color: #00A851;
    }
    
    &:before {
      @include media-breakpoint-up(xl) {
        content:'';
        position:absolute;
        top: 125px;
        left: -148px;
        display: block;
        width: 415px;
        height: 346px;
        background: url("/img/muzej/deteljica.png") no-repeat center center / contain;
        transform: rotate(243deg);
      }
    }

    &:after {
      @include media-breakpoint-up(xl) {
        content:'';
        position:absolute;
        top: -20px;
        right: -2px;
        display: block;
        width: 300px;
        height: 601px;
      }
    }

    .img-deteljica {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        position: absolute;
        right: -66px;
        top: 1046px;
        transform: rotate(-37deg);
      }
    }

    .mobile-img-container {
      display: block;
      @include media-breakpoint-up(md) {
        display: none;
      }
      .mobile-img {
        display: block;
        text-align:right;
      }
    }
  }
  
  .green-content {
    background:$primary;
    position:relative;
    
    h1, h2, h3, h4, p, a {
      color:#fff;
    }

    .container-xl {
      margin-top: -35px;
      @include media-breakpoint-up(sm) {
        margin-top: -55px;
      }
      @include media-breakpoint-up(md) {
        margin-top: -65px;
      }
      @include media-breakpoint-up(lg){
        margin-top: -140px;
      }
      @include media-breakpoint-up(xl){
        margin-top: -170px;
      }
    }
  }
  
  .media-content {
    padding-top: 44px;
    padding-bottom: 60px;
    @include media-breakpoint-up(md) {
      padding-top: 67px;
    }
    .container-xl { 
      position:relative; 
      .btn {
        padding: 0 20px;
        margin: 0 0 24px;
        text-transform: none;
        @include media-breakpoint-up(sm) {
          margin: 0 24px 0 0;
        }
      }

      .img-deteljica {
        transform: rotate(187deg);
        @include media-breakpoint-up(lg) {
          transform: none;
          position: absolute;
          right: 0;
          top: -150px;
        }
      }
    }
  }
}

img.top-rect {
  position: relative;
  top: 3px;
  width: 100%;
}

img.bottom-rect {
  position: relative;
  top: 3px;
  width: 100%;
}

.bottom-patern,
.top-patern {
  position: relative;
  transform: translate(0px, -74%);
  z-index: 300;
  
  @include media-breakpoint-up(lg) {
    transform: translate(0px, -62%);
    
  }

  .img-patern {
    display: block;
    margin: 0 auto;
  }
}

.museum-gallery-container {
  position:relative;
  background:$primary;
  padding-bottom: 53px;
  margin-bottom: 65px;
  color:#fff;
  @include media-breakpoint-up(md) {
    padding-bottom: 112px;
    margin-bottom: 86px;
  }
  
  .gallery-inner {
    margin-top: -18%;
    
    @media screen and (min-width: 1400px) {
      margin-top: -14%;
    }

    @media screen and (min-width: 1800px) {
      margin-top: -12%;
    }

    @media screen and (min-width: 2000px) {
      margin-top: -10%;
    }   
  }
  
  .splide__list {
    transition: transform 1500ms cubic-bezier(0.25, 1, 0.5, 1);
  }
  
  .splide__slide {
    transition: transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    @include media-breakpoint-up(md) {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 36% 60%;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: 30% 67%;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: 265px 840px;
    }
    
    .content {
      margin-bottom: 32px;
      
      @include media-breakpoint-up(md) {
        margin-bottom: 0px;
        padding-top: 50px;
        padding-left: 10px;
      }
      h3 {
        font-size: 22px;
        margin-bottom: 20px;
        font-family:$font-bold;
    
        @include media-breakpoint-up(md) {
          font-size: 24px;
          margin-bottom: 24px;
        }
      }
      
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  
  .splide__arrows {
    position: relative;
    display: flex;
    justify-content:center;
    margin-top: 32px;
    
    @include media-breakpoint-down(sm) {
      top: auto !important;
    }

    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      left: auto;
      right: 60%;
      margin-top: 0px;
    }

    @include media-breakpoint-up(lg) {
      right: 67%;
    }

    @include media-breakpoint-up(xl) {
      right: 840px;
    }

    svg {
      display: none;
    }

    .splide__arrow {
      width: 48px;
      height: 48px;
      border: 1px solid #fff;
      background: none;
      opacity: 1;
      display: flex;
      align-items:center;
      justify-content: center;
      position:relative;
      left: auto;
      right: auto;
      bottom: auto;
      top: auto;
      transform: none;
      margin-right: 20px;
      
      @include media-breakpoint-up(md) {
        position: absolute;
        top: auto;
        margin-right: 0px;
      }

      &:after {
        content:'';
        width: 14px;
        height: 14px;
        margin:0 0 0 4px;
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        transform: rotate(-45deg);
      }
      
      &.splide__arrow--next {
        @include media-breakpoint-up(md) {
          left: 68px;
        }
        &:after {
          margin:0 4px 0 0;
          border-left: none;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
        }
      }
    }
  }
  
  .slide-counter {
    position: relative;
    font-family:$font-semibold;
    font-size: 22px;
    z-index: 10;
    @include media-breakpoint-up(md) {
      position: absolute;
      top: -7px;
      left: 10px;
    }
  }
}