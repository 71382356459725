
ul.list-unstyled,
ul.list-unstyled ul {
  list-style: none;
}

ul.list-unstyled li,
ul.list-unstyled ul li {
  margin: 0;
}

.cat-list {
  display: table;
}

ul.cat-list li {
  display: table;
  padding: 5px 0;
}

ul.cat-list li:before {
  content: '\2013';
  display: table-cell;
  color: #e03c2f;
  font-size: 16px;
  padding-right: 7px;
}

ul.cat-list li:hover:before {
  color: #414042;
}

.cat-list li i {
  display: none;
}

ol#clusterInfoWindow {
  list-style: none;
  counter-reset: my-awesome-counter;
  padding: 0;
  margin: 0;
  
  li {
    counter-increment: my-awesome-counter;
    margin-bottom: 10px;
  }
}

ol#clusterInfoWindow li::before {
  content: counter(my-awesome-counter) ". ";
  font-weight: bold;
  color: white;
  background: #C60000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  margin-right: 10px;
}

ol#clusterInfoWindow li.fso_pd:before {
  background: #006FBB;
}
ol#clusterInfoWindow li.fso_pr:before {
  background: #13602B;
}
ol#clusterInfoWindow li.fso_ob:before {
  background: #782380;
}
ol#clusterInfoWindow li.fiho_io:before {
  background: #FFC000;
}
ol#clusterInfoWindow li.fiho_ho:before {
  background: #B15300;
}

/* test */
.clusterFiho {
  font-weight: bold;
  color: white;
  background: #003802;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  margin-right: 10px;
}

.white {
  color: #ffffff;
}

.gray {
  color: #f5f5f6;
}

.red {
  color: #e03c2f;
}

.brown {
  color: #414042;
}

.black {
  color: #000000;
}

.bg-white {
  background: #ffffff;
}

.bg-gray {
  background: #f5f5f6;
}

.bg-red {
  background: #e03c2f;
}

.bg-brown {
  background: #414042;
}

.bg-black {
  background: #000000;
}

/*==========================================================================================================================================
                PADDING
  =========================================================================================================================================*/
.padding-60-0 {
  padding: 60px 0;
}

.padding-30-0 {
  padding: 30px 0;
}

.padding-20-0 {
  padding: 20px 0;
}

.padding-0-15 {
  padding: 0 15px;
}

.padding-20 {
  padding: 20px;
}

/* PROJEKTI *************************************************************/
/************************************************************************/
#zbrana-sredstva-year {
  width: 100px!important;
}

.legend {
  text-align: left !important;
  margin: 0;
  padding: 0px 0px 40px !important;
  z-index: 500;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  
  @include media-breakpoint-only(xs) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  @include media-breakpoint-up(md) {
    height: 175px;
  }
  li {
    display: block;
    margin-bottom: 10px !important;
    line-height: 31px;
    padding-left: 46px;
    .switch {
      position: relative;
      display: inline-block;
      padding-left: 30px;
      vertical-align: middle;
      margin-right: 5px;

      input {
        display: none;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 1px;
        width: 20px;
        height: 20px;
        left: 0;
        right: 0;
        bottom: 0px;
        -webkit-transition: .4s;
        -ms-transition: .4s;
        transition: .4s;
        -webkit-border-radius: 25px;
        -ms-border-radius: 25px;
        border-radius: 25px;

        &:before {
          position: absolute;
          content: "";
          height: 31px;
          width: 31px;
          background-color: #fff;
          -webkit-transition: .4s;
          -ms-transition: .4s;
          transition: .4s;
          -webkit-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }
  }
}

.legend li .switch.on .slider:before {
  right: 0px;
}

.legend li .switch.off .slider:before {
  left: 0px;
}

.legend li .switch.on input:checked + .slider:before {
  background-color:#d5d5d5;
}

/*switch styles*/
.legend li .switch.switch-fso-vs .slider:before {
  background-color: #a6ce39;
}
.legend li .switch.switch-fso-pd .slider:before {
  background-color: #599541;
}
.legend li .switch.switch-fso-pr .slider:before {
  background-color: #13602b;
}
.legend li .switch.switch-fso_ob .slider:before {
  background-color: #0d3318;
}
.legend li .switch.switch-fiho_io .slider:before {
  background-color: #ffc30e;
}
.legend li .switch.switch-fiho_ho .slider:before {
  background-color: #f38f37;
}

.legend {
  .custom-switch {
    .custom-control-label {
      cursor: pointer;
      font-size: 16px;
      color:#000;
      &:before {
        left: -48px;
        top: -1px;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background: none;
        border-color: transparent;
      }
      
      &:after {
        top: 0;
        left: -47px;
        transform: none;
        width: 31px;
        height: 31px;
        border-radius: 50%;
        transform: none;
      }
    }

    &.switch-fiho_io .custom-control-input~.custom-control-label {
      &:after {
        background-color: #FFC000;
      }
    }
    &.switch-fiho_ho .custom-control-input~.custom-control-label {
      &:after {
        background-color: #B15300;
      }
    }
    &.switch-fso .custom-control-input~.custom-control-label {
      &:after {
        background-color: #ff8c00;
      }
    }
    &.switch-fso-vs .custom-control-input~.custom-control-label {
      &:after {
        background-color: #C60000;
      }
    }
    &.switch-fso-pd .custom-control-input~.custom-control-label {
      &:after {
        background-color: #006FBB;
      }
    }
    &.switch-fso-pr .custom-control-input~.custom-control-label {
      &:after {
        background-color: #13602B;
      }
    }
    &.switch-fso_ob .custom-control-input~.custom-control-label {
      &:after {
        background-color: #782380;
      }
    }

    .custom-control-input:checked~.custom-control-label:after {
      background: #d0d0d0;
      transform: none;
    }
  }
}

/*.buttons {
  @include media-breakpoint-only(xs) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}*/

/* MODAL*/
.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

/* STATISTIC COUNTER ****************************************************/
/************************************************************************/
.stats1 {
  margin-top: 30px;
}

.stats1 .col-4 {
  padding: 40px 15px;
  background: #e03c2f;
}

.stats1 .col-4:nth-child(even) {
  background: #ff4337;
}

.stats1 .col-4 p {
  color: #ffffff;
  font-size: 4vw;
  line-height: 5vw;
  margin: 0 0 10px;
  font-weight: 700;
}

.stats1 .col-4 h2 {
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
}


#projects-list {
  @include media-breakpoint-up(lg) {
    width: calc(100% + 60px);
  }

  .project-container-filters {
    margin-bottom: 28px;
    .inner {
      @include media-breakpoint-up(lg) {
        padding: 0;
        margin-left: 20px;
      }
    }
    
    .filter {
      select {
        border: 1px solid #000;
        padding: 8px 13px;
        cursor: pointer;
        border-radius: 0;
      }
    }
  }

  .project-container-header {
    @include media-breakpoint-only(xs) {
      display: none;
    }
    background:$primary;
    color:#fff;
    font-family: $font-bold;
    font-size: 16px;
    
    div {
      padding: 13px 24px;
    }
  }

  .project-container {
    border-bottom: 1px solid $text-primary;
    @include media-breakpoint-only(xs) {
      padding-bottom: 10px;
      padding-top: 10px;
    }
    div {
      padding: 13px 24px;
      @include media-breakpoint-only(xs) {
        padding: 5px 0px;
        display: flex;
        align-items:center;

        hr {
          display: none;
        }
        
        &:before {
          flex: 0 0 30%;
          max-width: 30%; 
          font-family: $font-bold;        
        }
        &.city {
          &:before {
            content: 'Mesto';
          }
        }
        &.name {
          &:before {
            content: 'Ime društva';
          }
        }
        &.project-type {
          &:before {
            content: 'Naslov in projekt';
          }
        }
        &.category {
          &:before {
            content: 'Kategorija';
          }
        }
      }

      &.category {
        .dot {
          background:#006FBB;
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          position: relative;
          border-radius: 50%;
          @include media-breakpoint-up(sm) {
            top: 5px;
          }

          &.fso-pd {
            background: #006FBB;
          }
          &.fso-pr {
            background: #13602B;
          }
          &.fso-ob {
            background: #782380;
          }
          &.fiho-io {
            background: #FFC000;
          }
          &.fiho-ho {
            background: #B15300;
          }
        }
      }
    }

  }
}

/*
@media only screen and (max-width: 900px) {
  .wide-content .aside-container {
    position: absolute !important;
    left: -300px;
  }

  .wide-content .aside-container .projectMenu {
    display: inline-block;
  }

  .wide-content .projects-content {
    width: 100% !important;
  }
}

@media only screen and (max-width: 450px) {
  .header-map-icon {
    font-size: 35px !important;
  }

  .panel-default {
    padding: 13px 10px !important;
  }

  .panel-default h2 {
    font-weight: 700 !important;
    font-size: 17px !important;
  }

  .panel-default .results.accordion-toggle:after {
    margin-top: 2px !important;
  }

  ul.cat-list li:before {
    font-size: 11px;
  }

  ul.cat-list li a {
    display: block;
  }
}*/
