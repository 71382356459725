body {
  &.loto-statistics,
  &.ejp-statistics,
  &.viking-statistics,
  &.threexthree-statistics,
  &.tikitaka-statistics {
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
      top: 2px;
      right: 16px;
    }

    .select2-container--default {
      .select2-selection--single {
        height: 40px !important;
        border: none !important;
  
        .select2-selection__rendered {
          height: 40px !important;
          line-height: 40px;
          color: $text-primary;
          font-size: 16px;
          border: 1px solid #aaa !important;
          border-radius: 4px !important;
        }

        .select2-selection__arrow {
          right: 16px;
        }
      }
    }

    .select2-selection--single {
      height: 40px !important;
    }

    .select2-selection__rendered,
    .select2-selection__arrow {
      height: 35px !important;
      line-height: 35px !important;
    }

    .select2-dropdown {
      text-align: center;

      li.select2-results__option {
        font-size: 16px;
        color: $text-primary;
        border-bottom: 1px solid #b6b6b6;
        padding-right: 48px;
      }

      li.select2-results__option--highlighted[aria-selected] {
        background-color: rgba(0,149,57,.1);
      }
    }

    /*
    .popover {
      background: none;
      border: 2px solid $green;
      top: -10px !important;
      max-width: 290px !important;
      border-radius: 10px;
      background: #fff;
      left: -29px !important;
      top: 49px !important;
      position: relative;
      z-index: 100;

      .popover-body {
        padding: 18px 25px;
        color: $text-primary;
        font-size: 13px;
        line-height: 21px;
        border-radius: 8px;
      }

      .arrow {
        display: none;
      }
    }*/
  }
}

.statistics_chart_narrow_container {
  .field-title {
    font-size: 20px;
    padding-left: 12px;
  }
  
  .slick-list {

  }
  .slick-arrow {
    position:absolute;
    top: 50%;
    left: 0;
    background: #EDEDED;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      left: -50px;
    }
    
    span {
      width: 8px;
      height: 8px;
      display: block;
      border-top: 2px solid $primary;
      border-left: 2px solid $primary;
      transform: rotate(-45deg);
    }
    
    &.slick-right {
      left: auto;
      right: 0;
      @include media-breakpoint-up(md) {
        right: -50px;
      }
      
      span {
        transform: rotate(135deg);
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .pie_chart_statistics {
    .data {
      &:before {
        content:'';
        width: 21px;
        height: 21px;
        display: block;
        margin-bottom: 13px;
        border-radius: 50%;
      }
    }
    .statistics_pie_chart {
      > div > div {
          margin: 0 auto 20px;
      }
    }

    &.loto {
      .data {
        &:before {
          background:$loto;
        }

        &.odd {
          &:before {
            background:$primary;
          }
        }
      }
    }

    &.tikitaka {
      .data {
        &:before {
          background:#FFCB16;
        }

        &.odd {
          &:before {
            background:#1475BB;
          }
        }
      }
    }

    &.vikinglotto {
      .data {
        &:before {
          background:$viking;
        }

        &.odd {
          &:before {
            background:$viking3;
          }
        }
      }
    }

    &.ejp {
      .data {
        &:before {
          background:$ejp;
        }

        &.odd {
          &:before {
            background:$ejp-text;
          }
        }
      }
    }
  }
}
  
.pie_chart_statistics_accessibility {
  margin-top: 20px;

  .table-chart-header {
    font-size: 15px;
    font-family: $font-bold;
    color: $text-primary;
    border-bottom: 1px solid #e1e1e1;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }

    div {
      padding: 5px 0px;

      @include media-breakpoint-up(md) {
        padding: 8px 5px;
      }
    }
  }

  .table-chart-body {
    background: #f9f9f9;
    border-bottom: 1px solid #e1e1e1;
    color: $text-primary;
    font-size: 14px;
    font-family: $font-bold;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }

    &:nth-child(odd) {
      background: #f2f2f2;
    }

    div {
      padding: 5px 0px;

      @include media-breakpoint-up(md) {
        padding: 8px 5px;
      }
    }
  }
}

.loto-statistics {
  #main {
    #main-content {
      .container {
        @include media-breakpoint-up(sm) {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

    .google-visualization-tooltip {
      @include google-chart-tooltip;
      width: 140px !important;

      .google-visualization-tooltip-item-list {
        margin: 0;

        .google-visualization-tooltip-item {
          padding: 0;
          margin: 0;

          &:first-child {
            span {
              color: #fff !important;
              font-family: $font-bold !important;
              font-size: 19px !important;
              line-height: 33px !important;
            }
            opacity: 1;
            width: 32px !important;
            height: 32px !important;
            display: block;
            background: #d0021b;
            border-radius: 50%;
            text-align: center;
            margin-bottom: 10px;
          }

          &:last-child {
            span {
              font-size: 16px !important;
              letter-spacing: 0.25px !important;
              color: $text-primary !important;
              font-family: $font-normal !important;

              &:last-child {
                font-family: $font-bold;
              }
            }
          }
        }
      }
    }
  }
}

.tikitaka-statistics {
  #main {
    .google-visualization-tooltip {
      @include google-chart-tooltip;
      width: 170px !important;

      .google-visualization-tooltip-item-list {
        margin: 0;

        .google-visualization-tooltip-item {
          padding: 0;
          margin: 0;

          &:first-child {
            span {
              color: #000 !important;
              font-family: $font-bold !important;
              font-size: 19px !important;
              line-height: 33px !important;
            }
            opacity: 1;
            width: 32px !important;
            height: 32px !important;
            display: block;
            background: #ffcb16;
            border-radius: 50%;
            text-align: center;
            margin-bottom: 10px;
          }

          &:last-child {
            span {
              font-size: 16px !important;
              letter-spacing: 0.25px !important;
              color: $text-primary !important;
              font-family: $font-normal !important;

              &:last-child {
                font-family: $font-bold;
              }
            }
          }
        }
      }
    }
  }
}

.viking-statistics {
  #main {
    .google-visualization-tooltip {
      @include google-chart-tooltip;
      width: 170px !important;
      transform: translate(0, -80px);

      .google-visualization-tooltip-item-list {
        margin: 0;

        .google-visualization-tooltip-item {
          padding: 0;
          margin: 0;

          &:first-child {
            span {
              color: #fff !important;
              font-family: $font-bold !important;
              font-size: 19px !important;
              line-height: 33px !important;
            }
            opacity: 1;
            width: 32px !important;
            height: 32px !important;
            display: block;
            background: #0000ff;
            border-radius: 50%;
            text-align: center;
            margin-bottom: 10px;
          }

          &:last-child {
            span {
              font-size: 16px !important;
              letter-spacing: 0.25px !important;
              color: $text-primary !important;
              font-family: $font-normal !important;

              &:last-child {
                font-family: $font-bold !important;
              }
            }
          }
        }
      }
    }

    .pie_chart_statistics {
      svg {
        g:nth-child(3) {
          text {
            fill: #000;
          }
        }
      }
    }
  }
}

.ejp-statistics {
  #main {
    .google-visualization-tooltip {
      @include google-chart-tooltip;
      width: 170px !important;
      transform: translate(0, -80px);

      .google-visualization-tooltip-item-list {
        margin: 0;

        .google-visualization-tooltip-item {
          padding: 0;
          margin: 0;

          &:first-child {
            span {
              color: #581e00 !important;
              font-family: $font-bold !important;
              font-size: 19px !important;
              line-height: 33px !important;
            }
            opacity: 1;
            width: 32px !important;
            height: 32px !important;
            display: block;
            background: #ffbe14;
            border-radius: 50%;
            text-align: center;
            margin-bottom: 10px;
          }

          &:last-child {
            span {
              font-size: 16px !important;
              letter-spacing: 0.25px !important;
              color: $text-primary !important;
              font-family: $font-normal !important;

              &:last-child {
                font-family: $font-bold;
              }
            }
          }
        }
      }
    }
  }

  .pie_chart_statistics {
    svg {
      g:nth-child(2) {
        text {
          fill: $ejp-text;
        }
      }
    }
  }
}

.statistic {
  .row.select {
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 65px;
    }

    .selection {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0px;
      }

      .select2-container {
        width: 100% !important;
        text-align: center;
      }
    }
  }

  .draw-numbers-chart-tikitaka, .draw-numbers-chart-joker {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin-bottom: 75px;
    }
  }
  
  .draw-numbers-chart {
    margin-bottom: 30px;
    position: relative;
    
    @include media-breakpoint-down(sm) {
      circle.chartCircle {
        //r: 12 !important;

        + text {
          font-size: 14px;
        }
      }
    }

    .scroll {
      position: absolute;
      top: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        top: 10px;
        left: 8px;
        width: 11px;
        height: 11px;
        display: block;
        border-top: 2px solid $primary;
        border-right: 2px solid $primary;
        transform: rotate(45deg);
      }

      &.scroll-right {
        right: 15px;
      }

      &.scroll-left {
        left: 14px;
        display: none;

        &:before {
          border-top: 2px solid $primary;
          border-right: none;
          border-left: 2px solid $primary;
          transform: rotate(-45deg);
          left: 12px;
        }
      }
    }

    h3 {
      font-size: 20px;
      color: #575757;
      text-align: center;
      margin-bottom: 0;
    }

    .scrollable {
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 75px;
    }
  }

  h2 {
    font-size: 24px;
    letter-spacing: 0.38px;
    margin-bottom: 10px;

    span {
      font-size: 18px;
      margin-left: 7px;
      cursor: pointer;
      position: relative;
      z-index: 300;

      &:hover {
        color: $green;
      }
    }
  }

  h3, .subheading {
    font-size: 16px;
    font-family: $font-normal;
    color: $text-primary;
    letter-spacing: 0.25px;
    margin-bottom: 15px;
  }

  .dropdown {
    .dropdown-toggle {
      &:after {
        display: inline-block;
        margin-left: 8px;
        vertical-align: 2px;
        content: "";
        border-top: 6px solid;
        border-right: 6px solid transparent;
        border-bottom: 0;
        border-left: 6px solid transparent;
      }

      &:hover {
        color: $green;
      }
    }

    .dropdown-menu {
      min-width: 0;
      width: 120px;
      margin-top: 15px;
      border-radius: 4px;
      border: solid 1px #b6b6b6;
      background-color: #ffffff;
      padding: 0;
      max-height: 300px;
      overflow: auto;

      .dropdown-item {
        padding: 5px 10px 5px 5px;
        text-align: center;
        font-size: 16px;
        color: $text-primary;
        border-bottom: 1px solid #e1e1e1;

        &:active,
        &:hover {
          background-color: rgba(0, 149, 57, 0.1);
          color: $text-primary;
        }
      }
    }

    &.show {
      .dropdown-toggle {
        &:after {
          border-top: 0;
          border-right: 6px solid transparent;
          border-bottom: 6px solid;
          border-left: 6px solid transparent;
        }
      }
    }
  }

  .numbers {
    margin: 25px 0 40px;

    @include media-breakpoint-up(md) {
      margin: 70px 0 100px;
    }

    .number-c {
      text-align: center;
      padding: 10px 0;

      .number {
        width: 28px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 25px;
        }
      }

      span {
        color: $text-primary;
        font-size: 13px;
        letter-spacing: 0.25px;
        font-family: $font-normal;
        margin-top: 15px;
        display: block;
        line-height: 1;

        @include media-breakpoint-up(md) {
          font-size: 16px;
          margin-top: 25px;
        }
      }
    }

    &.ejp {
      .additional:nth-last-child(2) {
        border-left: 1px solid #d8d8d8;
      }
    }
  }

  .dropdown {
    .title {
      font-size: 16px;

      &:after {
        margin-left: 6px;
      }
    }
  }

  .game-tile-inner {
    margin: 0 0 30px;

    @include media-breakpoint-up(md) {
      margin: 0 0 90px;
    }
  }

  .pair-numbers-chart {
    .data {
      font-size: 16px;
      line-height: 22px;

      .statistics-number {
        font-size: 29px;
      }

      .subtitle {
        font-size: 14px;
      }
    }
  }
}

.viking-statistics {
  .statistic-row.joker {
    .top {
      background-color: #00f;
    }
    .scroll-icon {
      background-color: #00f;
    }
  }
}

.ejp-statistics {
  .statistic-row.joker {
    .top {
      background-color: #ffbe14;
      color: #581e00;
    }
    .scroll-icon {
      background-color: #ffbe14;
      color: #581e00;
    }
    .scroll-left {
      .icon {
        border-right: 8px solid #581e00 !important;
      }
    }
    .scroll-right {
      .icon {
        border-left:8px solid #581e00!important;
      }
    }
  }
}

.loto-statistics {
  .statistic-row.joker {
    .top {
      background-color: #D0021B;
    }
    .scroll-icon {
      background-color: #D0021B;
    }
  }
}

.statistics_chart_accessibility {
  margin-top: 42px;

  .toggleText {
    margin-bottom: 25px;
    .toggle-icon {
      position:absolute;
      top: 12px;
      right: 0;
      border-width: 0px 8px 10px 8px;
      border-color: transparent transparent $text-primary transparent;
      transform: rotate(0deg);
      transition: transform .3s ease;
      transform-origin: center;
    }
  }

  .collapsed {
    .toggleText {
      margin-bottom: 25px;
      .toggle-icon {
        position:absolute;
        top: 12px;
        right: 0;
        
        transform: rotate(180deg);
      }
    }
  }

  .statistic-row {
    position: relative;
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: start;
    font-size: 16px;
    font-family: $font-bold;
    margin-bottom: 10px;

    &.ejp {
      .top {
        background-color: #ffbe14;
        color: #581e00;
      }
      .scroll-icon {
        background-color: #ffbe14;
        color: #581e00;
      }
      .scroll-left {
        .icon {
          border-right: 8px solid #581e00 !important;
        }
      }
      .scroll-right {
        .icon {
          border-left:8px solid #581e00!important;
        }
      }
      &.additional {
        .top {
          background-color: #581e00;
          color: #ffbe14;
        }
        .scroll-icon {
          background-color: #581e00;
          color: #ffbe14;
        }
        .scroll-left {
          .icon {
            border-right: 8px solid #ffbe14 !important;
          }
        }
        .scroll-right {
          .icon {
            border-left:8px solid #ffbe14!important;
          }
        }
      }
    }
    &.tikitaka {
      .top {
        background-color: #ffcb16;
        color: #000;
      }
      .scroll-icon {
        background-color: #ffcb16;
        color: #000;
      }
      .scroll-left {
        .icon {
          border-right: 8px solid #000 !important;
        }
      }
      .scroll-right {
        .icon {
          border-left:8px solid #000!important;
        }
      }
    }
    &.vikinglotto {
      .top {
        background-color: #00f;
      }
      .scroll-icon {
        background-color: #00f;
      }
      &.additional {
        .top {
          background-color: #91ffc3;
          color: #00f;
        }
        .scroll-icon {
          background-color: #91ffc3;
        }
        .scroll-left {
          .icon {
            border-right: 8px solid #00f !important;
          }
        }
        .scroll-right {
          .icon {
            border-left:8px solid #00f!important;
          }
        }
      }
    }
    &.loto, &.lotko {
      .top {
        background-color: #D0021B;
      }
      .scroll-icon {
        background-color: #D0021B;
      }
      &.additional {
        .top {
          background-color: #00953a;
        }
        .scroll-icon {
          background-color: #00953a;
        }
      }
    }
    &.lotoplus {
      .top {
        background-color: #00953a;
      }
      .scroll-icon {
        background-color: #00953a;
      }
      &.additional {
        .top {
          background-color: #D0021B;
        }
        .scroll-icon {
          background-color: #D0021B;
        }
      }
    }
    
    .top {
      height: 50px;
      color: white;
      padding: 11px 8px;
    }
    
    .bottom {
      height: 50px;
      padding: 11px 5px;
      text-align: center;
    }

    .statistic-header {
      text-align: left;
      font-family: $font-bold;

      .top {
        padding-left: 18px;
      }
      
      .bottom {
        padding-left: 18px;
        color:#000;
      }
    }
    
    .statistic-content {
      display: flex;
      width: 100%;
      overflow: hidden;
      text-align: center;
      padding: 0;
      
      .statistic-number {
        padding: 0;
        
        .bottom {
          font-family: $font-normal;
        }
      }
    }   

    .scroll-icon {
      width: 57px;
      height: 50px;
      cursor: pointer;
      position: relative;

      .icon {
        width: 0; 
        height: 0; 
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right:8px solid white;
        display: block;
      }
      
      &.scroll-right {
        right: auto;
        .icon {
          border-left:8px solid white;
          border-right:0px;
        }
      }
      &.scroll-left {
        left: auto;
      }
    }
  }
}