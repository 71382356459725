.sreca-za-vse-main {
  #main-content {
    padding: 0 !important;
  }

  .inner-visual-druzbena {
    overflow: hidden;
    max-height: 500px;
    position: relative;
    z-index: 100;
  }

  p {
    font-size: 32px;
    font-family: $font-normal;

    strong {
      font-family: $font-normal;
      font-weight: normal;
    }
  }

  #druzbena-intro {
    margin: 60px 0;

    p {
      font-size: 28px;
      font-family: $font-normal;
      line-height: 1.2;
      color: $gray7;

      strong {
        font-family: $font-normal;
        font-weight: bolder;
      }
    }
  }

  #sreca {
    margin-bottom: 120px;

    .row {
      border: 1px solid $gray6;

      .bg-grey {
        padding: 80px 40px 30px 100px;

        h1 {
          color: $primary;
          position: relative;
          font-size: 40px;
          line-height: 1.2;
          text-transform: uppercase;

          strong {
            //color: $yellow-srecomobil;
          }

          &:before {
            display: inline-block;
            content: "";
            height: 140%;
            width: 4px;
            position: absolute;
            background: #d5e011;
            top: -20px;
            left: -50px;
          }
        }

        p {
          font-size: 27px;
          line-height: 1.4;
        }
      }

      .bg-white {
        padding: 80px;

        p {
          font-size: 22px;
          color: $gray7;

          strong {
            color: $primary;
            font-weight: bold;
          }
        }

        h3 {
          color: $primary;
          font-size: 50px;
          font-family: $font-normal;
          margin-bottom: 0;

          strong {
            font-family: $font-normal;
            font-weight: normal;
          }
        }
      }
    }
  }

  #stevilke {
    .container {
      padding-top: 100px;

      .outer {
        padding: 0 60px;

        &:nth-child(2) {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-row-gap: 20px;

          @media (max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);
          }

          @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }

        .title {
          h2,p {
            color: $primary;
            position: relative;
            font-size: 40px;
            line-height: 1.2;
            text-transform: uppercase;
            padding-left: 40px;
            font-family: $font-bold;

            /*strong {
              color: $yellow-srecomobil;
            }*/

            &:before {
              display: inline-block;
              content: "";
              height: 95%;
              width: 4px;
              position: absolute;
              background: #d5e011;
              top: 0;
              left: -15px;
            }
          }
        }

        .number-col {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(2, 1fr);
          grid-row-gap: 20px;
          border-top: 1px dotted;
          padding: 40px 0;

          .inner {
            height: 100%;
          }

          .content {
            padding-right: 30px;

            p {
              font-size: 18px;
              color: $text-primary;
              line-height: 25px;

              strong {
                color: $primary;
                font-family: 'Facit Bold', Arial, sans-serif;
              }
            }
          }

          .number-container {
            padding-right: 50px;

            .number-count {
              font-size: 80px;
              font-weight: 100;
              line-height: 1;
              color: $primary;
              font-family: $font-roboto;
            }

            .subtitle {
              font-size: 48px;
              font-weight: 100;
              line-height: 1.4;
              color: $primary;
              font-family: $font-roboto;

              @media (max-width: 1199px) {
                font-size: 43px;
              }
            }
          }

          .content {
            .content-edit {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

#sreca-za-vse {
  margin: 50px 0;

  .row {
    .col {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }

      div.sreca-title {
        //color: $yellow-srecomobil;
        margin-bottom: 0;
        font-size: 40px;
        text-transform: uppercase;
        line-height: 1;
        font-family: $font-bold;

        &:before {
          display: none;
        }

        @media (max-width: 768px) {
          flex: 1 0 auto;
          width: 100%;
        }
      }

      .equals, .plus {
        font-size: 40px;
        //color: $yellow-srecomobil;
        flex: 1;
        font-weight: bold;

        @media (max-width: 768px) {
          flex: 1 0 auto;
          width: 100%;
        }
      }

      .item {
        flex: 1;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: $primary;

        @media (max-width: 768px) {
          flex: 1 0 auto;
          width: 100%;
        }

        &:last-child {
          flex: 1 0 6%;
        }
      }
    }
  }
}