@import "./_variables.scss";
@import "./_fonts.scss";

body {
  color: $text-primary;
  font-family: $font-normal;
	font-size: 14px;
	line-height: 1.7;
}

p {
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6{
  line-height: 1;
}

.table {
	font-size: 16px;

	th {
		border-top: none;
	}

	tr {
		td {
			padding-top: 6px;
			padding-bottom: 6px;
			border: none;
		}		
	}

	&.two-headers {
		tr {
			td {	
				&:first-child {
					font-family: $font-bold;
				}
			}		
		}
	}
}

.table1 {
	font-size: 16px;
	width: 930px;

	tr {
		td {
			padding: 4px 0px;

			&:first-child {
				font-family: $font-bold;
			}
		}		
	}
}

.table2 {
	width: 930px;

	&:not(:first-of-type) {
		border-top: 1px solid #e1e1e1;
	}

	&:first-of-type {
		tbody {
			border-top: 1px solid #e1e1e1;
			border-bottom: 1px solid #e1e1e1;
		}
	}

	font-size: 16px;
	font-weight: 500;
	tr {
		td {
			//background:#fff;
			padding: 10px 20px;

			&:nth-child(2),
			&:nth-child(3) {
				text-align: right;
			}
		}
	}

	thead {
		tr {
			td {
				background: #f9f9f9;
			}
		}
	}

	tbody {
		tr {
			&:not(:nth-child(3)) {
				td {
					background: #f9f9f9;
				}
			}
		}
	}


	tr:not(:first-child) {
		border-top: 1px solid #e1e1e1;

		td {
			border: none !important;
		}
	}
}

.table-wrap1,
.table-wrap2 {
	background: $white;
	padding: 15px;
	border-bottom: 1px solid #e1e1e1;

	&.hidden-table {
		display: none;
		width: 100%;

		&.show {
			display: block;
		}

		.table3, .table4 {
			border: none !important;

			thead {
				border-bottom: 1px solid #e1e1e1;

				tr {

					td {
						width: 20%;
						padding-top: 0 !important;
						padding-bottom: 6px !important;
						border: none !important;

						&:nth-child(2),
						&:nth-child(3) {
							text-align: right !important;
						}
					}
				}
			}

			tbody {

				tr {
					td {
						border: none !important;
						width: 20%;
						padding-bottom: 0 !important;

						&:nth-child(2),
						&:nth-child(3) {
							text-align: right !important;
						}
					}
				}
			}
		}
	}
}

a.table-toggle1,
a.table-toggle2 {
	&:before {
		content: "+";
		margin-right: 20px;
	}

	&.active {
		&:before {
			content: "-";
		}
	}
}
